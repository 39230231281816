.cms-home.cms-index-index {
    //Commons
    #maincontent .columns .column.main {
        margin: 0 auto;
    }
    h2,
    .block.widget.block-products-list .block-title strong {
        //font-family: $font-family__Raleway-regular;
        font-weight: $font-weight__regular;
        @include lib-text(extra);
        text-align: center;
        margin-bottom: $indent__m;
    }
    .block.widget.block-products-list .block-title {
        text-align: center;
    }

    button.slick-next,
    button.slick-next:hover,
    button.slick-prev,
    button.slick-prev:hover {
        background-color: #fff;
    }

    button.slick-next,
    button.slick-next:hover
    {
        right: -5px;
    }
    button.slick-prev,
    button.slick-prev:hover {
        left: -5px;
    }
    .home-block-separator {
        margin: $indent__xxxxl 0;
        &.with-border {
            border-bottom: 1px solid $color-kim-gris2;
        }
        &.first-separator{
            margin: $indent__m 0;
        }
    }
    .top-separator {
        margin: $indent__base 0;
    }
    .block.widget.block-products-list.grid {
        margin-bottom: 0;
    }
    // products sliders
    .widget-wishlist {
        margin-left: 0;
        .filled-heart {
            display: none;
        }
        &:hover {
            .unfilled-heart {
                display: none;
                + .filled-heart {
                    display: block;
                }
            }
        }
    }
    //banners
    .home-news-and-blog,
    .home-banner{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .wrap-1 {
            width: 66.1%;
        }
        .wrap-2 {
            width: 33.1%;
        }
        .wrap-3 {
            width: 49.7%;
        }
        .wrap-4 {
            width: 100%;
        }
        .mobile {
            display: none;
        }
    }
    //Home videos
    .home-videos {
        background-color: $color-kim-gris3;
        padding: $indent__xxxxl $indent__xxxxxl $indent__xxxxxl $indent__xxxxxl;
        text-align: center;
        .videos {
            display: flex;
            justify-content: space-between;
            margin: $indent__xxl 0;
            .video {
                text-align: left;
                width: 30%;
                div.img {
                    position: relative;
                    text-align: center;
                }
                svg {
                    height: 48px;
                    left: 50%;
                    position: absolute;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    width: 48px;
                }
                img {
                    margin-bottom: $indent__xs;
                }
                p.title {
                    //font-family: $font-family__Raleway-semibold;
                    font-weight: $font-weight__semibold !important;
                    max-width: 360px;
                    margin-left: auto;
                    margin-right: auto;
                    @include lib-text(medio);
                }
                .content {
                    max-width: 360px;
                    margin-left: auto;
                    margin-right: auto;
                    @include lib-text(normal);
                }
            }
        }
    }
    //Instagram
    #cpcolumninstagram {
        margin: 0 auto;
        width: 80%;
        .instangram-feed {
            svg {
                display: none;
                height: 80px;
                left: 50%;
                position: absolute;
                top: 50%;
                transform: translate(-50%, -50%);
                width: 80px;
            }
            &:hover {
                opacity: 0.5;
                svg {
                    display: block;
                }
            }
        }
    }

    //kimidori
    .home-kimidori {
        margin: 0 auto;
        text-align: center;
        width: 80%;
        p {
            @include lib-text(medio);
            margin-bottom: 2rem;
            &.subtitle {
                //font-family: $font-family__Raleway-medium;
                font-weight: $font-weight__heavier;
            }
        }

    }

}

//
//  Desktop large
//  _____________________________________________

@include max-screen($screen__xl) {
    .cms-home.cms-index-index {
        .home-videos {
            .videos {
                margin: $indent__xxl 0;
            }
        }
    }
}

//
//  Desktop
//  _____________________________________________


@include max-screen($screen__l) {
    .cms-home.cms-index-index {
        .home-videos {
            padding: $indent__xl $indent__l $indent__xxl $indent__l;
            .videos {
                margin: $indent__xl 0;
            }
        }
        #cpcolumninstagram {
            .instangram-feed {
                svg {
                    height: 48px;
                    width: 48px;
                }
            }
        }
        .home-kimidori {
            width: 90%;
        }

    }
}

//
//  Tablet
//  _____________________________________________
@include max-screen($screen__m) {
    .cms-home.cms-index-index {
        .home-block-separator {
            margin: $indent__m 0;
        }
        .home-our-news{
            .new-content {
                width: 49.8%;
            }
        }
        .home-videos {
            padding: $indent__m $indent__base $indent__l $indent__base;
            .videos {
                margin: $indent__m 0;
            }
        }
        #cpcolumninstagram {
            width: 90%;
        }
        .home-kimidori {
            width: 100%;
        }
    }
}

//
//  mobile
//  _____________________________________________

@include max-screen($screen__xs) {
    .cms-home.cms-index-index {
        h2,
        .block.widget.block-products-list .block-title strong {
            margin-bottom: 20px;
        }
        .home-block-separator {
            margin: $indent__s 0;
        }
        .top-separator {
            margin: 0;
        }
        .home-news-and-blog,
        .home-banner{
            .wrap-1,
            .wrap-2,
            .wrap-3,
            .wrap-4 {
                width: 100%;
                &.mobile {
                    display: block;
                }
                &.desktop {
                    display: none;
                }
            }
        }
        .home-videos {
            padding: $indent__base $indent__base $indent__m $indent__base;
            .videos {
                display: flex;
                justify-content: space-between;
                margin: $indent__base 0;
                .video {
                    width: 100%;
                    img {
                        width: 100%;
                    }
                }
                .second-video,
                .third-video {
                   display: none;
                }
            }
        }

        #cpcolumninstagram {
            width: 100%;
            .instangram-feed {
                svg {
                    height: 24px;
                    width: 24px;
                }
            }
        }
    }
}