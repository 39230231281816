.customer-rewards-index{
    .page-title-wrapper {
        margin-bottom: $indent__xl;
    }
    .block-reward-dashboard {
        .block.reward-balance.row-mp {
            margin-bottom: $indent__xl;
        }
    }
    .block-reward-dashboard-collections {
        margin-bottom: $indent__m;
        button.slick-next,
        button.slick-next:hover {
            right: -5px;
        }
        button.slick-prev,
        button.slick-prev:hover {
            left: -5px;
        }
        &.recommended{
            background: $color-kim-gris3;
            padding: $indent__s $indent__xxl $indent__m;
            .slick-slide {
                background: $color-white;
            }
        }
    }
    .block-title {
        @include lib-text(extra);
    }
    .reward-intro{
        margin: 15px 0 25px;
    }
    .reward-balance{
        max-width: 675px;
    }
    .block-reward-dashboard {
        .block{
            margin-bottom: $indent__m;
        }
    }

    .points-label {
        display: block;
        text-align: center;
        font-size: 1.3rem;
    }
    .points-number {
        display: block;
        text-align: center;
        font-size: 4rem;
        line-height: 4rem;
    }
    .available{
        .points-number {
            font-size: 5rem;
            line-height: 5rem;
        }
        .points-label {
            font-size: 1.5rem;
        }
    }
    .box-balance {
        max-width: 170px;
        height: 160px;
        position: relative;
        margin-top: -20px;

        > span{
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            position: absolute;
            margin: 0 auto;
            z-index: 1;
        }
        &.earned,
        &.paid{
            > span{
                left: 65%;
            }
        }
    }
    .item-balance{
        float: left;
    }
    .custom-list-styles{
        margin-bottom: 0;
    }
}

.product-labels-photo{
    &.faltan-puntos{
        filter: grayscale(100%);
        -webkit-filter: grayscale(100%);
    }
}
.my-points{
    position: relative;
    span.number{
        display: none;

        &.active{
            -webkit-clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
            clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
            position: absolute;
            right: -7px;
            top: -9px;
            @include lib-css(background, $color-kim-amarillo1);
            @include lib-css(color, $color-white);
            @include lib-css(height, $minicart-qty__height);
            line-height: 16px;
            font-size: 11px;
            font-weight: $font-weight__semibold;
            display: inline-block;
            min-width: 10px;
            overflow: hidden;
            padding: 0 3px;
            text-align: center;
            white-space: normal;
        }
    }
}

@media (max-width: 767px) {
    .customer-rewards-index{
        .item-balance{
            &.mp-xs-12{
                .box-balance{
                    margin: 10px auto 35px;
                }
            }
            .box-balance{
                margin: 10px auto 15px;
            }
            .label {
                text-align: center;
            }
        }
        .points-number{
            font-size: 3rem;
            line-height: 3rem;
        }
    }
}

@media (max-width: 480px) {
    .customer-rewards-index{
        .item-balance{
            &.mp-xs-12{
                width: 100%;
            }
            &.mp-xs-6{
                width: 50%;
            }
        }
        .box-balance > span {
            top: 47%;
        }
    }
}