.blog-page {
    .post-view {
        .post-header {
            display: flex;
            flex-direction: row-reverse;
            justify-content: space-between;
            margin-bottom: $indent__base;

            @include max-screen($screen__xs) {
                flex-direction: column;
            }
        }

        .post-ftimg-hld {
            text-align: center;
            margin-bottom: $indent__xxs;
        }

        .post-text-hld {
            @include lib-font-size($blog-content__font-size);
            line-height: $blog-content__line-height;
        }
    }
    .addthis_toolbox {
        flex-shrink: 0;
        margin-left: -$indent__xxxs/2;
        margin-right: -$indent__xxxs/2;
        a {
            color: $color-white;
            padding: $indent__xxxs;
            margin-left: $indent__xxxs/2;
            margin-right: $indent__xxxs/2;
        }

        @include max-screen($screen__xs) {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            a {
                padding: $indent__xxs;
            }
        }
    }
    .addthis_toolbox,
    .atm {
        .at-icon-wrapper {
            color: $color-white;
        }
        svg {
            display: block;
            margin: 0;
            &:hover {
                color: $color-white;
            }
        }
    }
    #at-expanded-menu-host {
        .at-icon-wrapper {
            color: $color-white;
        }
        svg {
            margin: 0;
        }
        .at-expanded-menu-round {
            .at-expanded-menu-service-list button{
                height: auto;
            }
            .at-expanded-menu-button-label {
                display: none;
            }
        }
    }
    .post-nextprev-hld {
        display: flex;
        margin-bottom: $indent__base;
    }
    .prev-link,
    .next-link {
        padding: $indent__xxs $indent__xxxs;
        .previous-arrow svg,
        .next-arrow svg {
            width: 0.8rem;
            height: 0.8rem;
        }
    }
    .next-link {
        margin-left: auto;
    }
}
