.pages {
    @include lib-pager();

    .action {

        &.previous {
            margin-right: 0;
        }

        &.next {
            margin-left: 0;
        }
    }
}
