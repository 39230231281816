.faq-container{
    display: flex;
    flex-flow: row nowrap;
    .faq-sidebar{
        flex-shrink: 0;
        flex-grow: 0;
        flex-basis: 230px;
        padding: 0;
    }
    .faq-content{
        flex-grow: 1;
    }
    .contact-form-block{
        padding-left: 40px;
    }
}

.contact-form-block{
    .contact-form-intro{
        margin-top: 20px;
    }
}

.faq-nav-title{
    display: none;
    position: relative;
    text-align: center;
    border-bottom: 1px solid $color-kim-gris3;
    padding: 1rem;
    font-size: 1.7rem;
    font-weight: $font-weight__semibold;
    cursor: pointer;
    background: url('../images/icon-fleabajo-kimidori.svg') no-repeat center right;
    -webkit-background-size: 2.4rem;
    background-size: 2.4rem;
    user-select: none;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    &.opened{
        background-image: url('../images/icon-flearriba-kimidori.svg');
    }
}

.drop-down-container{
    display: none;
}
.drop-down-inner{
    display: none;
    padding: 20px 0;
    border-bottom: 1px solid $color-kim-gris2;
}
.drop-down-block{
    list-style: none;
    margin-bottom: 15px;
    .toggle{
        @include lib-text(medio);
        display: block;
        padding-bottom: 10px;
        border-bottom: 1px solid $color-kim-gris2;
        text-transform: uppercase;
        cursor: pointer;
        padding-right: 3rem;
        background: url('../images/icon-fleabajo-kimidori.svg') no-repeat center right;
        -webkit-background-size: 2.4rem;
        background-size: 2.4rem;
    }
    &.active{
        .toggle{
            background-image: url('../images/icon-flearriba-kimidori.svg');
        }
    }
}

@include max-screen($screen__m) {
    .faq-nav-title{
        display: block;
    }
    .faq-container {
        flex-direction: column;
        .faq-sidebar{
            flex-basis: auto;
            .content {
                display: none;
            }
        }
        .contact-form-block{
            padding-left: 0px;
        }
    }
    .drop-down-container{
        padding-left: 0;
    }
}