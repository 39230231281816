.tabla-we-ship-to-europe{
    width: 100% !important;
}

.h3_cms_we_ship_to_europe{
    margin-top: $indent__m;
    margin-bottom: $indent__xs;
}

.linea_con_margen_cms_we_ship_to_europe{
    margin-bottom: $indent__s;
}

.cms-we-ship-to-europe{
    overflow-x: hidden;
}