.fotorama--fullscreen .fotorama__fullscreen-icon {
    top: 0;
    background-image: url(../images/gallery.png);
    bottom: 100%;
    width: $size-fotorama-block;
    height: $size-fotorama-block;
    right: 0;
}
.fotorama__fullscreen-icon {
    @extend .fotorama-no-select;
    @extend .fotorama-no-tap;
    @extend .fotorama-sprite;
    position: absolute;
    display: block!important;
    bottom: 0;
    right: 24px;
    width: 24px;
    height: 24px;
    background-image: url("../images/icon-zoommas-kimidori.svg");
    background-position: 0 0;
    cursor: pointer;
    z-index: $z-index-10;

    .fotorama--fullscreen-icons & {
        display: block;
    }

    .fotorama--fullscreen & {
        display: inline-block;
        background-position: (-$size-fotorama-block) 0;
    }

    .fotorama__wrap--no-controls.fotorama__wrap--toggle-arrows & {
        opacity: 1;
    }
    .fotorama__wrap:not(.fotorama__wrap--toggle-arrows) & {
        @include fotorama-button-background();
    }
}
@include max-screen($screen__m-3) {
    .fotorama__fullscreen-icon {
        right: 10px;
    }
}

