$slide-post-item__max-width    : 360px;

.blog-page {
    .block.related {
        border-top: 1px solid $color-kim-gris3;

        @include max-screen($screen__m) {
            margin-top: $indent__m;
            padding-top: $indent__m;
        }

        @include max-screen($screen__xs) {
            margin-top: $indent__s;
            padding-top: $indent__s;
        }

        @include min-screen($screen__m) {
            margin-top: $indent__xl;
            padding-top: $indent__xl;
        }
    }
}
.blog-block-related {
    .block.related {
        @include max-screen($screen__m) {
            padding-top: $indent__m;
            padding-bottom: $indent__m;
        }

        @include max-screen($screen__xs) {
            padding-top: $indent__s;
            padding-bottom: $indent__s;
        }

        @include min-screen($screen__m) {
            padding-top: $indent__xl;
            padding-bottom: $indent__xl;
        }

    }
}

.blog-page,
.blog-block-related {
    .block.related {

        .product.alert.stock .action {
            display: inline-block;
        }

        .post-list {
            border: 0;

            &__item {
                display: inline-block;
                max-width: $slide-post-item__max-width;
                text-align: left;
            }
        }
        .post-item {
            border: 0;

            &__image {
                margin-bottom: $indent__base;
            }
            &__title {
                @include lib-text(medio);
                font-weight: $font-weight__semibold;
                margin-left: $indent__xxs;
                margin-left: $indent__xxs;
            }
        }
    }

    .slick-track {
        text-align: center;
    }

    button.slick-next,
    button.slick-next:hover
    {
        right: -5px;
    }

    button.slick-prev,
    button.slick-prev:hover {
        left: -5px;
    }
}

.blog-block-related-list {
    max-width: $blog-content__limit-l;
    margin-right: auto;
    margin-left: auto;
}

.blog-page,
.blog-block-related,
.blog-block-related-list {

    .block-title {
        text-align: center;
    }

    #block-relatedposts-heading,
    .block-title .title {
        @include lib-text(extra);
        font-weight: $font-weight__regular;
        margin-bottom: $indent__m;
        text-transform: uppercase;
    }

}
