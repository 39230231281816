.h3_cms_pago_seguro_y_fraccionados{
    margin-top: $indent__m;
    margin-bottom: $indent__xs;
}

.linea_margen_cms_pago_seguro_y_fraccionados{
    margin-bottom: $indent__xs;
}

.margenTop_cms_pago_seguro{
    margin-top: $indent__s;
}
