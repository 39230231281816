//
//  Theme variables
//  _____________________________________________

//  Messages
$message-global-note__background           : $color-yellow-light2 !default;
$message-global-note__border-color         : $color-yellow-light3 !default;
$message-global-note__color                : $text__color !default;

$message-global-note-link__color           : $link__color !default;
$message-global-note-link__color-hover     : $link__hover__color !default;
$message-global-note-link__color-active    : $link__active__color !default;

$message-global-caution__background        : $color-red9 !default;
$message-global-caution__border-color      : none !default;
$message-global-caution__color             : $color-white !default;

$message-global-caution-link__color        : $link__color !default;
$message-global-caution-link__color-hover  : $link__hover__color !default;
$message-global-caution-link__color-active : $link__active__color !default;

//  Header
$header__background-color                  : false !default;
$header-icons-color                        : $color-gray56 !default;
$header-icons-color-hover                  : $color-gray20 !default;

$addto-color                               : $text__color__muted !default;
$addto-hover-color                         : $primary__color !default;

$minicart-icons-color                      : $header-icons-color !default;
$minicart-icons-color-hover                : $header-icons-color-hover !default;

$button__shadow                            : inset 0 2px 1px rgba(0, 0, 0, 0.12) !default;

//  Footer
$footer__background-color                  : false !default;

//
//  Imports
//  ---------------------------------------------
@import 'components/header';

@import 'pages/page_not_found_404';
@import 'pages/brands';
@import 'pages/home';
@import 'pages/tags';
@import 'pages/faq';
@import 'pages/we_ship_to_europe';
@import 'pages/programa_de_puntos';
@import 'pages/condiciones_especiales_rebajas';
@import 'pages/condiciones_de_envio_y_devoluciones';
@import 'pages/aviso_legal_y_condiciones_de_uso';
@import 'pages/pago_seguro_y_fraccionados';
@import 'pages/legales';


body {
    @include lib-css(background-color, $page__background-color);
}

//
//  SVG Icons
//  ---------------------------------------------

svg {
    display: inline-block;
    vertical-align: middle;
    fill: currentColor;

    &:not(.replaced-svg):not(.ownstyle):not(.cms_page_not_found_bottom_div_imagen_svg) {
        width: 2.4rem;
        height: 2.4rem;
        path,
        path.st0,
        .st0 {
            fill: currentColor;
        }
        &:hover {
            color: $color-kim-primario1;
        }
    }
}

symbol:not(.replaced-svg) .st0 {
    fill: currentColor;
}

svg.icons-kimidori {
    display: none;
}

//
//  Theme Scroll
//  ---------------------------------------------

.page-wrapper * {
    &::-webkit-scrollbar {
        width: 9px;
        height: 9px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: $color-kim-gris2;
        border-radius: 10px;
    }
}

//
//  reCaptcha
//  ---------------------------------------------

.grecaptcha-badge {
    margin: 0 auto;
    transform: scale(0.8);
}


//
//  Return to top button
//  ---------------------------------------------

#return-to-top {
    position: fixed;
    background: white;
    border: 1px solid $color-kim-gris2;
    bottom: 65px;
    box-shadow: $box-shadow-kim-sombra2;
    display: none;
    right: 16px;
    text-decoration: none;
    text-align: center;
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -ms-transition: all 1s ease;
    -o-transition: all 1s ease;
    transition: all 1s ease;
    z-index: 100;
    padding: $indent__xxs;
    box-sizing: border-box;
    span {
        display: inline-block;
        font-size: $font-size__s;
        line-height: 13px;
        max-width: 40px;
        vertical-align: middle;
    }
}
#return-to-top:hover {
    border: 1px solid $color-kim-primario1;
    color: $color-kim-primario1;
}

.mensaje-adicional{
    background-color: $color-kim-secundario4;
    padding: 10px;
    box-sizing: border-box;
    margin-bottom: 16px;
    a{
        text-transform: uppercase;
        font-weight: $font-weight__semibold;
    }
    p:last-child{
        margin-bottom: 0;
    }
}
.page-main {
    > .page-title-wrapper {
        .page-title + .action {
            margin-top: $indent__l;
        }
    }
}

.action.skip {
    &:not(:focus) {
        @extend .abs-visually-hidden;
    }

    &:focus {
        @include lib-css(background, $color-gray94);
        @include lib-css(padding, $indent__s);
        box-sizing: border-box;
        left: 0;
        position: absolute;
        text-align: center;
        top: 0;
        width: 100%;
        z-index: 15;
    }
}

.action-skip-wrapper {
    height: 0;
    position: relative;
}

//
//  Global notice
//  ---------------------------------------------

.message.global {
    p {
        margin: 0;
    }

    &.noscript,
    &.cookie {
        @include lib-message($_message-type: global-note);
        margin: 0;
    }

    &.cookie {
        text-align: center;
        bottom: 0;
        left: 0;
        position: fixed;
        right: 0;
        z-index: 999999;
        padding: 12px 32px;

        .actions {
            margin-top: $indent__xs;
        }
        p{
            margin-bottom: $indent__xxs;
        }
        a{
            font-weight: 600;
        }
    }

    &.demo {
        @include lib-message($_message-type: global-caution);
        margin-bottom: 0;
        text-align: center;
    }
}

//
//  Footer
//  ---------------------------------------------

@keyframes movedcha {
    0% {
        transform: translate(0px,0px);
    }
    100% {
        transform: translate(8px,0px);
    }
}

@-o-keyframes movedcha {
    0% {
        transform: translate(0px,0px);
    }
    100% {
        transform: translate(8px,0px);
    }
}

@-moz-keyframes movedcha {
    0% {
        transform: translate(0px,0px);
    }
    100% {
        transform: translate(8px,0px);
    }
}

@-webkit-keyframes movedcha {
    0% {
        transform: translate(0px,0px);
    }
    100% {
        transform: translate(8px,0px);
    }
}

@keyframes moveizda {
    0% {
        transform: translate(0px,0px);
    }
    100% {
        transform: translate(-10px,0px);
    }
}

@-o-keyframes moveizda {
    0% {
        transform: translate(0px,0px);
    }
    100% {
        transform: translate(-10px,0px);
    }
}

@-moz-keyframes moveizda {
    0% {
        transform: translate(0px,0px);
    }
    100% {
        transform: translate(-10px,0px);
    }
}

@-webkit-keyframes moveizda {
    0% {
        transform: translate(0px,0px);
    }
    100% {
        transform: translate(-10px,0px);
    }
}

.page-footer {
    @include lib-css(background-color, $footer__background-color);
    margin-top: auto;
}

.social-icons {
    font-size: 0;
    img,
    svg {
        width: 2.4rem;
        height: 2.4rem;
        vertical-align: middle;
    }
    a {
        display: inline-block;
        position: relative;
        padding: $indent__xxxs $indent__xxs;

        &:not(:last-child)::after {
            content: "";
            position: absolute;
            height: 100%;
            max-height: 20px;
            right: 0;
            width: 1px;
            background-color: $color-kim-gris2;
            top: 50%;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
        }
    }

    a:hover path.st0 {
        fill: $color-kim-primario1;
    }
}

.block-separator-line{
    display: block;
    margin: $indent__m 0 $indent__s;
    border-bottom: 1px solid $color-kim-gris3;
    &.small{
        margin: $indent__s 0 $indent__xs;
    }
}

.category-top-banner{
    display: flex;
    margin-bottom: 55px;
    .ctb-image{
        padding-right: 20px;
        flex-shrink: 2;
    }
    .ctb-text {
        @include lib-text(extra);
        align-self: center;
    }
}

.subcategories-sidebar {
    .block-title.filter-title {
        display: none;
        position: relative;
        text-align: center;
        border-bottom: 1px solid $color-kim-gris3;
        padding: 1rem;
        font-size: 1.7rem;
        font-weight: $font-weight__semibold;
        cursor: pointer;
        background: url('../images/icon-fleabajo-kimidori.svg') no-repeat center right;
        -webkit-background-size: 2.4rem;
        background-size: 2.4rem;
        user-select: none;
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        &.opened{
            background-image: url('../images/icon-flearriba-kimidori.svg');
        }
    }
    ul{
        padding-left: 10px;
    }
    li{
        margin: 10px 0;
        list-style: none;
    }
}
.subcategories{
    margin-top: $indent__xxl;
}

.footer {
    &.content {
        margin-top: 25px;
        padding-bottom: 25px;
        border-top: 1px solid $color-kim-gris2;

        ul {
            @extend .abs-reset-list;
        }

        .switcher-store {
            margin: 0 0 30px;
        }
    }

    .copyright,
    .bugs {
        display: block;
        margin-left: auto;
        margin-right: auto;
        max-width: $layout__max-width;
    }
    .copyright span {
        @include lib-text(normal);
        text-align: center;
        display: block;
        margin: $indent__xs 0 0;
    }

    .footer-services {
        margin-left: auto;
        margin-right: auto;
        max-width: $layout__max-width;

        display: flex;
        justify-content: space-around;
        align-self: center;
        flex-direction: row;
        flex-wrap: wrap;

        .icon {
            text-align: center;
            margin-bottom: $indent__s;

            & > .replace-svg {
                max-width: 48px;
            }
        }
        p strong {
            text-transform: uppercase;
        }

        p,
        .email {
            @include lib-text(medio);
        }
        .email:hover {
            color: $color-kim-primario1;
        }
        & > div {
            text-align: center;

            & > img {
                display: none;
            }
        }

    }

    .footer-text {
        p {
            @include lib-text(grande);
            margin: 0 auto;
            text-align: center;
        }
    }

    .footer.links {
        display: grid;
        display: -ms-grid;

        .footer-info {

            margin-right: $indent__xs;

            svg,
            img {
                max-width: 93px;
            }
            p {
                @include lib-text(normal);
                margin-bottom: $indent__xs;
            }
        }

        .enlaces-footer {
            display: block;
            @supports (display: grid) {
                display: grid;
            }

            a {
                @include lib-text(normal);
                display: block;
                margin-bottom: $indent__xs;
                @include lib-link-tertiary();
            }
        }

        .view {
            display: none;

            .hide {
                display: none;
            }
            .show {
                display: inline-block;
            }
        }
    }

    .footer-social {

        .paying-methods {

            & > span {
                display: inline-block;
                margin-right: $indent__xxs;
                vertical-align: middle;
                @include lib-text(pequeno);
                text-align: left;
            }
            .visa {
                max-width: 48px;
            }
            .mastercard {
                max-width: 33px;
            }
            .paypal {
                max-width: 78px;
            }
            .candado {
                max-width: 24px;
            }

            .sitio-seguro {
                @include lib-text(normal);
                position: relative;
                vertical-align: middle;

                & > span {
                    display: inline-block;
                    vertical-align: middle;
                }

                & > span:first-child {
                    margin-right: $indent__xxs;
                }
            }

            svg,
            img {
                max-height: 24px;
            }
        }
    }

    .footer-subvencion {
        margin-bottom: $indent__xxs;

        .kit-digital {
            max-width: 400px;
            width: 100%;
            display: flex;
            flex-direction: column;
        }

        span {
            @include lib-text(pequeno);
            margin-top: $indent__xxxs;
        }
    }
}

.page-header,
.page-footer {
    .switcher {
        margin-right: 10px;

        .options {
            @include lib-dropdown(
                $_dropdown-actions-padding            : 0,
                $_dropdown-list-item-padding          : 0,
                $_dropdown-toggle-icon-content        : $icon-down,
                $_dropdown-toggle-active-icon-content : $icon-up,
                $_icon-font-text-hide                 : true,
                $_icon-font-size                      : 22px,
                $_icon-font-line-height               : 22px,
                $_dropdown-list-min-width             : 160px
            );

            ul.dropdown {
                a {
                    display: block;
                    padding: 8px;

                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }

        li {
            font-size: $font-size__s;
            margin: 0;
        }

        .label {
            @extend .abs-visually-hidden;
        }

        strong {
            font-weight: $font-weight__regular;
        }
    }
}

//
//  Widgets
//  ---------------------------------------------

.sidebar {
    .widget.block:not(:last-child),
    .widget:not(:last-child) {
        @extend .abs-margin-for-blocks-and-widgets;
    }
}

.widget {
    clear: both;

    .block-title {
        @extend .abs-block-title;
    }
}

.page-header,
.page-footer {
    .widget.block {
        @include lib-css(margin, $indent__base 0);
    }
}

.no-display {
    @extend .abs-no-display;
}

.no-visible {
    @extend .abs-visually-hidden;
}

//
//  Calendar
//  ---------------------------------------------

.ui-datepicker {
    td {
        padding: 0;
    }
}

//
// Clases genericas
// -----------------------------------------------

.info-header {
    display: block;
    @include lib_text(extra);
    background-color: $color-kim-gris3;
    padding: .4rem .8rem;
    margin-top: 2.5rem;
    margin-bottom: 2.4rem;
}

.politica-privacidad{
    label{
        a:hover{
            color: $color-kim-gris1;
            text-decoration: underline;
        }
    }
}

.custom-list-styles{
    list-style: none;
    padding: 0 0 0 15px;
    li{
        display: block;
        position: relative;
        border-left: 3px solid transparent;
        padding: .6rem 0 .6rem 1.5rem;
        margin-bottom: 0px;

        &::before {
            content: '';
            border-style: solid;
            border-width: 4px 0 4px 8px;
            height: 0;
            width: 0;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            position: absolute;
            border-color: transparent $color-kim-secundario1 transparent $color-kim-secundario1;
        }
    }
}

.uppercase{
    text-transform: uppercase;
}

.underline{
    text-decoration: underline;
}

.block-reward-dashboard-collections{
    .block-title{
        @include lib-text(extra);
        text-align: center;
    }
}

.action.view{
    font-style: italic;
    text-decoration: underline;
    font-size: 1.3rem;
    &.centered {
        display: block;
        text-align: center;
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__l-3) {
    .page-header.fixed {
        .logged-in{
            .navigation .enlaces-secundarios > li > a{
                padding: 6px 14px 3px;
            }
        }
    }
}

@include max-screen($screen__l-2) {
    .category-top-banner {
        .ctb-text {
            font-size: 1.4rem;
        }
    }
    .page-header.fixed {
        .custom-header{
            &.logged-in{
                > .custom-menu-sec {
                    display: none;
                }
            }
        }
    }
}

@include max-screen($screen__l) {
    .header.content {
        padding-top: 15px;
    }

    .footer {
        .footer-subvencion {
            margin: auto;

            .kit-digital {
                margin: auto;
                text-align: center;
            }
        }
    }
}

@include max-screen($screen__m) {

    #return-to-top {
        height: 40px;
        width: 40px;
        span {
            display: none;
        }
    }

    .footer {

        .footer.links,
        .footer-social,
        .footer-text,
        .copyright,
        .footer-services {
            padding-left: $layout__width-xs-indent;
            padding-right: $layout__width-xs-indent;
        }

        .footer-services > div {
            margin-bottom: $indent__s;
            width: 33%;

            & > .replace-svg {
                display: none;
            }
        }
        .footer-text {
            p {
                max-width: 640px;
                margin: 2.5em 3.2em 2.6em;
            }
            margin-bottom: $indent__l;
        }

        .footer.links {

            grid-template-columns: 30% 69%;
            -ms-grid-columns: 30% 69%;
            grid-template-areas: "info links";
            margin-bottom: $indent__xxl;

            .footer-info {
                grid-area: info;
                -ms-grid-row: 1;
                -ms-grid-column: 1;

                .logo-footer {
                    display: none;
                }
            }
            .enlaces-footer {
                grid-area: links;
                -ms-grid-row: 1;
                -ms-grid-column: 2;
                -webkit-column-count: 2;
                column-count: 2;
                @supports (display: grid) {
                    grid-template-columns: repeat(2, 50%);
                    grid-template-rows: repeat(12, auto);
                    grid-auto-flow: column;
                    -webkit-column-count: unset;
                    column-count: unset;

                    a:nth-child(4){
                        grid-row-start: 5;
                    }
                    a:nth-child(6){
                        grid-row-start: 1;
                    }
                }

            }
            hr {
                display: none;
            }
            @supports (display: -ms-grid) {
                .enlaces-footer {
                    max-height: 150px;
                    display: flex;
                    flex-wrap: wrap;
                    flex-direction: column;
                    a {
                        width: 48%;
                    }
                }
            }
        }

        .footer-social {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
            margin-bottom: $indent__s;

            .social-icons {
                flex: 0 1 100%;
                text-align: center;
                margin-bottom: $indent__m;
            }

            .paying-methods {
                flex: 0 1 100%;
                text-align: center;

                & > span {
                    text-align: left;
                    margin-right: $indent__base;
                }

                .sitio-seguro {
                    padding-left: $indent__base;
                    display: inline-block;

                    &::before {
                        content: "";
                        position: absolute;
                        height: 100%;
                        max-height: 20px;
                        left: 0;
                        width: 1px;
                        background-color: $color-kim-gris2;
                        top: 50%;
                        -webkit-transform: translateY(-50%);
                        transform: translateY(-50%);
                    }
                }
            }
        }
    }
    .subcategories{
        margin-top: $indent__s;
    }
}

@include max-screen($screen__s) {

    .footer {

        .footer.links {
            display: block;
            margin-bottom: 0;

            .footer-info {
                text-align: center;
                margin-right: 0;
                .logo-footer {
                    display: inline;
                    margin-bottom: 16px;
                }
            }
            .enlaces-footer {
                margin: $indent__base $indent__base $indent__xxs;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                height: auto;
                max-height: 400px;
                overflow: hidden;
                transition: max-height 0.5s, overflow 0.5s 0.5s;

                a {
                    margin-bottom: $indent__base;
                    flex: 0 1 100%;
                }

                &.hide {
                    margin-top: 0;
                    margin-bottom: 0;
                    max-height: 0;
                    overflow:hidden;
                    transition: max-height 0.5s, overflow 0s;
                }

                & > .hide {
                    display: none;
                }
            }

            .view {
                margin: $indent__xs 0 0;
                display: block;

                span {
                    cursor: pointer;
                    @include lib-text(normal);
                    //font-family: $font-family__Raleway-medium-italic;
                    //font-style: italic;
                    font-weight: $font-weight__heavier;
                    font-style: $font-style__emphasis;
                    margin: 0;
                }
            }

            hr {
                display: block;
                margin-top: $indent__base;
            }
        }

        .footer-social {

            padding-top: $indent__m;

            .paying-methods {
                flex: 0 1 100%;
                text-align: center;

                & > span {
                    text-align: left;
                }

                .sitio-seguro {
                    padding-left: $indent__base;
                    display: block;
                    padding-top: $indent__xs;
                    margin-top: $indent__s;

                    &::before {
                        content: "";
                        position: absolute;
                        height: 100%;
                        max-height: 1px;
                        left: 50%;
                        width: 19px;
                        background-color: $color-kim-gris2;
                        top: 0;
                        -webkit-transform: translateX(-50%);
                        transform: translateX(-50%);
                    }
                }
            }
        }

        .footer-subvencion {
           .kit-digital {
                max-width: 270px;
            }
        }
    }

}

@include max-screen($screen__xs) {

    .page-header.fixed {
        .custom-header > .custom-menu-sec,
        .custom-header > .custom-menu-sec {
            display: none;
        }
    }

    .footer {

        .footer.links,
        .footer-social,
        .footer-text,
        .copyright,
        .footer-services {
            padding-left: $layout__width-xxs-indent;
            padding-right: $layout__width-xxs-indent;
        }

        .footer-services {

            justify-content: flex-start;

            .icon {
                grid-area: icon;
                margin: 0 $indent__m 0 $indent__base;
                -ms-grid-row: 1;
                -ms-grid-row-span:4;
                -ms-grid-column: 1;
            }

            p,
            a {
                text-align: left;
            }

            p:nth-of-type(1) {
                grid-area: title;
                -ms-grid-row: 1;
                -ms-grid-column: 2;
            }

            p:nth-of-type(2) {
                grid-area: subtitle;
                -ms-grid-row: 2;
                -ms-grid-column: 2;
            }

            p:nth-of-type(3) {
                grid-area: text;
                -ms-grid-row: 3;
                -ms-grid-column: 2;
            }

            a {
                grid-area: more-info;
                -ms-grid-row: 4;
                -ms-grid-column: 2;
                display: block;
            }

            & > div {
                display: grid;
                display: -ms-grid;
                align-items: center;
                width: 100%;
                grid-template-columns: 35% 65%;
                -ms-grid-columns: 35% 65%;
                grid-template-rows: repeat(4, auto);
                -ms-grid-rows: auto auto auto auto;
                grid-template-areas:
                        "icon title"
                        "icon subtitle"
                        "icon text"
                        "icon more-info";
            }

        }
        .footer-text {
            p {
                margin: 2em 1.01em;
            }
            margin-bottom: $indent__m;
        }

        .footer-social {

            .paying-methods {
                max-width: 340px;
                margin: 0 auto;
            }
        }

    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {

    html,
    body {
        height: 100%; // Stretch screen area for sticky footer
    }

    .page-wrapper {
        @include lib-vendor-prefix-display(flex);
        @include lib-vendor-prefix-flex-direction(column);
        min-height: 100%; // Stretch content area for sticky footer

        > .breadcrumbs,
        > .top-container,
        > .widget {
            box-sizing: border-box;
            width: 100%;
        }

        .ie10 &,
        .ie11 & {
            height: 100%;
        }
    }

    .page-main {
        > .page-title-wrapper {
            .page-title {
                display: inline-block;
            }

            .page-title + .action {
                float: right;
                margin-top: $indent__base;
            }
        }
    }

    .footer {
        &.content {
            .switcher.store {
                display: inline-block;
                padding-right: 50px;
                vertical-align: top;
            }
        }

        .copyright {
            @extend .abs-add-clearfix-desktop;
        }

        .footer.links,
        .footer-social,
        .footer-text,
        .copyright,
        .footer-services {
            padding-left: $layout-indent__width;
            padding-right: $layout-indent__width;
        }

        .footer-services {

            & > div {
                margin-bottom: $indent__l;
                position: relative;
                width: 33%;

                & > .replace-svg {
                    position: absolute;
                    top: 0;
                    left: 50%;
                    transform: translateX(-50%);
                    max-width: 96px;
                    z-index: -1;

                    .triangulon,
                    .triangulito {
                        opacity: 0;
                    }
                }
            }

            & > div:nth-child(1) .replace-svg {
                .triangulon {
                    fill: $color-kim-secundario3;
                }
                .triangulito {
                    fill: $color-kim-primario3;
                }
            }
            & > div:nth-child(2) .replace-svg {
                .triangulon {
                    fill: $color-kim-secundario2;
                }
                .triangulito {
                    fill: $color-kim-primario2;
                }
            }
            & > div:nth-child(3) .replace-svg {
                .triangulon {
                    fill: $color-kim-secundario4;
                }
                .triangulito {
                    fill: $color-kim-primario4;
                }
            }
            & > div:nth-child(4) .replace-svg {
                .triangulon {
                    fill: $color-kim-secundario2;
                }
                .triangulito {
                    fill: $color-kim-primario2;
                }
            }
            & > div:nth-child(5) .replace-svg {
                .triangulon {
                    fill: $color-kim-secundario1;
                }
                .triangulito {
                    fill: $color-kim-primario1;
                }
            }

            & > div:hover .triangulon {
                opacity: 1;
                animation: movedcha 2s linear infinite alternate;
                -webkit-animation: movedcha 2s linear infinite alternate;
                -moz-animation: movedcha 2s linear infinite alternate;
                -o-animation: movedcha 2s linear infinite alternate;
            }


            & > div:hover .triangulito {
                opacity: 1;
                animation: moveizda 2.5s linear infinite alternate;
                -webkit-animation: moveizda 2.5s linear infinite alternate;
                -moz-animation: moveizda 2.5s linear infinite alternate;
                -o-animation: moveizda 2.5s linear infinite alternate;
            }


        }
        .footer-text {
            p {
                max-width: 825px;
                margin: 2.2em auto;
            }
            margin-bottom: $indent__xl;
        }


        .footer.links {
            grid-template-columns: 30% 69%;
            -ms-grid-columns: 30% 69%;
            grid-template-areas: "info links";
            margin-bottom: $indent__xxxl;

            .footer-info {
                grid-area: info;
                -ms-grid-row: 1;
                -ms-grid-column: 1;
                display: flex;
                flex-direction: row;
                align-items: flex-start;

                .logo-footer {
                    display: none;
                }
            }
            .enlaces-footer {
                grid-area: links;
                -ms-grid-row: 1;
                -ms-grid-column: 2;
                -webkit-column-count: 2;
                column-count: 2;
                @supports ( display: grid ) {
                    -webkit-column-count: unset;
                    column-count: unset;

                    grid-template-columns: repeat(2, 50%);
                    grid-template-rows: repeat(12, auto);
                    grid-auto-flow: column;

                    a:nth-child(4){
                        grid-row-start: 5;
                    }
                    a:nth-child(6) {
                        grid-row-start: 1;
                    }
                }
            }
            hr {
                display: none;
            }

            @supports (display: -ms-grid) {
                .enlaces-footer {
                    max-height: 150px;
                    display: flex;
                    flex-wrap: wrap;
                    flex-direction: column;
                    a {
                        width: 48%;
                    }
                }
            }
        }

        .footer-social {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
            margin-bottom: $indent__m;

            .social-icons {
                flex: 0 1 100%;
                text-align: center;
                margin-bottom: $indent__m;
            }

            .paying-methods {
                flex: 0 1 100%;
                text-align: center;

                .sitio-seguro {
                    padding-left: $indent__base;
                    display: inline-block;

                    &::before {
                        content: "";
                        position: absolute;
                        height: 100%;
                        max-height: 20px;
                        left: 0;
                        width: 1px;
                        background-color: $color-kim-gris2;
                        top: 50%;
                        -webkit-transform: translateY(-50%);
                        transform: translateY(-50%);
                    }
                }

            }
        }
    }
}

@include min-screen($screen__m-2) {

    .page-header.fixed {
        .header.content {
            strong.logo,
            a.logo {
                flex: 0 1 93px;
            }
        }
    }
    .footer {

        .footer-social {

            .social-icons {
                flex: 0 1 auto;
                text-align: left;
                margin-bottom: 0;
            }

            .paying-methods {
                flex: 0 1 auto;
                text-align: left;
            }
        }
    }

}

@include max-screen($screen__xxs) {
    .block-custom-minicart{
        margin-right: 5px;
    }
}

@include min-screen($screen__l) {

    .footer {

        .footer.links {
            grid-template-columns: repeat(2, 50%);
            -ms-grid-columns: 50% 50%;

            .footer-info .logo-footer {
                margin-right: $indent__s;
                display: block;
            }
        }
        .footer-social {

            .social-icons {
                margin-left: 109px;
            }
        }

        .footer-subvencion {
            margin-left: auto;
            margin-right: 0;
            width: 50%;

            .kit-digital {
                margin: 0;
            }
        }
    }

}

@include min-screen($screen__l-4) {
    .footer {

        .footer-services > div {
            width: 20%;

        }

        .footer-social {

            .social-icons {
                flex: 0 1 auto;
                text-align: left;
                margin-left: 109px;
                margin-bottom: 0;
            }

            .paying-methods {
                flex: 0 1 50%;
                text-align: left;
            }
        }
    }
}

//
//  Sequra widget
//  ---------------------------------------------
@media screen and (min-width: $screen__m) {

    .sequra-promotion-widget {
        max-width: 315px;
        margin-right: auto;
        border-color: $color-kim-gris2 !important;
    }
}

@media screen and (min-width:$screen__xs) and (max-width: $screen__m) {
    .sequra-promotion-widget {
        width: 55%;
    }
}
