//
//  Variables
//  _____________________________________________

$checkout-tooltip__hover__z-index               : $tooltip__z-index !default;
$checkout-tooltip-icon__color                   : $color-gray-light2 !default;
$checkout-tooltip-icon__content                 : $icon-help !default;
$checkout-tooltip-icon__hover__color            : $primary__color !default;

$checkout-tooltip-content-mobile-popup__width   : 200px !default;

//
//  Common
//  _____________________________________________

.field-tooltip {
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 1px;

    .label {
        display: none;
    }

    &._active {
        @include lib-css(z-index, $checkout-tooltip__hover__z-index);

        .field-tooltip-content {
            display: block;
        }

        .field-tooltip-action {
            &:before {
                @include lib-css(color, $checkout-tooltip-icon__hover__color);
            }
        }
    }

    .field-tooltip-action {
        @include lib-icon-font(
            $checkout-tooltip-icon__content,
            $_icon-font-size         : $checkout-tooltip-icon__font-size,
            $_icon-font-text-hide    : true,
            $_icon-font-color        : $checkout-tooltip-icon__color,
            $_icon-font-color-hover  : $checkout-tooltip-icon__hover__color,
            $_icon-font-color-active : false
        );

        &:focus {
            ._keyfocus & {
                @include lib-css(z-index, $checkout-tooltip__hover__z-index);

                + .field-tooltip-content {
                    display: block;
                }

                &:before {
                    @include lib-css(color, $checkout-tooltip-icon__hover__color);
                }
            }
        }
    }

    .field-tooltip-content {
        @include lib-css(background, $checkout-tooltip-content__background-color);
        @include lib-css(border, $checkout-tooltip-content__border-width solid $checkout-tooltip-content__border-color);
        @include lib-css(border-radius, $checkout-tooltip-content__border-width);
        @include lib-css(font-size, $checkout-tooltip-content__font-size);
        @include lib-css(padding, $checkout-tooltip-content__padding);
        @include lib-css(width, $checkout-tooltip-content__width);
        display: none;
        position: absolute;
        @include lib-css(right, $checkout-tooltip-content-mobile__right);
        @include lib-css(top, $checkout-tooltip-content-mobile__top);
        left: auto;
        text-transform: none;
        word-wrap: break-word;
        z-index: 2;

        &:before,
        &:after {
            @include lib-arrow(
                    $_position : top,
                    $_size     : $checkout-tooltip-icon-arrow__font-size,
                    $_color    : $checkout-tooltip-content__background-color
            );
            @include lib-css(margin-top, $checkout-tooltip-icon-arrow__left);
            @include lib-css(right, $indent__s);
            left: auto;
            top: 0;
            content: '';
            display: block;
            position: absolute;
            z-index: 3;
        }

        &:before {
            @include lib-css(border-bottom-color, $checkout-tooltip-content__active__border-color);
        }

        &:after {
            @include lib-css(border-bottom-color, $checkout-tooltip-content__background-color);
            width: 0;
            z-index: 4;
            top: 1px;
        }
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__xs) {
    .modal-popup {
        .field-tooltip {
            .field-tooltip-content {
                @include lib-css(width, $checkout-tooltip-content-mobile-popup__width);
            }
        }
    }
}

@include max-screen($screen__m) {
    .field-tooltip {
        .field-tooltip-content {
            @extend .abs-checkout-tooltip-content-position-top-mobile;
        }
    }
}
