//
//  Variables
//  _____________________________________________

$checkout-sidebar-shipping-information__padding                : $indent__l !default;
$checkout-sidebar-shipping-information__line-height            : $checkout-billing-address-details__line-height !default;

$checkout-sidebar-shipping-information-edit-icon__color        : $minicart-icons-color !default;
$checkout-sidebar-shipping-information-edit-icon__content      : $icon-settings !default;
$checkout-sidebar-shipping-information-edit-icon__font-size    : 28px !default;
$checkout-sidebar-shipping-information-edit-icon__line-height  : 28px !default;
$checkout-sidebar-shipping-information-edit-icon__top          : 2px !default;
$checkout-sidebar-shipping-information-edit-icon__hover__color : $primary__color !default;

//
//  Shipping Information
//  ---------------------------------------------

.opc-block-shipping-information {


    .shipping-information-title {
        @extend .abs-checkout-title;
        @include lib-css(border-bottom, $checkout-step-title__border);
        margin: 0 0 $indent__base;
        position: relative;

        .action-edit {
            @extend .abs-action-button-as-link;
            @include lib-css(top, $checkout-sidebar-shipping-information-edit-icon__top);
            @include lib-icon-font(
                "",
                $_icon-font-size         : $checkout-sidebar-shipping-information-edit-icon__font-size,
                $_icon-font-line-height  : $checkout-sidebar-shipping-information-edit-icon__line-height,
                $_icon-font-text-hide    : true,
                $_icon-font-color        : $checkout-sidebar-shipping-information-edit-icon__color,
                $_icon-font-color-hover  : $checkout-sidebar-shipping-information-edit-icon__hover__color,
                $_icon-font-color-active : $checkout-sidebar-shipping-information-edit-icon__color
            );
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M23.2 2.9L21 .7c-.9-.9-2.6-.9-3.5 0L2.1 16.1c0 .1-.1.1-.1.2l-2 7c0 .2 0 .4.1.5.1.1.2.1.4.1h.1l7-2c.1 0 .2-.1.2-.1L23.2 6.4c.5-.5.8-1.1.8-1.7 0-.7-.3-1.3-.8-1.8zm-.7 2.8L7.2 21l-6 1.7 1.7-6L18.2 1.4c.6-.6 1.6-.6 2.1 0l2.2 2.2c.6.6.6 1.5 0 2.1z'/%3E%3Cpath d='M23.2 2.9L21 .7c-.9-.9-2.6-.9-3.5 0L2.1 16.1c-.2.2-.2.5 0 .7.2.2.5.2.7 0L18.2 1.4c.6-.6 1.6-.6 2.1 0l2.2 2.2c.6.6.6 1.5 0 2.1L7.1 21.1c-.2.2-.2.5 0 .7.1.1.2.1.4.1s.3 0 .4-.1L23.2 6.4c1-1 1-2.5 0-3.5z'/%3E%3Cpath d='M7.8 21.1l-5-5c-.2-.2-.5-.2-.7 0-.2.2-.2.5 0 .7l5 5c.1.1.2.1.4.1s.3 0 .4-.1c.1-.2.1-.5-.1-.7zM20.8 3.1c-.2-.2-.5-.2-.7 0l-14 14c-.2.2-.2.5 0 .7.1.1.3.2.4.2s.3 0 .4-.1l14-14c.1-.3.1-.6-.1-.8z'/%3E%3C/svg%3E");
            background-repeat: no-repeat;
            background-position: center;
            margin: 0;
            position: absolute;
            right: 0;
            display: block;
            width: 20px;
            height: 20px;
            &:hover{
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cg fill='%237acae3'%3E%3Cpath d='M23.2 2.9L21 .7c-.9-.9-2.6-.9-3.5 0L2.1 16.1c0 .1-.1.1-.1.2l-2 7c0 .2 0 .4.1.5.1.1.2.1.4.1h.1l7-2c.1 0 .2-.1.2-.1L23.2 6.4c.5-.5.8-1.1.8-1.7 0-.7-.3-1.3-.8-1.8zm-.7 2.8L7.2 21l-6 1.7 1.7-6L18.2 1.4c.6-.6 1.6-.6 2.1 0l2.2 2.2c.6.6.6 1.5 0 2.1z'/%3E%3Cpath d='M23.2 2.9L21 .7c-.9-.9-2.6-.9-3.5 0L2.1 16.1c-.2.2-.2.5 0 .7.2.2.5.2.7 0L18.2 1.4c.6-.6 1.6-.6 2.1 0l2.2 2.2c.6.6.6 1.5 0 2.1L7.1 21.1c-.2.2-.2.5 0 .7.1.1.2.1.4.1s.3 0 .4-.1L23.2 6.4c1-1 1-2.5 0-3.5z'/%3E%3Cpath d='M7.8 21.1l-5-5c-.2-.2-.5-.2-.7 0-.2.2-.2.5 0 .7l5 5c.1.1.2.1.4.1s.3 0 .4-.1c.1-.2.1-.5-.1-.7zM20.8 3.1c-.2-.2-.5-.2-.7 0l-14 14c-.2.2-.2.5 0 .7.1.1.3.2.4.2s.3 0 .4-.1l14-14c.1-.3.1-.6-.1-.8z'/%3E%3C/g%3E%3C/svg%3E");
            }
        }
    }

    .shipping-information-content {
        @include lib-css(line-height, $checkout-sidebar-shipping-information__line-height);
        .actions-toolbar {
            margin-left: 0;
        }
    }

    .ship-to,
    .ship-via {
        margin: 0 0 $indent__base;
    }
}
