.abc-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    list-style: none;
    margin: 0 auto;
    padding: 0;
    width: 50%;
    li {
        display: inline;
        margin: 0 8px 0 0;
        width: 6.5%;
        a {
            @include lib-text(extra);
            padding: 0 $indent__xs;
            &.others {
                @include lib-text(grande);
            }
        }
        &.no-activa a {
            color: $color-kim-gris2;
            cursor: auto;
            &.current {
                color: #fff;
                background-color: $color-kim-gris1;
            }
            &.others {
                line-height: 1.9;
            }
        }
        &.activa a {
            &.current {
                color: #fff;
                //font-family: $font-family__Raleway-medium;
                font-weight: $font-weight__heavier;
                background-color: $color-kim-gris1;
            }
            &.others {
                line-height: 1.9;
            }
        }
    }
}
.tag-list {
    list-style: none;
    margin: 0 auto;
    padding: 0;
    text-align: center;
    width: 60%;
    li {
        display: inline-block;
        margin: $indent__xxs $indent__xs;
        a {
            //font-family: $font-family__Raleway-semibold;
            font-weight: $font-weight__semibold;
            @include lib-text(pequeno);
            padding: 4px 8px;
            background: $color-kim-gris3;
            span.svg {
                svg {
                    height: 8px;
                    width: 8px;
                    polygon.color {
                        &-1{
                            fill: $color-kim-primario1;
                        }
                        &-2{
                            fill: $color-kim-primario2;
                        }
                        &-3{
                            fill: $color-kim-primario3;
                        }
                        &-4{
                            fill: $color-kim-primario4;
                        }
                        &-5{
                            fill: $color-kim-primario5;
                        }
                        &-6{
                            fill: $color-kim-primario6;
                        }
                    }
                }
            }
            span.text {
                font-weight: $font-weight__semibold;
                @include lib-text(pequeno);
                text-transform: uppercase;
            }
        }
    }
}

//
//  Desktop large
//  _____________________________________________

@include max-screen($screen__xl) {
    .abc-list {
        width: 70%;
    }
}

//
//  Desktop
//  _____________________________________________


@include max-screen($screen__l) {
    .abc-list {
        width: 99%;
    }
}
//
//  Tablet
//  _____________________________________________

@include max-screen($screen__m) {
    .tag-list {
        width: 70%;
    }
}

@include max-screen($screen__s) {
    .tag-list {
        width: 80%;
    }
}

@include max-screen($screen__xs) {
    .tag-list {
        width: 100%;
    }
}