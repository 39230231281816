.blog-page {
    .sidebar-main,
    .sidebar-additional {
        .block.widget {
            margin-bottom: $indent__m;
        }
        .block-title {
            @include lib-font-size($font-size__l);
            font-weight: $font-weight__semibold;
            text-transform: uppercase;
            display: block;
            padding: $indent__xxs 0;
            margin-bottom: $indent__xxs;
        }
    }

    .blog-search {
        .control {
            position: relative;
        }
        .action.search {
            @include lib-custom-button-reset();
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            min-width: unset;
            padding: $indent__xxs;

            svg {
                margin: 0;
            }
        }
    }

    .block-archive {
        .list-item {
            .item.hide ~ .item {
                display: none;
            }
        }
        .view {
            @include lib-button-as-link();
            @include lib-link-quinary();
            padding: $indent__xxs 0;
        }
        .hide {
            display: none;
        }
    }

    .block-categories,
    .block-archive {
        ul {
            @include lib-list-reset-styles();
        }
        li {
            margin-top: 0;
            margin-bottom: 0;
        }
        li + li {
           margin-top: $indent__xxxs;
        }
        a {
            display: inline-block;
            padding: $indent__xxs 0;
        }
        .category-childs {
            padding-left: $indent__xxs;
        }
    }

    .block-recent-posts,
    .block-featured-posts,
    .block-popular-posts {
        .post-recent-item {
            display: flex;
            align-items: center;
            margin-top: 0;

            & + .post-recent-item {
                margin-top: $indent__xxxs;
            }

            &__image {
                flex-basis: 40%;
                flex-shrink: 0;
                margin-right: $indent__xxs;
            }

            &__link {
                display: inline-block;
                padding: $indent__xxs 0;
            }
        }
    }

    .block-tagclaud {
        .tagclaud-hld {
            margin-left: -$indent__xxxs;
            margin-right: -$indent__xxxs;
        }
        .action {
            padding: $indent__xxxs/2 $indent__xxxs;
            display: inline-block;
            &.largest {
                @include lib-font-size($font-size__xl);
            }
            &.large {
                @include lib-font-size($font-size__l);
            }
            &.medium {
                @include lib-font-size($font-size__l);
            }
            &.small {
                @include lib-font-size($font-size__base);
            }
            &.smallest {
            }
        }
    }
}
