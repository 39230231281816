$layout-column-main__sidebar-offset       : 2% !default;
$layout-column__additional-sidebar-offset : $layout-column-main__sidebar-offset !default;

// Sidebar
$layout-column_sidebar-desktop1-width     : 232px - $layout-indent__width;
$layout-column_sidebar-desktop2-width     : 282px - $layout-indent__width;

body.cms-index-index #maincontent .page.messages {
    margin: 0 auto;
}
.columns {
    @include lib-layout-columns();

    .column.main {
        @extend .abs-add-box-sizing;
        @include lib-css(padding-bottom, $indent__xl);
        /*@include lib-vendor-prefix-flex-basis(100%);*/
        @include lib-vendor-prefix-flex-grow(1);
        @include lib-vendor-prefix-order(2);
        width: 100%;
        position: relative;
    }

    .sidebar-main {
        @extend .abs-add-box-sizing;
        @include lib-vendor-prefix-flex-grow(1);
        @include lib-vendor-prefix-flex-basis(100%);
        @include lib-vendor-prefix-order(1);
    }

    .sidebar-additional {
        @extend .abs-add-box-sizing;
        @include lib-vendor-prefix-flex-grow(1);
        @include lib-vendor-prefix-flex-basis(100%);
        @include lib-vendor-prefix-order(2);
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .breadcrumbs,
    .submenu1,
    .page-header:not(.fixed),
    .page-header.fixed .content.header,
    .page-main,
    .page-wrapper > .widget,
    .page-wrapper > .page-bottom,
    .block.category.event,
    .top-container {
        padding-left: $layout__width-xs-indent;
        padding-right: $layout__width-xs-indent;
    }

    .page-main {
        // .account &,
        .cms-privacy-policy & {
            padding-top: 41px;
            position: relative;
        }
    }
}

@include max-screen($screen__s-2) {
    .sections.nav-sections,
    .submenu2 {
        padding-left: $layout__width-xs-indent;
        padding-right: $layout__width-xs-indent;
    }
}

@include max-screen($screen__xs) {
    .sections.nav-sections {
        padding-left: $layout__width-xs-indent;
        padding-right: $layout__width-xs-indent;
    }
}

@include max-screen($screen__xxs) {
    .breadcrumbs,
    .page-header:not(.fixed),
    .page-header.fixed .content.header,
    .page-main,
    .page-wrapper > .widget,
    .page-wrapper > .page-bottom,
    .block.category.event,
    .top-container,
    .sections.nav-sections {
        padding-left: $layout__width-xxs-indent;
        padding-right: $layout__width-xxs-indent;
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .navigation,
    .submenu1,
    .breadcrumbs,
    .page-header:not(.fixed),
    .page-header.fixed .custom-header,
    .page-wrapper > .widget,
    .page-wrapper > .page-bottom,
    .block.category.event,
    .top-container,
    .page-main {
        box-sizing: border-box;
        margin-left: auto;
        margin-right: auto;
        max-width: $layout__max-width;
        padding-left: $layout-indent__width;
        padding-right: $layout-indent__width;
        width: 100%;
    }

    .footer.content {
        box-sizing: border-box;
        margin-left: auto;
        margin-right: auto;
        max-width: $footer__max-width;
        width: auto;
    }

    .page-main {
        width: 100%;

        // These flex properties should be combined to `flex: 1 0 auto`
        // sass-lint:disable no-css-comments
        /* stylelint-disable declaration-block-no-redundant-longhand-properties */
        @include lib-vendor-prefix-flex-grow(1);
        @include lib-vendor-prefix-flex-shrink(0);
        @include lib-vendor-prefix-flex-basis(auto);
        /* stylelint-enable declaration-block-no-redundant-longhand-properties */
        // sass-lint:enable  no-css-comments

        .ie9 & {
            width: auto;
        }
    }

    .columns {
        display: block;
    }

    .column.main {
        @extend .abs-add-box-sizing-desktop;
        @include lib-layout-columns__main();
        min-height: 300px;
    }

    body.page-layout-2columns-left .column.main {
        padding-left: 1.8em;
    }

    body.page-layout-2columns-right .column.main {
        padding-right: 1.8em;
    }

    /*body.cms-index-index #maincontent .column.main,
    body.cms-index-index #maincontent .page.messages,*/
    body.page-layout-2columns-left .column.main,
    body.page-layout-2columns-right .column.main {
        width: calc(100% - 200px);
    }

    .page-layout-3columns .column.main {
        width: calc(100% - 400px);
    }

    .sidebar-main {
        @extend .abs-add-box-sizing-desktop;
        @include lib-layout-columns__left();
        padding-right: $layout-column-main__sidebar-offset;
    }

    .page-layout-2columns-left,
    .page-layout-2columns-right,
    .category-view.page-layout-2columns-left,
    .catalogsearch-result-index.page-layout-2columns-left {
        .sidebar-main {
            width: $layout-column_sidebar-desktop1-width;
            float: none;
        }
    }

    .page-layout-2columns-right .sidebar-main {
        padding-left: $layout-column-main__sidebar-offset;
        padding-right: 0;
    }

    .page-main {
        position: relative;
    }

    .sidebar-additional {
        @extend .abs-add-box-sizing-desktop;
        @include lib-layout-columns__right();
        clear: right;
        padding-left: $layout-column__additional-sidebar-offset;
    }

    .page-layout-2columns-left {
        .sidebar-main,
        .sidebar-additional {
            clear: left;
            float: left;
            padding-left: 0;
            padding-right: $layout-column__additional-sidebar-offset;
            width: $layout-column_sidebar-desktop1-width;
        }
    }

    .page-layout-2columns-right {
        .sidebar-main,
        .sidebar-additional {
            clear: right;
            float: right;
            padding-right: 0;
            padding-left: $layout-column__additional-sidebar-offset;
            width: $layout-column_sidebar-desktop1-width;
        }
    }
    .page-layout-3columns {
        .sidebar-main,
        .sidebar-additional {
            width: $layout-column_sidebar-desktop1-width;
        }
    }

    .panel.header {
        padding: 10px 20px;
    }

    body.cms-index-index .column.main {
        padding-left: 0;
    }
}

@include min-screen($screen__xxl) {

    /*body.cms-index-index #maincontent .column.main,
    body.cms-index-index #maincontent .page.messages,*/
    body.page-layout-2columns-left .column.main,
    body.page-layout-2columns-right .column.main  {
        width: calc(100% - 250px);
    }
    body.checkout-index-index .column.main {
        margin: 0 auto;
    }
    .page-layout-2columns-left,
    .page-layout-2columns-right,
    .page-layout-3columns,
    .category-view.page-layout-2columns-left,
    .catalogsearch-result-index.page-layout-2columns-left {
        .sidebar-main,
        .sidebar-additional {
            width: $layout-column_sidebar-desktop2-width;
        }
    }
    .page-layout-3columns .column.main {
        width: calc(100% - 500px);
    }

}
