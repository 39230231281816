$blog-autor-img__max-width          : 100px;
$blog-post-autor-img__max-width     : 75px;

.blog-page {
    .author {
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        &__image {
            flex-basis: $blog-autor-img__max-width;
            flex-grow: 0;
            border-radius: 50%;
            overflow: hidden;
            margin-right: $indent__base;
            img {
                width: 100%;
            }
        }

        &__description {
            flex-basis: calc(100% - (#{$blog-autor-img__max-width} + #{$indent__base}));
        }

        &__content {
            flex-basis: 100%;
        }
    }

    .post-bottom {
        .author {
            border-top: 1px solid $color-kim-gris2;

            @include max-screen($screen__m) {
                margin-top: $indent__m;
                padding-top: $indent__m;
            }

            @include max-screen($screen__xs) {
                margin-top: $indent__s;
                padding-top: $indent__s;
            }

            @include min-screen($screen__m) {
                margin-top: $indent__xl;
                padding-top: $indent__xl;
            }

            &__image {
                flex-basis: $blog-post-autor-img__max-width;
            }

            &__description {
                flex-basis: calc(100% - (#{$blog-post-autor-img__max-width} + #{$indent__base}));
            }

            &__readmore {
                flex-basis: 100%;
                .btn {
                    padding: $indent__xxs 0;
                }
            }
        }
    }
}
