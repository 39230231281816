//  Newsletter subscription
.block.newsletter {
    margin-bottom: $indent__xl;
    background: url(../images/bg_newsletter-kimidori.svg) no-repeat center bottom;
    background-size: cover;
    
    .title,
    .content{
        max-width: 450px;
        margin: 0 auto;
    }

    .form.subscribe {
        display: flex;
        flex-direction: row;
        flex-flow: wrap;
        align-items: flex-start;
        text-align: center;
        justify-content: center;
        width: 100%;

        & > span,
        .clausula-newsletter {
            display: block;
            @include lib-text(pequeno);
            flex: 0 1 100%;
            margin-top: $indent__base;
            a {
                @include lib-link-secondary();
            }
        }
    }

    .fieldset {
        margin: 0;
        padding: 0;
        vertical-align: top;
    }

    .field {
        margin: 0;
        max-width: 290px;
        width: 100%;

        .control {
            display: block;
        }
    }
    .label {
        @include lib-text(grande);
        text-align: center;
        display: block;
        margin-bottom: $indent__base;
    }

    input {
        padding-left: $indent__xxs;
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .block.newsletter {
        padding: $indent__xxl $layout__width-xs-indent 88px;
    }
}

@include max-screen($screen__xxs) {
    .block.newsletter {
        padding: $indent__l $layout__width-xxs-indent $indent__xxxl;
    }

    .form.subscribe {
        .actions {
            order: 2;
            margin-top: $indent__base;
        }

        & > span {
            margin-top: $indent__xxs;
            order: 1;
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .block.newsletter {
        padding: $indent__xl $layout-indent__width 88px;

        .field {
            .control {
                width: 100%;
            }
        }
    }
}
