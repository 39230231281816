.message.info {
    @include lib-message-icon-inner(info);
    background-color: $color-kim-primario3;
    color: inherit;
}

.message.error {
    @include lib-message-icon-inner(error);
    background-color: $color-kim-secundario2;
    color: inherit;
}

.message.warning {
    @include lib-message-icon-inner(warning);
}

.message.notice {
    @include lib-message-icon-inner(notice);
}

.message.success {
    @include lib-message-icon-inner(success);
}

.highlight-link{
    a{
        font-weight: 600;
        text-transform: uppercase;
    }
}