//
//  Variables
//  _____________________________________________

$autocomplete__background-color  : $color-white !default;
$autocomplete__border            : 1px solid $form-element-input__border-color !default;
$autocomplete-item__border       : 1px solid $color-gray90 !default;
$autocomplete-item__hover__color : $color-gray91 !default;
$autocomplete-item-amount__color : $color-gray60 !default;

.block-search {
    margin-bottom: 0;

    .block {
        &-title {
            display: none;
        }
    }

    .block-content {
        margin-bottom: 0;
    }

    .close-btn,
    .input-btn,
    label {
        svg {
            width: 2.4rem;
            height: 2.4rem;
            cursor: pointer;
        }
    }

    .close-btn,
    .input-btn {
        @include lib-custom-button-reset();
        display: inline-block;
        padding: $indent__xxs;

        span {
            display: none;
        }
    }

    label.active {
        + .control {
            left: 0;
            max-height: 64px;
            transition: max-height 0.5s, overflow 0.5s 0.5s;
            overflow: hidden;

            .input-btn {
                margin-right: $indent__xxxs;
            }
            .close-btn {
                margin-right: -$indent__xxs;
            }
            input {
                margin-top: 0;
                margin-bottom: 0;
            }
        }
        svg {
            cursor: unset;
            opacity: 0.2;
        }
    }

    form.minisearch.active {
        .field.search .label.active svg {
            opacity: 0.2;
            -webkit-transition: opacity 0.7s;
            -moz-transition: opacity 0.7s;
            -ms-transition: opacity 0.7s;
            -o-transition: opacity 0.7s;
            transition: opacity 0.7s;
        }
    }

    .control {
        position: absolute;
        overflow: hidden;
        max-height: 0;
        height: auto;
        transition: max-height 0.5s, overflow 0s;
        left: 0;
        right: 0;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        z-index: 100;

        .actions {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 0;
        }

        & > svg {
            max-width: 24px;
            vertical-align: middle;
            margin-left: 10px;
            cursor: pointer;
        }
    }

    input {
        width: calc(100% - 37px);
        margin-bottom: $indent__xxs;
    }

    .nested {
        display: none;
    }
}

.search-autocomplete {
    @extend .abs-add-box-sizing;
    display: none;
    margin-top: -15px;
    overflow: hidden;
    position: absolute;
    z-index: 3;

    ul {
        @include lib-list-reset-styles();

        li {
            @include lib-css(border-top, $autocomplete-item__border);
            cursor: pointer;
            margin: 0;
            padding: $indent__xs $indent__xl $indent__xs $indent__s;
            position: relative;
            text-align: left;
            white-space: normal;

            &:not(:empty) {
                border-top: 0;
                @include lib-css(border, $autocomplete__border);
                @include lib-css(background, $autocomplete__background-color);
            }

            &:first-child {
                border-top: none;
            }

            &:hover,
            &.selected {
                @include lib-css(background, $autocomplete-item__hover__color);
            }

            .amount {
                @include lib-css(color, $autocomplete-item-amount__color);
                position: absolute;
                right: 7px;
                top: $indent__xs;
            }
        }
    }
}

.form.search.advanced {
    .fields.range {
        .field {
            &:first-child {
                position: relative;

                .control {
                    padding-right: 25px;

                    &:after {
                        content: ' \2013 ';
                        display: inline-block;
                        position: absolute;
                        right: 0;
                        text-align: center;
                        top: 6px;
                        width: 25px;
                    }
                }
            }

            &:last-child {
                position: relative;

                div.mage-error[generated] {
                    left: 0;
                    position: absolute;
                    top: 32px;
                }
            }

            &.with-addon {
                .control {
                    padding-right: 45px;
                }
            }
        }
    }

    .group.price {
        .addon {
            .addafter {
                background: none;
                border: 0;
                padding-top: 6px;
                position: absolute;
                right: 0;
                top: 0;
            }
        }
    }
}

.search.summary {
    margin-bottom: $indent__s;
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__l) {
    .block-search {
        .control {
            top: 100%;
            padding-left: $layout-indent__width;
            padding-right: $layout-indent__width;
            .actions {
                right: $layout-indent__width;
            }
        }
        .label.active + .control {
            box-shadow: 1px 4px 6px 0 rgba(0, 0, 0, 0.2);
            input {
                margin-top: $indent__xxs;
                margin-bottom: $indent__xxs;
            }
        }
    }
    .page-header:not(.fixed) {
        .block-search {
            .control {
                margin-right: -$layout-indent__width;
                margin-left: -$layout-indent__width;
            }
        }
    }
}
@include max-screen($screen__m) {
    .block-search {
        .block-content {
            margin-bottom: 0;
        }
        .control {
            background-color: $color-white;
            margin-left: -$layout__width-xs-indent;
            margin-right: -$layout__width-xs-indent;
            padding-left: $layout__width-xs-indent;
            padding-right: $layout__width-xs-indent;
            .actions {
                right: $layout__width-xs-indent;
            }
        }

        .close-btn,
        .input-btn {
            transform: none;
            position: relative;
            height: $form-element-input__height;

            > span {
                @include lib-visually-hidden();
            }

            svg {
                margin: 0;
            }
        }
    }

    .page-header:not(.fixed) {
        .block-search {
            .control {
                margin-left: -$layout__width-xs-indent;
                margin-right: -$layout__width-xs-indent;
            }
            form.active .control{
                padding-bottom: 18px;
            }
        }
    }
}

@include max-screen($screen__xxs) {
    .block-search {
        .control {
            margin-left: -$layout__width-xxs-indent;
            margin-right: -$layout__width-xxs-indent;
            padding-left: $layout__width-xxs-indent;
            padding-right: $layout__width-xxs-indent;

            .actions {
                right: $layout__width-xxs-indent;
            }
        }
    }
    .page-header:not(.fixed) {
        .block-search {
            .control {
                margin-left: -$layout__width-xxs-indent;
                margin-right: -$layout__width-xxs-indent;
            }
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .block-search {
        .block-content {
            margin-bottom: 0;
        }
        .control {
            background-color: $color-white;
            input {
                margin-top: $indent__xxs;
            }
        }

        .actions {
            .close-btn,
            .input-btn {
                display: inline-block;
                z-index: 1;
                min-width: unset;

                span {
                    @include lib-visually-hidden();
                }

                &:hover {
                    color: $color-kim-primario1;
                }
            }

        }
    }

    .page-header.fixed {
        .block-search {
            .block-content {
                margin-bottom: 0;
            }
            .control {
                background-color: $color-white;
            }

            label.active {
                + .control {
                    max-height: 51px;
                }
            }
        }
    }
}

@include min-screen($screen__l) {

    .page-header:not(.fixed) {
        .block-search {
            position: relative;
            padding-left: 15px;
            z-index: 4;

            .label {
                //@extend .abs-visually-hidden-desktop;
                border: 0;
                clip: rect(0, 0, 0, 0);
                height: 1px;
                margin: -1px;
                overflow: hidden;
                padding: 0;
                position: absolute;
                width: 1px;
            }

            .control {
                border-top: 0;
                margin: 0;
                padding: 0;
                position: static;
                max-height: unset;

                & > svg {
                    display: none;
                }
            }

            .nested {
                display: block;
                padding-top: 5px;
                position: absolute;
            }

            input {
                @include lib-form-element-all();
                margin: 0;
                padding-right: 35px;
                width: 100%;
            }

            .close-btn {
                display: none;
            }
        }
        .search-autocomplete {
            margin-top: 0;
        }
    }
    .page-header.fixed {
        .block-search {
            .control {
                top: 40px;
                margin-left: $layout-indent__width;
                margin-right: $layout-indent__width;
            }
        }
    }

}

