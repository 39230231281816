$toolbar-mode-icon-font-size: 24px !default;
$toolbar-element-background : $panel__background-color !default;

.page-products {
    .columns {
        position: relative;
        z-index: 1;
    }
}

.toolbar {
    @extend .abs-add-clearfix;
}

.toolbar-amount {
    display: block;
    padding: 0;
    margin: 0;
    grid-area: amount;
    -ms-grid-row: 1;
    -ms-grid-column: 1;
    align-self: center;
    font-weight: $font-weight__heavier;
    font-style: $font-style__emphasis;

    .products.wrapper ~ .toolbar & {
        display: none;
    }
}

.toolbar-products {
    @extend .abs-add-clearfix;
    margin-bottom: $indent__xl;

    display: grid;
    display: -ms-grid;
    grid-template-areas:    "amount pages pages"
                            "line line line"
                            "mode limiter sorter";
    grid-template-columns: auto min-content min-content;
    -ms-grid-columns: auto min-content min-content;
    align-items: center;

    .products.wrapper ~ & {
        display: block;
    }

    hr {
        grid-area: line;
        -ms-grid-row: 2;
        -ms-grid-column: 1;
        -ms-grid-column-span: 3;
        width: 100%;
        margin: $indent__xxxs 0 $indent__xxs;
        border-color: $color-kim-gris2;
    }

    .pages {
        grid-area: pages;
        -ms-grid-row: 1;
        -ms-grid-column: 2;
        -ms-grid-column-span: 2;
        align-self: flex-end;
        padding: 0;
        display: flex;
        align-items: baseline;
        justify-content: flex-end;
        text-align: right;

        .products.wrapper ~ & {
            border-bottom: none;
        }
    }

    .limiter {
        grid-area: limiter;
        -ms-grid-row: 3;
        -ms-grid-column: 2;
        display: flex;
        align-items: baseline;

        .control {
            display: inline-block;
        }
        .label {
            margin-right: $indent__xxxs;
        }
    }
}

.sorter {
    grid-area: sorter;
    -ms-grid-row: 3;
    -ms-grid-column: 3;
    justify-self: flex-end;
    display: flex;
    align-items: baseline;

    .products.wrapper ~ .toolbar & {
        display: none;
    }

    .sorter-label {
        @include lib-font-size(11);
        margin-right: $indent__xxxs;
    }
}

.sorter-options {
    width: auto;
}

.sorter-action {
    vertical-align: top;
    @include lib-icon-font(
        $icon-arrow-up,
        $_icon-font-size       : 28px,
        $_icon-font-line-height: 32px,
        $_icon-font-color      : $header-icons-color,
        $_icon-font-color-hover: $header-icons-color-hover,
        $_icon-font-text-hide  : true
    );
}

.sorter {
    .sort-desc {
        &:before {
            content: $icon-arrow-down;
        }
    }
}

.modes {
    display: none;
    grid-area: mode;
    -ms-grid-row: 3;
    -ms-grid-column: 1;
}

.limiter-options {
    margin: 0;
    width: auto;
    height: auto;
}

.limiter-label {
    font-weight: 400;
}

.limiter {

    .label {
        @include lib-font-size(11);
    }


    .products.wrapper ~ .toolbar & {
        display: none;
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .toolbar-products {
        grid-template-areas: "amount pages""line line""limiter sorter";
        grid-template-columns: auto auto;
        -ms-grid-columns: auto auto;
    }

    .limiter,
    .pages {
        .label {
            display: none;
        }
    }
}

@include max-screen($screen__s) {
    .toolbar-amount {
        @include lib-font-size(11);
    }
}

@include max-screen($screen__xxs) {
    .toolbar-sorter {
        label {
            display: none;
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .page-products {
        .columns {
            padding-top: 0;
            position: relative;
            z-index: 1;
        }
    }

    .limiter {
        justify-self: flex-end;
    }

    .sorter {
        margin-left: $indent__xxxl;
    }

    .modes {
        display: block;

        .products.wrapper ~ .toolbar & {
            display: none;
        }
    }

    .modes-label {
        @extend .abs-visually-hidden-desktop;
    }

    .modes-mode {
        font-size: 0;
        display: inline-block;
        font-weight: $font-weight__regular;
        line-height: 1;
        text-align: center;
        vertical-align: middle;

        img, svg {
            width: 58px;
            height: 30px;
            .st0 {
                fill: $color-kim-gris2;
            }
        }

        &:not(.active) {
            &:hover {
                svg .st0 {
                    fill: $color-kim-primario1;
                }
            }
        }

        &.active {
            svg .st0 {
                fill: $color-kim-gris1;
            }
        }
    }

    .mode-list {
        margin-right: $indent__base;
        img, svg {
            width: 38px;
        }
    }
}
