.blog-page {
    .subcategory-links {
        @include lib-list-reset-styles();
        border-top: 1px solid $color-kim-gris2;
        padding: $indent__xxs 0 $indent__base;

        &__item {
            margin: $indent__xxs 0 0;
        }
    }
    .subcategory-item {
        &__link {
            display: inline-block;
            padding: $indent__xxs 0;
        }
    }

    .post-links {
        @include lib-list-reset-styles();
        border-top: 1px solid $color-kim-gris2;
        padding: $indent__base 0;
    }

    .post-link-item {
        display: flex;
        align-items: center;
        margin-top: 0;

        & + .post-link-item {
            margin-top: $indent__xxxs;
        }

        &__image {
            flex-basis: 40%;
            flex-shrink: 0;
            margin-right: $indent__xxs;
        }

        &__link {
            display: inline-block;
            padding: $indent__xxs 0;
        }
    }
}
