//
//  Variables
//  _____________________________________________


$checkout-summary-title__margin     : $indent__s !default;
$checkout-summary-mark-value__color : $color-gray60 !default;

$checkout-summary-items__max-height : 370px !default;
$checkout-summary-items__padding    : 15px !default;

//
//  Order Summary
//  ---------------------------------------------

.order-summary {
    @include lib-css(background, $checkout-summary__background-color);
    @include lib-css(padding, $checkout-summary__padding);
    margin: 0 0 $indent__base;

    .step-title {
        background-color: transparent;
    }
    .opc-block-summary.step-content {
        padding: 0;
        margin: $indent__xxs 0 0;
    }


    @include max-screen($screen__xxs) {
        @include lib-css(padding, $checkout-summary__padding $indent__xs);
    }
}
.opc-summary-wrapper {
    .modal-inner-wrap {
        background-color: $color-kim-gris3;
        padding: 8px;
    }
}
.opc-block-summary {
    @extend .abs-add-box-sizing;
    padding: 0!important;
    &__wrapper{
        position: relative;
        .title {
            @extend .abs-checkout-title;
            display: block;
        }
    }
    .action-edit {
        @extend .abs-action-button-as-link;
        @include lib-css(top, $checkout-sidebar-shipping-information-edit-icon__top);
        @include lib-icon-font(
                "",
            $_icon-font-size         : $checkout-sidebar-shipping-information-edit-icon__font-size,
            $_icon-font-line-height  : $checkout-sidebar-shipping-information-edit-icon__line-height,
            $_icon-font-text-hide    : true,
            $_icon-font-color        : $checkout-sidebar-shipping-information-edit-icon__color,
            $_icon-font-color-hover  : $checkout-sidebar-shipping-information-edit-icon__hover__color,
            $_icon-font-color-active : $checkout-sidebar-shipping-information-edit-icon__color
        );
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M23.2 2.9L21 .7c-.9-.9-2.6-.9-3.5 0L2.1 16.1c0 .1-.1.1-.1.2l-2 7c0 .2 0 .4.1.5.1.1.2.1.4.1h.1l7-2c.1 0 .2-.1.2-.1L23.2 6.4c.5-.5.8-1.1.8-1.7 0-.7-.3-1.3-.8-1.8zm-.7 2.8L7.2 21l-6 1.7 1.7-6L18.2 1.4c.6-.6 1.6-.6 2.1 0l2.2 2.2c.6.6.6 1.5 0 2.1z'/%3E%3Cpath d='M23.2 2.9L21 .7c-.9-.9-2.6-.9-3.5 0L2.1 16.1c-.2.2-.2.5 0 .7.2.2.5.2.7 0L18.2 1.4c.6-.6 1.6-.6 2.1 0l2.2 2.2c.6.6.6 1.5 0 2.1L7.1 21.1c-.2.2-.2.5 0 .7.1.1.2.1.4.1s.3 0 .4-.1L23.2 6.4c1-1 1-2.5 0-3.5z'/%3E%3Cpath d='M7.8 21.1l-5-5c-.2-.2-.5-.2-.7 0-.2.2-.2.5 0 .7l5 5c.1.1.2.1.4.1s.3 0 .4-.1c.1-.2.1-.5-.1-.7zM20.8 3.1c-.2-.2-.5-.2-.7 0l-14 14c-.2.2-.2.5 0 .7.1.1.3.2.4.2s.3 0 .4-.1l14-14c.1-.3.1-.6-.1-.8z'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: center;
        margin: 0;
        position: absolute;
        right: 0;
        display: block;
        width: 20px;
        height: 20px;
        &:hover{
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cg fill='%237acae3'%3E%3Cpath d='M23.2 2.9L21 .7c-.9-.9-2.6-.9-3.5 0L2.1 16.1c0 .1-.1.1-.1.2l-2 7c0 .2 0 .4.1.5.1.1.2.1.4.1h.1l7-2c.1 0 .2-.1.2-.1L23.2 6.4c.5-.5.8-1.1.8-1.7 0-.7-.3-1.3-.8-1.8zm-.7 2.8L7.2 21l-6 1.7 1.7-6L18.2 1.4c.6-.6 1.6-.6 2.1 0l2.2 2.2c.6.6.6 1.5 0 2.1z'/%3E%3Cpath d='M23.2 2.9L21 .7c-.9-.9-2.6-.9-3.5 0L2.1 16.1c-.2.2-.2.5 0 .7.2.2.5.2.7 0L18.2 1.4c.6-.6 1.6-.6 2.1 0l2.2 2.2c.6.6.6 1.5 0 2.1L7.1 21.1c-.2.2-.2.5 0 .7.1.1.2.1.4.1s.3 0 .4-.1L23.2 6.4c1-1 1-2.5 0-3.5z'/%3E%3Cpath d='M7.8 21.1l-5-5c-.2-.2-.5-.2-.7 0-.2.2-.2.5 0 .7l5 5c.1.1.2.1.4.1s.3 0 .4-.1c.1-.2.1-.5-.1-.7zM20.8 3.1c-.2-.2-.5-.2-.7 0l-14 14c-.2.2-.2.5 0 .7.1.1.3.2.4.2s.3 0 .4-.1l14-14c.1-.3.1-.6-.1-.8z'/%3E%3C/g%3E%3C/svg%3E");
        }
    }
    //  Totals table
    .table-totals {
        @extend .abs-sidebar-totals;
    }

    .mark {
        .value {
            @include lib-css(color, $checkout-summary-mark-value__color);
            display: block;
        }
    }

    .grand.incl {
        & + .grand.excl {
            .mark,
            .amount {
                border-top: 0;
                @include lib-font-size(14);
                padding-top: 0;

                strong {
                    font-weight: $font-weight__regular;
                }
            }
        }
    }

    .not-calculated {
        font-style: italic;
    }

    #checkout-review-table {
        thead {
            display: none;
        }
        .product-item {
            .details-qty {
                width: 60%;

                .product-item-name {
                    padding: 0;
                    width: 100%;
                    word-wrap: normal;
                }
            }
        }
        @include max-screen($screen__l) {
            tbody > tr > td {
                padding: $indent__xxs $indent__xxxs;
            }
        }
    }

    //
    //  Items list
    //  ---------------------------------------------

    //  Block title
    .items-in-cart {
        > .title {
            border-bottom: $border-width__base solid $border-color__base;
            @include lib-css(padding, $indent__base $indent__l $indent__base 0);
            cursor: pointer;
            margin-bottom: 0;
            position: relative;

            &:after {
                content: '';
                position: absolute;
                background: url('../images/icon-flearriba-kimidori.svg') no-repeat center right;
                width: 24px;
                height: 24px;
                top: 11px;
                right: 0;
                transform: rotate(180deg);
            }

            strong {
                @include lib-font-size(15);
                font-weight: $font-weight__regular;
                margin: 0;
                text-align: left;
                text-transform: uppercase;
            }
        }

        &.active {
            > .title {
                &:after{
                    transform: rotate(0deg);
                }
            }
        }

        .product {
            display: flex;
            align-items: end;
            position: relative;
            text-align: center;

            > .product-image-container {
                float: none;
                height: 80px;
                width: 75px;
                .product-image-wrapper img {
                    max-width: 80%;
                }
            }
            > .product-item-details {
                padding-left: 16px;
                width: 100%;
            }
        }
    }

    //  Cart items
    .minicart-items-wrapper {
        @include lib-css(max-height, $checkout-summary-items__max-height);
        border: 0;
        padding-right: 0;
    }

    .column.main & {
        .product-item {
            margin: 0;
            padding-left: 0;
        }
    }

    .product-item {
        .product-item-inner {
            display: table;
            margin: 0 0 $indent__s;
            width: 100%;
        }

        .product-item-name-block {
            display: table-cell;
            padding-right: $indent__xs;
            text-align: left;
        }

        .subtotal {
            display: table-cell;
            text-align: right;
        }

        .price {
            font-size: $font-size__base;
            font-weight: $font-weight__regular;
        }

        .price-including-tax {
            & + .price-excluding-tax {
                margin: 0;

                .price {
                    font-size: $font-size__s;
                }
            }
        }
        @include max-screen($screen__xxs) {
            .product-item-name,
            .price {
                font-size: $font-size__s;
            }
        }
    }

    .actions-toolbar {
        position: relative;
        z-index: 1;

        .secondary {
            border-top: $border-width__base solid $border-color__base;
            display: block;
            float: none;
            margin: -1px 0 0;
            padding: 15px 0 0;
            text-align: left;

            .action {
                margin: 0;
            }
        }
    }
}

//
//  Mobile
//  _____________________________________________


@include max-screen($screen__xs) {

    .opc-block-summary .minicart-items-wrapper {
        .product-image-container {
            max-width: 100px;
        }
        td {
            &.details-qty,
            &.subtotal {
                box-sizing: border-box;
                float: left;
                text-align: left;
                white-space: nowrap;
                display: inline-block;
                width: 100%;
                padding: $indent__xxs 0 $indent__xxs 120px;

                .price-including-tax,
                .price-excluding-tax,
                .field.qty{
                    display: inline-block;
                    margin-bottom: 0;
                }
            }
            &.subtotal {
                margin-bottom: $indent__xxs;
            }
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .opc-summary-wrapper {
        .modal-header {
            .action-close {
                display: none;
            }
        }
    }
}
