//
//  Product Lists
//  _____________________________________________
@mixin lib-price-box() {
    margin-bottom: $indent__xs;
    position: relative;
    display: grid;
    display: -ms-grid;
    grid-template-areas:  "final original discount"
                                  "final vat discount"
                                  "text text discount";
    grid-template-columns: min-content auto min-content;
    -ms-grid-columns: min-content auto min-content;
    justify-content: flex-start;
    align-items: flex-end;

    .price-label {
        display: none;
    }
    .vat {
        grid-area: vat;
        -ms-grid-row: 2;
        -ms-grid-column: 2;
    }
    .old-price,
    .price-container {
        grid-area: original;
        -ms-grid-row: 1;
        -ms-grid-column: 2;
    }
    .special-price,
    .price-container.tax {
        grid-area: final;
        -ms-grid-row: 1;
        -ms-grid-row-span: 2;
        -ms-grid-column: 1;
        margin-right: $indent__xxs;
    }
    .price-container .price {
        @include lib-font-size(12);
    }

    .special-price .price,
    > .price-container .price {
        @include lib-heading(h1);
    }

    .discount-label {
        grid-area: discount;
        -ms-grid-row: 1;
        -ms-grid-row-span: 3;
        -ms-grid-column: 3;
    }
    .text {
        grid-area: text;
        -ms-grid-row: 3;
        -ms-grid-column: 1;
        -ms-grid-column-span: 2;
        //font-family: $font-family__Raleway-medium-italic;
        font-weight: $font-weight__heavier;
        font-style: $font-style__emphasis;
        @include lib-text(pequeno);
        color: $color-kim-primario1;
        margin-top: $indent__xxs;
        margin-bottom: 0;
    }
}


ol.products {
    margin: $indent__l 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @supports (display: grid) {
        display: grid;
        grid-auto-flow: dense;
    }
}
ol#related-products-ol {
    display: block;
    &.slick-slider {
        .slick-prev.slick-arrow {
            background-color: #fff;
            left: 0;
        }
        .slick-next.slick-arrow {
            background-color: #fff;
            right: 0;
        }
        li {
            .towishlist {
                svg {
                    vertical-align: middle;
                    width: 2.4rem;
                    height: 2.4rem;
                    &.filled-heart {
                        display: none;
                    }
                }
                &:hover {
                    .unfilled-heart {
                        display: none;
                    }
                    .filled-heart {
                        display: inline;
                    }
                }
            }
        }
    }

}

.slick-slider button.slick-arrow {
    font-size: 0;
}

.stock.unavailable{
    padding: 0.6rem 1rem;
}
@supports (display: -ms-grid) {
    ol.products {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;

        .product-vista-previa {
            flex: 0 1 0;

            &.active {
                flex: 0 1 100%;
            }
        }
    }
}
.product-vista-previa {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s, overflow 0s;
    grid-column: 1 / -1;

    .vista-previa {
        display: flex;
        position: relative;

        .product-img,
        .product-info {
            flex: 0 1 50%;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
        }
        .product-img {
            flex: 0 1 40%;
            padding: $indent__s $indent__s;
        }
        .product-info {
            flex: 0 1 60%;
            padding: $indent__s $indent__m $indent__s $indent__s;
            background-color: $color-kim-gris3;

            .product.name,
            .product.name .product-item-link {
                @include lib-heading(h2);
            }

            .product.name {
                justify-content: flex-start;
            }

            .product-item-description {
                margin: $indent__s 0;
                .value {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-height: 105px;
                }
                .action.more {
                    @include lib-link-quaternary();
                    margin-top: $indent__s;
                    padding: $indent__xxxs 0;
                    display: block;
                }
            }

            form {
                display: flex;
            }

            .action.towishlist {
                margin-top: $indent__s;

                > span {
                    display: inline-block;
                    height: auto;
                    margin: 0;
                    position: relative;
                    width: auto;
                    vertical-align: middle;
                }
                img, svg {
                    width: 2.4rem;
                    height: 2.4rem;
                    vertical-align: middle;
                }

            }
            .last-units{
                margin-bottom: $indent__s;
            }
            .price-box {
                @include lib-price-box();
            }

            .field.qty,
            .actions {
                display: inline-block;
                vertical-align: top;
            }

            .field.qty {
                margin-right: $indent__base;
                margin-bottom: $indent__xxs;
                .label {
                    display: none;
                }
                .control {
                    font-size: 0;
                    display: flex;
                }
            }

            input.qty {
                width: $tocart-width;
                vertical-align: middle;
                text-align: center;
                border-color: $color-kim-gris2;
                border-left: none;
                border-right: none;
                font-size: $font-size__xl;
            }

            .input-qty.minus,
            .input-qty.plus {
                vertical-align: middle;
                cursor: pointer;
                display: inline-block;
                border: 1px solid $color-kim-gris2;
                box-sizing: border-box;
                padding: $indent__xxs;
                background-color: $color-kim-gris3;
                width: $tocart-width;
                height: $tocart-height;
                position: relative;
                transition: background 1s, border 1s;

                svg {
                    color: $color-kim-gris2;
                }

                &:hover {
                    background-color: $color-kim-gris1;
                    border: 1px solid $color-kim-gris1;
                    transition: background 1s, border 1s;
                }
            }
        }

        @include min-screen($screen__l-4) {
            .product-img,
            .product-info {
                flex: 0 1 50%;
            }

            .product-img{
                padding: $indent__l $indent__m;
            }
            .product-info {
                padding: $indent__l $indent__xl $indent__l $indent__m;

                .product-item-description {
                    margin: $indent__m 0;
                    .action.more {
                        margin-top: $indent__s;
                    }
                }
            }
        }

        .close-view {
            position: absolute;
            top: 0;
            right: 0;
            padding: $indent__xxs;
            cursor: pointer;

            svg {
                width: 1.7rem;
                height: 1.7rem;
            }
        }
    }

    &.active {
        border-top: 1px solid $color-kim-gris2;
        border-bottom: 1px solid $color-kim-gris2;
        max-height: 650px;
        overflow: hidden;
        transition: max-height .5s, overflow 0.5s 0.5s;
    }

    & > span {
      height: 350px;
      display: block;
    }

    & > div {
      transition: opacity 1s linear;
      &.hide {
        opacity: 0;
      }
    }

    &.active > div.show {
        opacity: 1;
    }
    .product-image-container{
        display: block;
        margin: 0 auto;
    }
    .special-product-labels {
        left: 0;
    }
}

.product {
    &-items {
        @extend .abs-reset-list;
    }


    &-item {
        @extend .abs-add-box-sizing;
        vertical-align: top;

        &-name {
            @extend .abs-product-link;
            margin: $indent__base 0;
            word-wrap: break-word;
            hyphens: auto;
            display: flex;
            flex-grow: 1;
            justify-content: center;
        }

        &-info {
            text-align: center;
            margin: 0 auto;
            position: relative;
            padding: $indent__base;
            box-sizing: border-box;

            display: flex;
            flex-direction: column;
            flex-grow: 1;
            max-width: 100%;
            width: 100%;

            &:hover {
                box-shadow: $box-shadow-kim-sombra1;
            }
        }

        .special-product-labels {
            position: absolute;
            z-index: 2;
            top: 0;
            left: 0;
            max-width: 150px;
            text-align: left;
        }

        &-actions {

            .product.alert.stock .alert {
                display: inline-block;
            }

            .actions-secondary {
                margin-top: $indent__xxs;
                & > .action {
                    @extend .abs-actions-addto-gridlist;
                    &:before {
                        content: none;
                    }

                    span {
                        @extend .abs-visually-hidden;
                    }
                }

                .link-vista-previa {
                    display: none;
                    padding-right: $indent__xs;
                    padding-left: $indent__xs;
                    position: relative;
                    color: $color-kim-gris1;
                    -webkit-transition: color 1s;
                    -moz-transition: color 1s;
                    -ms-transition: color 1s;
                    -o-transition: color 1s;
                    transition: color 1s;
                    &:after {
                        content: '';
                        position: absolute;
                        top: 50%;
                        right: 0;
                        transform: translateY(-50%);
                        height: 20px;
                        width: 1px;
                        background-color: $color-kim-gris2;
                    }
                    &:hover {
                        color: $color-kim-primario1;
                    }
                    svg {
                        -webkit-transition: color 1s;
                        -moz-transition: color 1s;
                        -ms-transition: color 1s;
                        -o-transition: color 1s;
                        transition: color 1s;
                    }
                }

                .action.towishlist {
                    display: inline-block;
                }

                .link-vista-previa,
                .action.towishlist {
                    cursor: pointer;

                    span {
                        display: none;
                    }

                    .view-active {
                        display: none;
                    }
                    .view-disable {
                        display: inline-block;
                    }
                }
            }
        }

        &-description {
            margin: $indent__m 0;
        }

        .product-reviews-summary {
            .rating-summary {
                margin: 0 4px 0 0;
            }

            .reviews-actions {
                font-size: $font-size__s;
                margin-top: 5px;
                text-transform: lowercase;
            }
        }

        .price-box {
            padding: $indent__xxs 0;
            border-bottom: 1px solid $color-kim-gris2;
            position: relative;

            .price {
                font-size: 19px;
                //font-family: $font-family__Raleway-semibold;
                white-space: nowrap;
                font-weight: $font-weight__semibold;
            }

            .price-label {
                font-size: $font-size__s;
                display: none;

                &:after {
                    content: ':';
                }
            }
            .vat, .text {
                display: none;
            }

            .discount-label {
                position: absolute;
                top: -48px;
                right: 0;
            }
        }

        .special-price,
        .minimal-price {
            .price {
                font-size: 19px;
                //font-family: $font-family__Raleway-semibold;
                font-weight: $font-weight__semibold;
            }

            .price-wrapper {
                display: inline-block;
            }

            .price-including-tax + .price-excluding-tax {
                display: block;
            }
        }

        .special-price {
            display: inline-block;
        }

        .old-price {
            .price {
                margin-left: $indent__xxs;
                font-weight: $font-weight__regular;
                font-size: $font-size__base;
            }
        }

        .minimal-price {
            .price-container {
                display: block;
            }
        }

        .minimal-price-link {
            margin-top: 5px;

            .price-label {
                color: $link__color;
                font-size: 14px;
            }

            .price {
                font-weight: $font-weight__regular;
            }
        }

        .minimal-price-link,
        .price-excluding-tax,
        .price-including-tax {
            display: block;
            white-space: nowrap;
        }

        .price-from,
        .price-to {
            margin: 0;
        }

        .action.tocompare {
            @include lib-icon-font-symbol($icon-compare-empty);
        }

        &.active-view {
            position: relative;
            padding-bottom: 30%;

            &:after {
                content: '';
                position: absolute;
                bottom: 7px;
                left: 50%;
                -webkit-transform: translateX(-50%);
                -moz-transform: translateX(-50%);
                -ms-transform: translateX(-50%);
                -o-transform: translateX(-50%);
                transform: translateX(-50%);
                width: 40px;
                height: 40px;
                background: url("../images/item-flecha1-vistaprevia-kimidori.svg") no-repeat center;
            }

            .price-box,
            .product.name,
            .actions-primary,
            .last-units,
            .actions-secondary .action.towishlist {
                display: none;
            }

            .product-item-info {
                flex-grow: 0;
            }

            .actions-secondary {
                position: relative;
                margin-top: 26px;

                &:before,
                &:after {
                    background-color: $color-kim-gris2;
                    content: "";
                    display: inline-block;
                    height: 1px;
                    position: absolute;
                    vertical-align: middle;
                    width: calc(50% - 30px);
                    top: 50%;
                    transform: translateY(-50%);
                    opacity: 0.6;
                }

                &:before {
                    right: 0;
                }

                &:after {
                    left: 0;
                }

                .link-vista-previa {
                    padding: 0;

                    &:after {
                        content: unset;
                    }

                    .view-active {
                        display: inline-block;
                    }
                    .view-disable {
                        display: none;
                    }
                }
            }

            .product-item-photo {
                opacity: 0.6;
            }

        }
    }
}

.price-container {
    .price {
        font-size: 14px;
    }

    .price-including-tax + .price-excluding-tax,
    .weee {
        margin-top: $indent__xs;
    }

    .price-including-tax + .price-excluding-tax,
    .weee,
    .price-including-tax + .price-excluding-tax .price,
    .weee .price,
    .weee + .price-excluding-tax:before,
    .weee + .price-excluding-tax .price {
        font-size: 11px;
    }

    .weee {
        &:before {
            content: '('attr(data-label) ': ';
        }

        &:after {
            content: ')';
        }

        + .price-excluding-tax {
            &:before {
                content: attr(data-label) ': ';
            }
        }
    }
}
.products-grid,
.products-list {
    .product {
        &-item {
            width: 100%;
            display: flex;
            flex-direction: column;
            flex-shrink: 0;
            box-sizing: border-box;

            &-photo {
                vertical-align: top;
                width: 100%;
            }

            &-details {
                vertical-align: top;
                display: flex;
                flex-direction: column;
                flex-grow: 1;
            }
            .last-units{
                margin-top: -6px;
                margin-bottom: 12px;
            }
        }
    }

    /*.product-image-wrapper {
        @extend .abs-reset-image-wrapper;
    }*/
}

//
//  Mobile
//  _____________________________________________
@include max-screen($screen__m) {
    .products-list .products.product-items,
    .products-grid .products.product-items {
        grid-template-columns: repeat(3, minmax(140px, 360px));
    }

    @supports (display: -ms-grid) {
        .products-list .products.product-items .product-item,
        .products-grid .products.product-items .product-item {
            width: 33%;
        }
    }
}

@include max-screen($screen__s) {
    .products-list .products.product-items,
    .products-grid .products.product-items {
        grid-template-columns: repeat(2, minmax(140px, 360px));
    }

    @supports (display: -ms-grid) {
        .products-list .products.product-items .product-item,
        .products-grid .products.product-items .product-item {
            width: 50%;
        }
    }

    .products-list .product {
        &-item {
            table-layout: fixed;

            &-photo {
                display: block;
            }
        }
    }
}

@include max-screen($screen__xxxs-2) {
    .products-list .products.product-items,
    .products-grid .products.product-items {
        grid-template-columns: repeat(1, minmax(140px, 360px));
        justify-content: center;
    }

    @supports (display: -ms-grid) {
        .products-list .products.product-items .product-item,
        .products-grid .products.product-items .product-item {
            width: 100%;
        }
    }
}

@include min-screen($screen__s) {
    .product {
        &-item {

            &-actions {

                .actions-primary + .actions-secondary {
                    display: block;
                    white-space: nowrap;
                    font-size: 0;
                    & > * {
                        white-space: normal;
                    }

                    .action.towishlist {
                        padding-left: $indent__xs;
                        padding-right: $indent__xs;
                    }

                    @supports (display: grid) {
                        .link-vista-previa {
                            display: inline-block;
                        }
                    }
                }
            }
        }
        &-items {
            .product-vista-previa.active  {
                margin-bottom: $indent__l;
            }
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .products-list .products.product-items,
    .products-grid .products.product-items {
        grid-template-columns: repeat(4, minmax(140px, 360px));
    }

    @supports (display: -ms-grid) {
        .products-list .products.product-items .product-item,
        .products-grid .products.product-items .product-item {
            width: 25%;
        }
    }

}

@include min-screen($screen__l) {
    .products-grid .products.product-items {
        grid-template-columns: repeat(5, minmax(140px, 360px));
    }

    @supports (display: -ms-grid) {
        .products-grid .products.product-items .product-item {
            width: 20%;
        }
    }
}

@include min-screen($screen__l-4) {
    .products-grid .products.product-items {
        grid-template-columns: repeat(6, minmax(140px, 360px));
    }

    @supports (display: -ms-grid) {
        .products-grid .products.product-items .product-item {
            width: 16%;
        }
    }
}
