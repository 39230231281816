.h3_cms_aviso_legal_y_condiciones_de_uso{
    margin-top: $indent__m;
    margin-bottom: $indent__xs;
}

.h2_marginBottom_cms_aviso_legal_y_condiciones_de_uso {
    margin-bottom: $indent__xs;
}

.h2_marginTop_cms_aviso_legal_y_condiciones_de_uso {
    margin-top: $indent__m;
}

.h2_especial_cms_aviso_legal_y_condiciones_de_uso{
    margin-bottom: -$indent__ss;
}

.mini_margin_top_cms_aviso_legal_y_condiciones_de_uso{
    margin-top: $indent__s;
}