.cms-somos-kimidori {
    h2 {
        font-size: 1.5rem;
        background-color: $color-kim-gris3;
        padding: 1.1rem 2.7rem;
        font-weight: normal;
        text-transform: uppercase;
    }
    .we-are {
        &-intro {
            padding: 1rem 0 6.5rem;
            img {
                max-width: 100%;
            }
            .description {
                text-align: center;
                span {
                    display: block;
                    font-size: 5rem;
                    font-weight: 600;
                    color: $color-kim-primario5;
                    padding: 4rem 0;
                }
                .text {
                    display: inline-block;
                    max-width: 60%;
                    p {
                        text-align: left;
                    }
                }
            }
        }
        &-ideas {
            .board {
                position: relative;
                height: 120rem;
                .board-element {
                    position: absolute;
                    display: inline-block;
                    .text {
                        padding: 1.6rem;
                        background-color: $color-white;
                        transition: all .5s ease-in-out;
                        & > span {
                            display: block;
                            font-size: 2.4rem;
                            font-weight: 600;
                            margin-bottom: 1.6rem;
                        }
                    }
                    & > svg {
                        position: absolute;
                        z-index: -1;
                        width: 32rem;
                        height: 32rem;
                        transition: all .5s ease-in-out;
                    }
                    &:hover {
                        svg {
                            transform: scale(1.1);
                        }
                    }
                    &:nth-child(1) {
                        &:hover .text {
                            transform: translateX(4rem);
                        }
                        min-width: 30rem;
                        width: 25%;
                        top: 15rem;
                        left: 5%;
                        svg {
                            top: -10rem;
                            left: 20rem;
                        }
                    }
                    &:nth-child(2) {
                        &:hover .text {
                            transform: translateX(-3rem);
                        }
                        min-width: 30rem;
                        width: 35%;
                        top: 11rem;
                        right: 5%;
                        svg {
                            top: -6rem;
                            left: 19rem;
                        }
                    }
                    &:nth-child(3) {
                        &:hover .text {
                            transform: translateX(2rem);
                        }
                        min-width: 30rem;
                        width: 45%;
                        top: 45rem;
                        left: 25%;
                        svg {
                            top: -7rem;
                            left: -20rem;
                        }
                    }
                    &:nth-child(4) {
                        &:hover .text {
                            transform: translate(3rem, 2rem);
                        }
                        min-width: 30rem;
                        width: 25%;
                        top: 68rem;
                        right: 11%;
                        svg {
                            top: -5rem;
                            right: -12rem;
                        }
                    }
                    &:nth-child(5) {
                        &:hover .text {
                            transform: translateX(2rem);
                        }
                        min-width: 30rem;
                        width: 30%;
                        top: 90rem;
                        left: 5%;
                        svg {
                            top: -17rem;
                            left: -5rem;
                        }
                    }
                    &:nth-child(6) {
                        &:hover .text {
                            transform: translate(-3rem, -3rem);
                        }
                        min-width: 30rem;
                        width: 40%;
                        bottom: 5rem;
                        right: 5%;
                        svg {
                            top: -17rem;
                            left: -18rem;
                        }
                    }
                }
            }
        }
        &-bullet {
            .board {
                padding: 7.5rem 0;
                text-align: center;
                & > span {
                    font-style: italic;
                    font-size: 2rem;
                    font-weight: 600;
                    color: $color-kim-primario1;
                    display: block;
                }
                .bullet-image {
                    position: absolute;
                    opacity: 0;
                    transition: opacity 1s ease-in-out;
                    display: block;
                    width: 100%;
                    top: 0;
                    &.active {
                        opacity: 1;
                        position: static;
                    }
                }
                .bullet-list {
                    display: inline-block;
                    max-width: 40rem;
                    margin-top: 4rem;
                    ol {
                        padding-inline-start: 0;
                        .bullet-element {
                            & > span {
                                font-size: 2.4rem;
                                font-weight: 600;
                                line-height: 1.25;
                                color: $color-kim-gris1;
                                display: inline-block;
                                margin-bottom: 2rem;
                            }
                        }
                    }
                }
            }
            .bullet-pictures{
                position: relative;
            }
        }
    }
    .familia-image{
        display: block;
        text-align: center;
    }
}

@include max-screen($screen__xl) {
    .cms-somos-kimidori {
        .we-are{
            &-ideas {
                .board {
                    .board-element {
                        &:nth-child(1) {
                            left: 0;
                        }
                        &:nth-child(2) {
                            right: 10%;
                        }
                    }
                }
            }
        }
    }
}

@include max-screen(1200px) {
    .cms-somos-kimidori {
        .we-are{
            &-ideas {
                .board {
                    .board-element {
                        &:nth-child(2) {
                            right: 15%;
                        }
                    }
                }
            }
        }
    }

}
@include max-screen(1100px) {
    .cms-somos-kimidori {
        .we-are{
            &-ideas {
                .board {
                    .board-element {
                        & > svg {
                            width: 26rem;
                            height: 26rem;
                        }
                        &:nth-child(1) {
                            top: 11rem;
                        }
                        &:nth-child(2) {
                            top: 7rem;
                        }
                        &:nth-child(3) {
                            top: 43rem;
                        }
                        &:nth-child(4) {
                            top: 72rem;
                        }
                        &:nth-child(5) {
                            top: 94rem;
                        }
                        &:nth-child(6) {
                            bottom: 0;
                            right: 4%
                        }
                    }
                }
            }
        }
    }

}
@include max-screen(1023px) {
    .cms-somos-kimidori {
        .we-are{
            &-ideas {
                .board {
                    height: auto;
                    .board-element {
                        position: relative;
                        display: block;
                        box-sizing: border-box;
                        padding: 80px;
                        &:hover {
                            svg {
                                transform: none;
                            }
                        }
                        & > svg {
                            width: 30rem;
                            height: 30rem;
                        }
                        &:nth-child(1) {
                            top: 0;
                            right: 0;
                            left: 0;
                            width: 100%;
                            &:hover .text {
                                transform: none;
                            }
                            svg {
                                top: 0;
                                right: 0;
                                left: unset;
                            }
                        }
                        &:nth-child(2) {
                            top: 0;
                            right: 0;
                            left: 0;
                            width: 100%;
                            &:hover .text {
                                transform: none;
                            }
                            svg {
                                top: 0;
                                left: 50%;
                                transform: translateX(-50%);
                            }
                        }
                        &:nth-child(3) {
                            top: 0;
                            right: 0;
                            left: 0;
                            width: 100%;
                            &:hover .text {
                                transform: none;
                            }
                            svg {
                                top: 0;
                                left: 0;
                            }
                        }
                        &:nth-child(4) {
                            top: 0;
                            right: 0;
                            left: 0;
                            width: 100%;
                            &:hover .text {
                                transform: none;
                            }
                            svg {
                                top: 0;
                                right: 0;
                            }
                        }
                        &:nth-child(5) {
                            top: 0;
                            right: 0;
                            left: 0;
                            width: 100%;
                            &:hover .text {
                                transform: none;
                            }
                            svg {
                                top: 0;
                                left: 0;
                            }
                        }
                        &:nth-child(6) {
                            right: 0;
                            left: 0;
                            bottom: 0;
                            margin-bottom: 10px;
                            width: 100%;
                            &:hover .text {
                                transform: none;
                            }
                            svg {
                                top: 0;
                                left: 50%;
                                transform: translateX(-50%);
                            }
                        }
                    }
                }
            }
        }
    }
}
@include max-screen($screen__m) {
    .cms-somos-kimidori {
        .we-are {
            &-ideas {
                .board {
                    .board-element {
                        padding: 70px;
                    }
                }
            }
        }
    }
}
@include max-screen($screen__s) {
    .cms-somos-kimidori {
        .we-are {
            &-intro {
                .description {
                    span {
                        font-size: 4rem;
                    }
                    .text {
                        max-width: 100%;
                    }
                }
            }
            &-ideas {
                .board {
                    .board-element {
                        padding: 70px 0;
                    }
                }
            }
        }
    }
}
@include max-screen($screen__xxs) {
    .cms-somos-kimidori {
        .we-are {
            &-intro {
                .description {
                    span {
                        font-size: 2rem;
                    }
                }
            }
            &-ideas {
                .board {
                    .board-element {
                        padding: 70px 0;
                    }
                }
            }
            &-bullet {
                .board {
                    .bullet-list {
                        max-width: 100%;
                        .slick-slide{
                            margin: 7px 44px;
                        }
                    }
                }
            }
        }
    }
}