//
//  Kimidori lines
//  _____________________________________________

.linea-gris2 {
    width: auto;
    max-width: $layout__max-width;
    border-color: $color-kim-gris2;
    border-width: 1px;
    margin-top: 0;
    margin-bottom: 0;
}

.linea-gris3 {
    width: auto;
    max-width: $layout__max-width;
    border-color: $color-kim-gris3;
    border-width: 1px;
    margin-top: 0;
    margin-bottom: 0;
}
