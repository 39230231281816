.h3_cms_condiciones_de_envio_y_devoluciones{
    margin-top: $indent__m;
    margin-bottom: $indent__xs;
}

@include min-screen($screen__xxl) {
    .img_cms_condiciones_de_envio_y_devoluciones{
        margin: auto;
        display: block;
        width: 30%;
    }
}

@include max-screen($screen__xxl) {
    .img_cms_condiciones_de_envio_y_devoluciones{
        margin: auto;
        display: block;
        width: 40%;
    }
}

@include max-screen($screen__l) {
    .img_cms_condiciones_de_envio_y_devoluciones{
        margin: auto;
        display: block;
        width: 60%;
    }
}

@include max-screen($screen__s) {
    .img_cms_condiciones_de_envio_y_devoluciones{
        margin: auto;
        display: block;
        width: 80%;
    }
}

@include max-screen($screen__xxs) {
    .img_cms_condiciones_de_envio_y_devoluciones{
        margin: auto;
        display: block;
        width: 100%;
    }
}







