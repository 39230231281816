.actions-toolbar {
    > .primary,
    > .secondary {
        // text-align: center;
        .action {
            @extend .abs-button-responsive;
            margin-bottom: $indent__s;
            &:last-child {
                margin-bottom: 0;
            }
        }
        // margin-bottom: $indent__s;
        &:last-child {
            margin-bottom: 0;
        }
        &.action-login {
            width: 100%;
        }
    }
}
//
//  Desktop
//  _____________________________________________
@include min-screen($screen__m) {
    .actions-toolbar {
        @include lib-actions-toolbar();
        > .secondary,
        > .primary {
            margin-bottom: 3rem;
            .action {
                margin-bottom: 0;
                width: auto;
            }
        }
    }
    .checkout-index-index .actions-toolbar {
        > .primary {
            .action.continue.primary,
            .action.checkout.primary {
                width: 100%;
            }
        }
    }
    form {
        .actions-toolbar {
            .column:not(.sidebar-additional) &,
            .column:not(.sidebar-main) & {
                @extend .abs-margin-for-forms-desktop;
            }
        }
    }
}

//
//  Mobile
//  _____________________________________________
@include max-screen($screen__m) {
    .login-container .block-customer-login .actions-toolbar .secondary {
            margin-bottom: 16px;
    }
}