.contact-index-index{
    h2{
        @include lib-text(medio);
    }
    .form.contact{
        padding-left: 24px;
    }
}

.contact-top-info{
    margin-top: $indent__xs;
    padding-left: 24px;
}

.contact-bottom-info{
    padding-left: 24px;
}

.contact-aditional-info{
    margin-top: $indent__xxl;
}

.contact.form {
    margin: $indent__m 0 $indent__s;
    .action.submit{
        max-width: 380px;
    }
}

.happy-face-icon{
    width: 24px;
    height: 24px;
    background: url("../images/icon-carita-kimidori.png") no-repeat center center;
    -webkit-background-size: 24px;
    background-size: 24px;
    display: inline-block;
    margin-left: 10px;
}

.contact-top-info-intro{
    span{
        display: inline-block;
        vertical-align: middle;
    }
}
.extra-info-icon{
    color: $color-kim-primario1;
    font-size: $text-extragrande__font-size;
    line-height: 1.4rem;
    vertical-align: middle;
}

.light-title{
    @include lib-typography();
}


@include max-screen($screen__l-2) {
    .contact-index-index{
        .form.contact{
            padding-left: 0px;
        }
    }

    .contact-top-info,
    .contact-bottom-info{
        padding-left: 0px;
    }

}