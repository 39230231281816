//
//  Variables
//  _____________________________________________

$account-nav-background              : $sidebar__background-color !default;
$account-nav-color                   : false !default;

$account-nav-current-border          : 3px solid transparent !default;
$account-nav-current-border-color    : $color-orange-red1 !default;
$account-nav-current-color           : false !default;
$account-nav-current-font-weight     : $font-weight__semibold !default;

$account-nav-delimiter__border-color : $color-gray82 !default;

$account-nav-item-hover              : $color-gray91 !default;

$_password-default                   : $color-gray-light01 !default;
$_password-weak                      : #ffafae !default;
$_password-medium                    : #ffd6b3 !default;
$_password-strong                    : #c5eeac !default;
$_password-very-strong               : #81b562 !default;

.login-container {
    .block {
        &-new-customer {
            .actions-toolbar {
                margin-top: 25px;
            }
        }

        .block-title {
            @extend .abs-login-block-title;
            @include lib-font-size(18);
        }
    }

    .fieldset {
        @include lib-form-hasrequired(bottom);
        &:after {
            margin-top: 35px;
        }
    }
    #remember-me-box{
        margin-bottom: $indent__xs;
    }
}

.block-addresses-list {
    .items.addresses {
        > .item {
            margin-bottom: $indent__base;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

.block-dashboard-addresses, .block-dashboard-info,
.block-order-details-view, .block-addresses-default,
.block-addresses-list {
    border-bottom: 1px solid $color-kim-gris3;
    padding-bottom: 1.5rem;
    .block-title strong{
      text-transform: uppercase;
    }
    a {
      font-style: italic;
      text-decoration: underline;
    }
}

.block-dashboard-addresses, .block-dashboard-info,
.block-order-details-view, .block-addresses-default,
.block-addresses-list, .block-dashboard-greeting {
    margin-bottom: 2.8rem;
}

.block-dashboard-greeting {
    .info-header {
      grid-area: dashboard-title;
      font-size: 1.7rem;
      background-color: $color-kim-gris3;
      padding: .4rem .8rem;
      font-weight: 500;
      margin-top: 2.5rem;
    }
}

.block-dashboard-orders,
.orders-history,
#rewards-transaction-table{
    a {
      font-style: italic;
      text-decoration: underline;
    }
    tr {
      border-bottom: 1px solid $color-kim-gris3;
    }
}

.block-dashboard-greeting {
    display: grid;
    display: -ms-grid;
    grid-template-areas: "image title"
                         "image text"
                         "dashboard-title dashboard-title";
    grid-template-columns: 100px auto;
    -ms-grid-columns: 100px auto;

    .greeting-title {
      grid-area: title;
      font-size: 1.7rem;
      position: relative;
      display: inline-block;
      margin: 1.5rem 0;
      &::after {
        content: url(../images/icon-carita-kimidori.svg);
        display: inline-block;
        position: absolute;
        margin-left: 1rem;
        width: 2.4rem;
        height: 2.4rem;
      }
    }

    .greeting-text {
      grid-area: text;
    }
    .greeting-logo {
      grid-area: image;
      height: 88px;
      width: 80px;
    }
}

.form-address-edit {
    .actions-toolbar .action.primary {
        @extend .abs-button-l;
    }
}

.form-edit-account {
    .fieldset.password {
        display: none;
    }
}


//  Full name fieldset
.fieldset {
    .fullname {
        &.field {
            > .label {
                @extend .abs-visually-hidden;

                + .control {
                    width: 100%;
                }
            }
        }

        .field {
            @extend .abs-add-clearfix;
        }
    }
}

//
//  My account
//  ---------------------------------------------

.account {
    .column.main {
        h2 {
            margin-top: 0;
        }

        .toolbar {
            text-align: center;
            .limiter-options {
                width: auto;
            }
        }

        .limiter {
            >.label {
                @extend .abs-visually-hidden;
            }
        }

        .block:not(.widget) {
            @extend .abs-account-blocks;
        }
    }

    .sidebar-additional {
        margin-top: 40px;
    }

    .table-wrapper {
        &:last-child {
            margin-bottom: 0;
        }

        .action {
            margin-right: 15px;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    .table-return-items {
        .qty {
            .input-text {
                @extend .abs-input-qty;
            }
        }
    }
    .page-title-wrapper {
        .page-title{
            display: block;
            @include lib_text(grande);
            background-color: $color-kim-gris3;
            padding: .4rem .8rem;
            margin-top: 0;
            margin-bottom: 2.4rem;
            font-weight: 500;
        }
    }
}

//  Checkout address (create shipping address)
.field.street {
    .field.additional {
        .label {
            @extend .abs-visually-hidden;
        }
    }
}

//
//  Account navigation
//  ---------------------------------------------
.account .block-collapsible-nav-title {
    display: none;
}
.account-nav,
.account .block-collapsible-nav-content {

    margin-bottom: 1rem;
    .hidden {
      display: none;
    }
    .showing {
      display: block;
    }

    .title {
        @extend .abs-visually-hidden;
    }

    .content {
        @include lib-css(background, $account-nav-background);
        padding: 15px 0;
    }

    .item {
        margin: 3px 0 0;

        &:first-child {
            margin-top: 0;
        }

        a > strong {
          padding-left: inherit;
        }

        a,
        > strong {
            @include lib-css(color, $account-nav-color);
            border-left: 3px solid transparent;
            display: block;
            padding: .6rem 1.8rem .6rem 1.5rem;
        }

        a {
            text-decoration: none;

            // &:hover {
            //     @include lib-css(background, $account-nav-item-hover);
            // }
        }

        &.current,
        &.current:last-child{
            & > a,
            & > strong {
                font-style: normal;
                position: relative;
                @include lib-css(color, $account-nav-current-color);
                @include lib-css(font-weight, $account-nav-current-font-weight);
                &::before {
                    content: '';
                    border-style: solid;
                    border-width: 4px 0 4px 8px;
                    height: 0;
                    width: 0;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    position: absolute;
                    border-color: transparent $color-kim-secundario1 transparent $color-kim-secundario1;
                }
            }
        }

        .delimiter {
            display: block;
            margin: $indent__s 1.8rem;
        }
        &.level2{
            position: relative;
            a, strong {
                padding: .6rem 1.8rem .6rem 1.5rem;
                border-left: calc(1.5rem + 3px) solid transparent;
            }
            &.current,
            &.current:last-child{
                & > a,
                & > strong {
                    &:before{
                        border-color: transparent $color-kim-secundario2 transparent $color-kim-secundario2;
                    }
                }
            }
        }
        &:last-child a {
            font-style: italic;
            font-weight: 500;
            position:relative;
            &::before {
                content:'x';
                margin-right: .4rem;
            }
        }
    }
    ul.level2{
        display: none;
    }
}
.faq-nav{
    .item{
        &:not(.current):last-child a {
            font-style: normal;
            font-weight: 400;
            &::before {
                display: none;
            }
        }
    }
}

//
//  Blocks & Widgets
//  ---------------------------------------------

.block {
    @extend .abs-margin-for-blocks-and-widgets;
    .column.main & {
        &:last-child {
            margin-bottom: 0;
        }
    }

    .title {
        margin-bottom: $indent__xs;

        strong {
            @include lib-heading(h1);
            text-align: center;
            display: block;
        }
    }

    p:last-child {
        margin: 0;
    }

    .box-actions {
        margin-top: $indent__xs;
    }
}

//
//  Password Strength Meter
//  ---------------------------------------------

.field.password {
    .control {
        @include lib-vendor-prefix-display();
        @include lib-vendor-prefix-flex-direction();

        .mage-error {
            @include lib-vendor-prefix-order(2);
        }

        .input-text {
            @include lib-vendor-prefix-order(0);
        }
    }
}

.password-strength-meter {
    background-color: $_password-default;
    height: $form-element-input__height;
    line-height: $form-element-input__height;
    padding: $form-element-input__padding;
    position: relative;
    z-index: 1;

    &:before {
        content: '';
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        z-index: -1;
    }

    .password-none & {
        &:before {
            background-color: $_password-default;
            width: 100%;
        }
    }

    .password-weak & {
        &:before {
            background-color: $_password-weak;
            width: 25%;
        }
    }

    .password-medium & {
        &:before {
            background-color: $_password-medium;
            width: 50%;
        }
    }

    .password-strong & {
        &:before {
            background-color: $_password-strong;
            width: 75%;
        }
    }

    .password-very-strong & {
        &:before {
            background-color: $_password-very-strong;
            width: 100%;
        }
    }
}

.control.captcha-image {
    @include lib-css(margin-top, $indent__s);

    .captcha-img {
        vertical-align: middle;
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__s) {
    .account {
        .column.main,
        .sidebar-additional {
            margin: 0;
        }
    }
}

@include max-screen($screen__m) {
    .login-container {
        .fieldset {
            &:after {
                text-align: center;
            }
        }
    }

    .account {
        .page.messages {
            // margin-bottom: $indent__xl;
        }

        .toolbar {
            @extend .abs-pager-toolbar-mobile;
        }
        &.customer-rewards-index {
            .page-title-wrapper {
                margin-bottom: $indent__m;
            }
            .block-reward-dashboard {
                .block.reward-balance.row-mp {
                    margin-bottom: $indent__base;
                }
                .block.reward-information.mp-clear,
                .block.block-reward-dashboard-transaction {
                    margin-bottom: $indent__m;
                }
            }
        }
    }

    .control.captcha-image {
        .captcha-img {
            @include lib-css(margin-bottom, $indent__s);
            display: block;
        }
    }

    .customer-account-index {
        .page-title-wrapper {
            position: relative;
        }
    }
    .account .block-collapsible-nav-title {
        position: relative;
        text-align: center;
        font-size: 1.7rem;
        font-weight: $font-weight__semibold;
        display: block;
        cursor: pointer;
        border: 1px solid $color-kim-gris2;
        padding: 0 1rem;
        margin-bottom: 0;
        margin-top: 1rem;

        user-select: none;
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */

        &::after {
            content: url(../images/icon-fleabajo-kimidori.svg);
            position: absolute;
            width: 2.4rem;
            right: 1rem;
            bottom: 0.6rem;
        }

        &.active {
            border-bottom: 1px solid $color-kim-gris3;
            &:after {
                content: url(../images/icon-flearriba-kimidori.svg);
            }
        }
        strong {
            margin-top: 1.2rem;
        }
    }
    .account .block-collapsible-nav-content {
        display: none;
        &.active {
            border-top: none;
            display: block;
            margin-top: 0;
            padding-top: $indent__base;
        }
    }
    .account-nav,
    .account .block-collapsible-nav-content {
      border: 1px solid #dcdcdc;
      padding: 0 1rem;
      margin-top: 1rem;

      .title-my-account {
        position: relative;
        text-align: center;
        border-bottom: 1px solid $color-kim-gris3;
        padding: 1rem;
        font-size: 1.7rem;
        font-weight: $font-weight__semibold;
        display: block;
        cursor: pointer;

        user-select: none;
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */

        &::after {
            content: url(../images/icon-fleabajo-kimidori.svg);
            position: absolute;
            width: 2.4rem;
            right: 1rem;
        }
        &.opened::after {
          content: url(../images/icon-flearriba-kimidori.svg);
        }
        &.closed::after {
          content: url(../images/icon-fleabajo-kimidori.svg);
        }
      }
    }

    .block-dashboard-greeting {
      .greeting-logo, .greeting-title, .greeting-text {
        display: none;
      }
      .info-header {
        margin-top: 0;
      }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .login-container {
        @extend .abs-add-clearfix-desktop;

        .block {
            @extend .abs-blocks-2columns;
            &.login {
                .actions-toolbar {
                    > .primary {
                        margin-bottom: 0;
                        margin-right: $indent__l;
                    }

                    > .secondary {
                        float: left;
                    }
                }
            }
        }

        .fieldset {
            &:after {
                @extend .abs-margin-for-forms-desktop;
            }
        }
    }

    //  Full name fieldset
    .fieldset {
        .fullname {
            .field {
                .label {
                    @include lib-css(margin, $form-field-type-label-inline__margin);
                    @include lib-css(padding, $form-field-type-label-inline__padding);
                    @include lib-css(text-align, $form-field-type-label-inline__align);
                    @include lib-css(width, $form-field-type-label-inline__width);
                    box-sizing: border-box;
                    float: left;
                }

                .control {
                    @include lib-css(width, $form-field-type-control-inline__width);
                    float: left;
                }
            }
        }
    }

    .form.password.reset,
    .form.send.confirmation,
    .form.password.forget,
    .form.create.account {
        min-width: 600px;
        width: 50%;
    }

    // Update and forgot Password messages
    // ----------------------------------------------

    .fieldset{
        &.login,
        &.forgotpassword{
            .actions-toolbar .secondary {
                max-width: 74.2%;
            }
        }
    }

    //
    //  My account
    //  ---------------------------------------------

    .account.page-layout-2columns-left {
        .sidebar-main,
        .sidebar-additional {
            width: 22.3%;
        }

        .column.main {
            width: 77.7%;
        }
    }

    .account {
        .column.main {
            .block:not(.widget) {
                .block-content {
                    @extend .abs-add-clearfix-desktop;

                    .box {
                        @extend .abs-blocks-2columns;
                    }
                }
            }
        }

        .toolbar {
            @extend .abs-pager-toolbar;
        }
    }

    .block-addresses-list {
        .items.addresses {
            @extend .abs-add-clearfix-desktop;
            font-size: 0;

            > .item {
                display: inline-block;
                font-size: $font-size__base;
                margin-bottom: $indent__base;
                vertical-align: top;
                width: 48.8%;

                &:nth-last-child(1),
                &:nth-last-child(2) {
                    margin-bottom: 0;
                }

                &:nth-child(even) {
                    margin-left: 2.4%;
                }
            }
        }
    }

    //
    //  Welcome block
    //  ---------------------------------------------

    .dashboard-welcome-toggler {
        @extend .abs-visually-hidden-desktop;
    }

    .control.captcha-image {
        .captcha-img {
            margin: 0 $indent__s $indent__s 0;
        }
    }
    .title-my-account {
        display: inline-block;
        font-size: 2.4rem;
        //font-family: $font-family__Raleway-semibold;
        font-weight: $font-weight__semibold;
        //font-weight: bold;
        margin-top: 0;
    }
}
