.cms-marcas {
    .page-main .columns {
        display: block;
    }
    .slider-block {
        border-bottom: 1px solid $color-kim-gris2;
        border-top: 1px solid $color-kim-gris2;
        padding: $indent__xxxxl  0 $indent__xxxxxl  0;
        h2 {
            //font-family: $font-family__Raleway-regular;
            font-weight: $font-weight__regular;
            @include lib-text(extra);
            text-align: center;
            margin-bottom: $indent__m;
        }
    }
}

.brand-slider-block {
    border-bottom: 1px solid $color-kim-gris2;
    border-top: 1px solid $color-kim-gris2;
    padding: $indent__xxxxl  0 $indent__xxxxxl  0;
    h2 {
        //font-family: $font-family__Raleway-regular;
        font-weight: $font-weight__regular;
        @include lib-text(extra);
        text-align: center;
        margin-bottom: $indent__m;
    }
}

.brands-count {
    //font-family: $font-family__Raleway-medium-italic;
    //font-style: italic;
    font-weight: $font-weight__heavier;
    font-style: $font-style__emphasis;
    @include lib-text(normal);
}
#brand-slider {
    margin: 0 auto;
    width: 90%;
}

.brands-filter {
    padding: $indent__xxl 0;
    .abc-mobile-list {
        display: none;
        .select-input-container {
            width: 100%;
            .selected-input-option {
                @include lib-text(medio);
                background-color: #fff;
                background-position-x: 97%;
                border: 1px solid $color-kim-gris2;
                padding: $indent__xs;
                margin-bottom: 0;
            }
            .select-input-options
            {
                margin-top: $indent__xxxs;
                @include lib-text(medio);
                li:first-child {
                    display: none;
                }
                li:nth-child(2) {
                    margin-top: $indent__xxs;
                }
                li {
                    padding: $indent__xs;
                    margin-bottom: 0;
                }
            }
        }

    }
}

.all-brands-block{
    .brand-details {
        div.title {
            background-color: $color-kim-gris3;
            @include lib-text(extra);
            margin-bottom: 14px;
            padding: .4rem .8rem;
        }
        div.content ul {
            align-items: center;
            display: flex;
            flex-wrap: wrap;
            list-style: none;
            padding: 0;
            margin: 0;
            li {
                text-align: center;
                width: 16.5%;
                margin-bottom: $indent__s;
                padding: 10px 25px;
                box-sizing: border-box;
            }
        }
        img{
            transition: all .3s ease-in-out;
            &:hover{
                transform: scale(.9);
                transition: all .3s ease-in-out;
            }
        }
    }
}

//
//  Desktop large
//  _____________________________________________

@include max-screen($screen__xl) {
    .all-brands-block {
        .brand-details {
            div.content ul li{
                width: 20%;
                padding: 10px 15px;
            }
        }
    }
}

//
//  Desktop
//  _____________________________________________


@include max-screen($screen__l) {
    .all-brands-block {
        .brand-details {
            div.content ul li{
                width: 25%;
            }
        }
    }
    .brand-slider-block {
        padding: $indent__xxl  0 $indent__xxl  0;
    }
}

//
//  Tablet
//  _____________________________________________
@include max-screen($screen__m) {
    .cms-marcas {
        .slider-block {
            display: none;
        }
    }

    .brand-slider-block {
        display: none;
        padding: $indent__s  0 $indent__s  0;
    }

    .brands-filter {
        border-top: 1px solid $color-kim-gris2;
        padding: $indent__s 0;
    }

    .all-brands-block {
        .brand-details {
            div.content ul li{
                width: 33%;
            }
        }
    }
}

//
//  mobile
//  _____________________________________________

@include max-screen($screen__xs) {
    .brands-filter {
        padding: $indent__base 0 $indent__xl 0;
        .abc-mobile-list {
            display: block;
        }
        #abc-list {
            display: none;
        }
    }
    .all-brands-block {
        .brand-details {
            div.content ul li{
                width: 50%;
            }
        }
    }

}

@include max-screen($screen__xxs) {
    .all-brands-block {
        .brand-details {
            div.content ul li{
                width: 100%;
                padding: 0 10px;
            }
        }
    }
}