.custom-widget {
    .block-title {
        text-align: center;
    }

    .block-title .title {
        @include lib-text(extra);
        font-weight: $font-weight__regular;
        margin-bottom: $indent__m;
        text-transform: uppercase;
    }

    .banner-item {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        justify-content: center;
        text-align: center;
        margin: 0 auto;
        position: relative;
        padding: $indent__base;
        max-width: 100%;
        width: 100%;
    }

    button.slick-next,
    button.slick-next:hover
    {
        right: -5px;
    }
    button.slick-prev,
    button.slick-prev:hover {
        left: -5px;
    }
}
