.rte{
    overflow: hidden;
    h1{
        margin-top: 0;
    }
    h2{
        margin-top: $indent__m;
        margin-bottom: $indent__xs;
    }
    h3, h4{
        margin-top: $indent__s;
        margin-bottom: $indent__xs;
        font-weight: 600;
    }
    p, li {
        font-size: 1.4rem;
        line-height: 1.9rem;
        text-align: justify;
    }
    ul, ol {
        margin: $indent__s 0;
    }
    li{
        margin-bottom: 1.6rem;
    }
    .table-container{
        overflow-x: auto;
        td{
            word-break: break-word;
        }
    }
}

.clausula-link{
    &:hover{
        text-decoration: underline;
    }
}