.fotorama-stretch {
    bottom: 0;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
}

.fotorama-grab-cursor {
    cursor: move;
    cursor: grab;
}

.fotorama-grabbing-cursor {
    cursor: move;
    cursor: grabbing;
}

.fotorama-gpu {
    transform: translateZ(0);
}

.fotorama-focus {
    outline: 0;
}

.fotorama-focus-overlay {
    &:after {
        @extend .fotorama-stretch;
        background-color: $color-blue2;
        border-radius: inherit;
        content: '';
    }
}

.fotorama-transform-disabled {
    transform: none !important;
}

.fotorama-transition-for-slide {
    transition-duration: unset;
    transition-property: transform, width;
    transition-timing-function: cubic-bezier(0.1, 0, 0.25, 1);
}

.fotorama-no-select {
    user-select: none;
}

.fotorama-select {
    user-select: text;
}

.fotorama-empty-bg {
    background: url(data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=);
}

.fotorama-auto-margin {
    margin: auto;
    padding: 0;
}

.fotorama-inline-block {
    @include fotorama-inline-block();
}

.fotorama-hidden {
    position: absolute;
    top: -99999px;
    left: -99999px;
    z-index: -$z-index-10;
}

.fotorama-visible {
    position: relative;
    left: auto;
    top: auto;
    opacity: 1;
    z-index: auto;
}

.fotorama-no-tap {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0); // sass-lint:disable-line no-vendor-prefixes
}

.transitionDuration {
    transition-duration: 333ms;
}

.transitionDurationZero {
    transition-duration: unset;
}

.fotorama-sprite {
    @extend .fotorama-print-background;
    background-image: url(../images/gallery.png);
    background-repeat: no-repeat;
    /*background: url(../images/icon-fleizda-kimidori.svg) no-repeat center;
    background-size: 24px;
    box-shadow: 2px 2px 7px 0 rgba(0, 0, 0, 0.2);
    border: none;*/
}

.fotorama__arr.fotorama__arr--next {
    .fotorama__arr__arr {
        background: url("../images/icon-fledcha-kimidori.svg") no-repeat center;
        background-size: 24px;
        box-shadow: 2px 2px 7px 0 rgba(0, 0, 0, 0.2);
        border: none;
    }
}
.fotorama__arr.fotorama__arr--prev {
    .fotorama__arr__arr {
        background: url("../images/icon-fleizda-kimidori.svg") no-repeat center;
        background-size: 24px;
        box-shadow: 2px 2px 7px 0 rgba(0, 0, 0, 0.2);
        border: none;
    }
}

.fotorama-print-background {
    @media print {
        background: none !important;
    }
}

.gallery-placeholder {
    .loading-mask {
        display: none;
    }

    &._block-content-loading .loading-mask {
        display: block;
    }
}
