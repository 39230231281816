//
//  Shopping cart
//  ---------------------------------------------

//  Summary block
.cart-summary {
    @extend .abs-add-box-sizing;
    @extend .abs-adjustment-incl-excl-tax;
    @include lib-css(background, $checkout-summary__background-color);
    margin-bottom: $indent__m;
    padding: $checkout-summary__padding;

    @include max-screen($screen__xxs) {
        @include lib-css(padding, $checkout-summary__padding $indent__xs);
    }

    > .title {
        display: block;
        @include lib-heading(h1);
        text-transform: uppercase;
    }

    .block {
        @extend .abs-discount-block;
        margin-bottom: 0;

        .item-options {
            margin-left: 0;
        }

        .fieldset {

            .field {
                margin: 0 0 $indent__base;

                &.note {
                    font-size: $font-size__s;
                }
                &:not(.choice) > .control{
                    width: 100%;
                }
            }

            .methods {
                .field {
                    > .label {
                        display: inline;
                    }
                }
            }
        }

        .fieldset.estimate {
            > .legend,
            > .legend + br {
                @extend .abs-no-display;
            }
        }
        &.discount{
            .title{
                padding: $indent__base 0;
                text-transform: uppercase;
                font-weight: $font-weight__regular;
                font-size: 1.5rem;
                &:after{
                    content: '';
                    background: url('../images/icon-flearriba-kimidori.svg') no-repeat center right;
                    width: 24px;
                    height: 24px;
                    top: 11px;
                    right: 0;
                    transform: rotate(180deg);
                }
            }
            &.active{
                .title{
                    &:after{
                        transform: rotate(0deg);
                    }
                }
            }
        }

    }
    .actions-toolbar {
        > .primary {
            width: 100%;
            margin-bottom: $indent__base;
            button {
                @include lib-button-kim-secondary-s();
                margin-bottom: $indent__s;
                max-width: 150px;
                width: 100%;
            }
        }
    }
    .block.shipping {
        display: none;
    }
    .faltan-wrapper{
        border-top: 1px solid #d1d1d1;
        text-align: center;
        padding: $indent__xs $indent__s;
    }
    .pedido-minimo{
        border-top: 1px solid $color-kim-gris2;
    }
    #block-discount{
        .label{
            margin-bottom: 5px;
        }
    }
    .multicheckout{
        display: none;
    }
}

.pedido-minimo{
    padding: $indent__xs $indent__s 0;
    color: $color-kim-primario1;
    font-size: $font-size__s;
    line-height: 1.3rem;
    text-align: center;
    font-style: italic;
    span{
        display: block;
        max-width: 200px;
        margin: 0 auto;
    }
}

//  Totals block
.cart-totals {
    border-top: 1px solid $border-color__base;
    padding-top: $indent__xs;

    .table-wrapper {
        margin-bottom: 0;
        overflow: inherit;
        .mark,
        .amount{
            padding-top: 5px;
            padding-bottom: 5px;
            vertical-align: middle;
        }
        .grand.totals{
            .mark,
            .amount {
                padding-top: $indent__base;
                padding-bottom: $indent__base;
            }
            .amount {
                @include lib-heading(h1);
                color: $color-kim-primario5;
            }
        }
        .amount {
            padding-right: 0;
            padding-left: $indent__xxxs;
            text-align: right;
        }
        .mark {
            padding-right: 0;
            padding-left: 0;
            text-align: left;
        }
    }
    .totals.sub{
        th {
            font-weight: $font-weight__regular;
        }
    }
}

.ultimas-unidades{
    display: block;
    @include lib-text(pequeno);
    margin-bottom: 0;
    color: $color-kim-primario1;
}

//  Products table
.cart.table-wrapper {
    .items {
        > .item {
            border-bottom: $border-width__base solid $border-color__base;
            position: relative;
        }
    }

    .col {
        &.qty {
            .label {
                @extend .abs-visually-hidden;
            }
        }
        &.price .price{
            font-weight: $font-weight__regular;
        }
    }
    thead{
        th{
            background-color: $color-kim-gris3;
        }
    }
    tbody{
        > tr{
            >td{
                padding: $indent__s 0px 20px;
                &.price,
                &.subtotal{
                    padding-top: 35px;
                }
                .price-excluding-tax{
                    margin-bottom: $indent__base;
                }

                &:first-child{
                    padding-left: 10px;
                    .product-item-details{
                        padding-top: 10px;
                    }
                }
                &:last-child{
                    padding-top: $indent__m;
                    padding-right: 10px;
                    text-align: right;
                    width: 25px;
                }
            }
        }
    }

    .item {
        &-actions{
            &.borrar-anadir{
                td{
                    position: relative;
                    padding: 0;
                    .actions-toolbar{
                        top: -30px;
                        right: 10px;
                        position: absolute;
                        font-weight: $font-weight__regular;
                        line-height: 1.18;
                        font-size: $font-size__s;
                        cursor: pointer;
                    }
                }
            }
            td {
                padding-bottom: $indent__s;
                text-align: center;
                white-space: normal;
            }
        }
        &-info{
            .col{
                min-height: 105px;
            }
        }
        .col {
            &.item {
                display: block;
                position: relative;
            }
        }
    }

    .actions-toolbar {
        @extend .abs-add-clearfix;

        > .action {
            &:last-child {
                margin-right: 0;
            }
            &.action-edit{
                display: none;
            }
            &.action-delete{
                background: url("../images/icon-papelera-kimidori.svg") no-repeat center center;
                width: 24px;
                height: 24px;
                display: inline-block;
                text-indent: 100%;
                white-space: nowrap;
                overflow: hidden;
                &:hover{
                    background-image: url("../images/icon-papelera-azul-kimidori.svg");
                }
            }
        }
    }
    .borrar-anadir{
        .actions-toolbar {
            > .action {
                &.action-delete {
                    display: none;
                }
            }
        }
    }

    .action {
        &.help.map {
            @extend .abs-action-button-as-link;
            font-weight: $font-weight__regular;
        }
    }

    .product {
        &-item-photo {
            display: block;
            left: 0;
            max-width: 60px;
            padding: 0;
            position: absolute;
            top: $indent__base;
            width: 100%;
        }

        &-item-details {
            white-space: normal;
        }

        &-item-name {
            @include lib-text(medio);
            display: inline-block;
            font-weight: $font-weight__semibold;
            margin: 0 0 5px;
        }
    }

    .gift-registry-name-label {
        &:after {
            content: ':';
        }
    }

    //  Product options
    .item-options {
        @extend .abs-product-options-list;
        @extend .abs-add-clearfix;
        font-size: $font-size__s;
        margin-bottom: $indent__s;
    }

    .product-item-name + .item-options {
        margin-top: $indent__s;
    }

    .product-image-wrapper {
        @extend .abs-reset-image-wrapper;
    }

    .action.configure {
        display: inline-block;
        margin: 0 0 $indent__base;
    }
    .qty-control-input{
        .item-qty {
            width: 40px;
            height: 40px;
            max-width: 130px;
            font-size: 1.8rem;
        }
        .input-qty{
            height: 40px;
            width: 40px;
        }
    }
    .delete-only{
        .action-towishlist{
            display: none;
        }
    }
}
.mpfreegifts-btn.action.primary {
    @include lib-button-kim-primary-base();
}

.cart-container {
    .form-cart {
        @extend .abs-shopping-cart-items;
        .actions{
            padding: 0 0 $indent__s;
        }
    }

    .checkout-methods-items {
        @extend .abs-reset-list;
        margin-top: 10px;
        text-align: center;

        .action.primary {
            width: 100%;
        }

        .item {
            & + .item {
                margin-top: $indent__base;
            }
        }
    }
}

//  Products pager
.cart-products-toolbar {
    .toolbar-amount {
        margin: $indent__m 0 $indent__base;
        padding: 0;
        text-align: center;
    }

    .pages {
        margin: 0 0 $indent__m;
        text-align: center;

        .items {
            > .item {
                border-bottom: 0;
            }
        }
    }
}

.cart-products-toolbar-top {
    border-bottom: $border-width__base solid $border-color__base;
}

//
//  Cross sell
//  ---------------------------------------------

.block.crosssell {
    margin-top: 70px;
}

.cart-tax-info,
.cart .cart-tax-info {
    + .cart-tax-total {
        display: block;
    }
}

.cart.table-wrapper,
.order-items.table-wrapper {
    .col.price,
    .col.qty,
    .col.subtotal,
    .col.msrp {
        text-align: center;
    }
}

.titulo-tabla-oculto{
    display: none;
    padding: $indent__xxs $indent__base;
    background-color: $color-kim-gris3;
    font-weight: $font-weight__semibold;
}

.minicart-items-wrapper{
    padding-right: 9px;
    padding-bottom: 9px;
    max-height: 331px;
    overflow: auto;
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__l) {

    .cart.table-wrapper{
        .qty-control-input{
            .item-qty {
                width: 26px;
                height: 24px;
                max-width: 80px;
                font-size: $font-size__base;
            }
            .input-qty{
                height: 24px;
                width: 24px;
            }
        }
        .item {
            &-info {
                .col{
                    min-height: 60px;
                }
            }
        }
        tbody{
            > tr{
                >td{
                    padding: $indent__s 0px;
                    &.price,
                    &.subtotal{
                        padding-top: 27px;
                    }
                    &:first-child{
                        .product-item-details{
                            padding-top: 2px;
                        }
                    }
                    &:last-child{
                        padding-top: $indent__s;
                    }
                }
            }
        }
        .product-item-details{
            padding-left: 60px;
        }
        .item {
            &-actions {
                &.borrar-anadir {
                    td {
                        .actions-toolbar {
                            top: -20px;
                            right: 10px;
                        }
                    }
                }
            }
        }
    }
}

@include max-screen($screen__m) {
    .cart-container {
        .form-cart {
            @extend .abs-shopping-cart-items-mobile;
        }
    }
}

@include max-screen($screen__s) {
    .cart-container{
        .form-cart{
            .action{
                &.clear,
                &.update,
                &.continue{
                    display: block;
                    width: 100%;
                    margin: 5px 0;
                }
            }
        }
    }
    .cart.table-wrapper{
        .item {
            &-info {
                .col{
                    min-height: inherit;
                }
            }
        }
    }
}

@include max-screen($screen__xs) {
    .cart {
        &.table-wrapper {

            thead {
                display: none;
            }
            tbody{
                > tr{
                    > td{
                        padding: 12px 0px;
                        &:last-child {
                            padding-top: 14px;
                        }
                    }
                }
            }
            .product-item-details{
                padding-left: 110px;
            }
            .item-actions{
                &.borrar-anadir{
                    td{
                        .actions-toolbar{
                            top: inherit;
                            right: inherit;
                            position: relative;
                            span{
                                text-align: left;
                                display: block;
                                padding-left: 120px;
                                margin-bottom: $indent__base;
                            }
                        }
                    }
                }
            }
            .product-item-name {
                font-size: 1.6rem;
            }
            .product-item-photo{
                max-width: 100px;
            }
            .col {
                &.qty,
                &.price,
                &.subtotal,
                &.msrp {
                    box-sizing: border-box;
                    float: left;
                    text-align: left;
                    white-space: nowrap;
                    display: inline-block;
                    width: 100%;
                    padding: $indent__xxs 0 $indent__xxs 120px;

                    &:before {
                        content: attr(data-th) ':';
                        display: inline-block;
                        font-weight: $font-weight__semibold;
                        margin-right: 5px;
                        vertical-align: initial;
                    }
                    .price-including-tax,
                    .price-excluding-tax,
                    .field.qty{
                        display: inline-block;
                        margin-bottom: 0px;
                    }
                }
                &.price {
                    margin-top: 10px;
                }
                &.subtotal {
                    margin-bottom: 10px;
                }
                &.msrp {
                    white-space: normal;
                }
            }

            .item {
                .col.item {
                    padding-bottom: 0;
                }
            }
        }
    }
    .titulo-tabla-oculto{
        display: block;
    }
}

@include max-screen($screen__xxs-2) {
    .cart.table-wrapper{
        .product-item-photo {
            max-width: 75px;
        }
        .product-item-details {
            padding-left: 80px;
        }
        .product-item-name{
            font-size: 1.4rem;
        }
        .col {
            &.qty,
            &.price,
            &.subtotal,
            &.msrp {
                padding: $indent__xxs 0 $indent__xxs 90px;
            }
        }
        .item-actions{
            &.borrar-anadir{
                td{
                    .actions-toolbar{
                        span{
                            padding-left: 90px;
                        }
                    }
                }
            }
        }

    }
}


//
//  Desktop
//  _____________________________________________

@include min-screen($screen__l) {
    .cart {
        &.table-wrapper {
            .items { // Google Chrome version 44.0.2403.107 m fix
                min-width: 100%;
                width: auto;
            }

            .item {
                &-actions {
                    td {
                        text-align: right;
                    }
                }
                &-info{
                    .col.qty{
                        min-width: 125px;
                    }
                }
            }

            .product {
                &-item-photo {
                    display: table-cell;
                    max-width: 100%;
                    padding-right: $indent__base;
                    position: static;
                    vertical-align: top;
                    width: 1%;
                }

                &-item-details {
                    display: table-cell;
                    vertical-align: top;
                    white-space: normal;
                    width: 99%;
                }
            }

            .item-actions {
                .actions-toolbar {
                    text-align: left;
                }
            }
        }

        .action {
            margin-bottom: $indent__s;
        }
    }
    .cart-container {
        .form-cart {
            float: left;
            position: relative;
            width: 73%;
        }
        .widget {
            float: left;
        }
    }

    .cart-summary {
        float: right;
        position: relative;
        width: 25%;

        .actions-toolbar {
            .column.main & {
                > .secondary {
                    float: none;
                }
            }
        }
    }

    //  Products pager
    .cart-products-toolbar {
        .toolbar-amount {
            line-height: 30px;
            margin: 0;
        }

        .pages {
            float: right;
            margin: 0 0 1px;

            .item {
                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }

    .cart.table-wrapper {
        .cart-products-toolbar {
            + .cart {
                thead {
                    tr {
                        th.col {
                            padding-bottom: 7px;
                            padding-top: 8px;
                        }
                    }
                }
            }
        }

        .cart {
            + .cart-products-toolbar {
                margin-top: $indent__m;
            }
        }
    }
}
@include min-screen($screen__m) {

}

@include min-screen($screen__s) {
    .cart-container {
        .form-cart {
            .actions {
                text-align: right;
            }

            .action {
                &.clear,
                &.update {
                    margin-left: $indent__s;
                }

                &.continue {
                    float: left;
                }
            }

        }
    }
}
