.blog-page {
    .post-info {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-bottom: $indent__xxs;

        @include min-screen($screen__xs) {
            margin-right: auto;
        }

        @include max-screen($screen__xs) {
            flex-direction: column;
        }

        &__date,
        &__categories,
        &__comments,
        &__author,
        &__views {
            font-style: $font-style__emphasis;
            .value {
                padding: $indent__xxxs $indent__xxxs/2;
            }
            .action {
                @include lib-link-quaternary();
            }
        }

        .item {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            position: relative;
            margin-bottom: 0;

            & + .item {
                padding-left: $indent__xxs;
                margin-left: $indent__xxs;
            }
            & + .item:before {
                content: "";
                background-color: $color-kim-gris2;
                position: absolute;
                left: 0;
                width: 1px;
                height: 100%;
                max-height: 16px;
            }

            @include max-screen($screen__xs) {
                & + .item{
                    padding-top: $indent__xxxs;
                    margin-top: $indent__xxxs;
                    padding-left: 0;
                    margin-left: 0;
                }

                & + .item:before {
                    top: 0;
                    left: 50%;
                    transform: translateX(-50%);
                    width: 16px;
                    max-height: 1px;
                }
            }
        }
    }
}
