/* Slider */
.slick-slider
{
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;

    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
        touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list
{
    position: relative;

    display: block;
    overflow: hidden;

    margin: 0;
    padding: 0;
}
.slick-list:focus
{
    outline: none;
}
.slick-list.dragging
{
    cursor: pointer;
    cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list
{
    -webkit-transform: translate3d(0, 0, 0);
       -moz-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
         -o-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
}

.slick-track
{
    position: relative;
    top: 0;
    left: 0;

    display: block;
    margin-left: auto;
    margin-right: auto;
}
.slick-track:before,
.slick-track:after
{
    display: table;

    content: '';
}
.slick-track:after
{
    clear: both;
}
.slick-loading .slick-track
{
    visibility: hidden;
}

/* Dots */

.slick-dotted.slick-slider {
    margin-bottom: 30px;
}

.slick-dots {
    display: block;
    text-align: center;
    padding: 0;
    margin: 8px 0 0;
    width: 100%;
}
.slick-dots li {
    position: relative;
    display: inline-block;
    margin: 0 8px;
    padding: 0;
}
.slick-dots li button {
    font-size: 0;
    border-radius: 50%;
    border: 0;
    appearance: none;
    background: #dcdcdc;
    width: 5px;
    height: 5px;
    padding: 5px;
}
.slick-dots li.slick-active button {
    background: #505050;
}
    /*button {
        @include lib-button(
        $_button-height:             $indent__xxs,
        $_button-padding:            $indent__xxxs,
        $_button-min-width:          $indent__xxs,
        $_button-background:         $color-gopt-grey2,
        $_button-border:             unset,
        $_button-background-hover:   $color-gopt-secondary1,
        $_button-border-hover:       unset,
        $_button-background-active:  $color-gopt-grey2,
        $_button-border-active:      unset
        );
            font-size: 0;
        }
        &.slick-active button {
             @include lib-button(
             $_button-height:             $indent__xxs,
             $_button-padding:            $indent__xxxs,
             $_button-min-width:          $indent__xxs,
             $_button-background:         $color-gopt-secondary1,
             $_button-border:             unset,
             $_button-background-hover:   $color-gopt-secondary1,
             $_button-border-hover:       unset,
             $_button-background-active:  $color-gopt-secondary1,
             $_button-border-active:      unset
             );
             font-size: 0;
         }
    }
}*/

.slick-slide
{
    display: none;
    /*float: left;*/
    height: 100%;
    min-height: 1px;
    vertical-align: middle;
    margin: 7px;
}
[dir='rtl'] .slick-slide
{
    float: right;
}
.slick-slide img
{
    display: block;
}
.slick-slide.slick-loading img
{
    display: none;
}
.slick-slide.dragging img
{
    pointer-events: none;
}
.slick-initialized .slick-slide
{
    display: inline-block;
}
.slick-loading .slick-slide
{
    visibility: hidden;
}
.slick-vertical .slick-slide
{
    display: block;

    height: auto;

    border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
    display: none;
}

button.slick-next, button.slick-next:hover
{
    position: absolute;
    top: 50%;
    transform: translate(0%, -50%);
    right: -50px;
    width: 40px;
    height: 40px;
    background: url("../images/icon-fledcha-kimidori.svg") no-repeat center;
    background-color: #fff;
    background-size: 24px;
    box-shadow: 2px 2px 7px 0 rgba(0, 0, 0, 0.2);
    border: none;

}
button.slick-prev, button.slick-prev:hover {
    position: absolute;
    top: 50%;
    transform: translate(0%, -50%);
    left: -50px;
    z-index: 1;
    width: 40px;
    height: 40px;
    background: url("../images/icon-fleizda-kimidori.svg") no-repeat center;
    background-color: #fff;
    background-size: 24px;
    box-shadow: 2px 2px 7px 0 rgba(0, 0, 0, 0.2);
    border: none;
}
button.slick-prev:before {
    content: '';
}
.slick-slider img {
    margin: 0 auto;
}
#brand-slider.loading,
#post-related-products.loading,
#relatedposts.loading,
#relatedauthorposts.loading,
.product-items.widget-product-grid.loading {
    display: none;
}

@media only screen and (max-width: 780px){
    button.slick-next, button.slick-next:hover {
        right: -40px;
    }
    button.slick-prev, button.slick-prev:hover {
        left: -40px;
    }
}

@media only screen and (max-width: 640px){
    button.slick-next, button.slick-next:hover {
        right: -30px;
    }
    button.slick-prev, button.slick-prev:hover {
        left: -30px;
    }
}
@media only screen and (max-width: 530px){
    button.slick-next, button.slick-next:hover {
        right: -20px;
    }
    button.slick-prev, button.slick-prev:hover {
        left: -20px;
    }
    .bullet-list #bullet-list button.slick-next, button.slick-next:hover {
        right: 0;
    }
    .bullet-list #bullet-list button.slick-prev, button.slick-prev:hover {
        left: 0;
    }
}



