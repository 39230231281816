.h3_cms_programa_de_puntos{
    margin-top: $indent__m;
    margin-bottom: $indent__xs;
}

@include min-screen($screen__l) {
    .img_cms_programa_de_puntos {
        margin: auto;
        display: block;
        margin-bottom: $indent__xxl;
        margin-top: $indent__s;
    }
}

@include max-screen($screen__l) {
    .img_cms_programa_de_puntos{
        margin: auto;
        display: block;
        margin-bottom: $indent__s;
        margin-top: $indent__xxs;
    }
}
