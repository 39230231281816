.fotorama__arr__arr {
    .fotorama__arr & {
        @extend .fotorama-sprite;
        width: 40px;
        height: 40px;
        @include fotorama-abs-center();

        .ie9 & {
            margin: (-$size-fotorama-block / 2) 0 0 (-$size-fotorama-block / 2);
        }
    }
}
