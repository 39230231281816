.cms_page_not_found {
  .cms_page_not_found_top {
    margin-top: 1.6rem;
  }
  .cms_page_not_found_bottom_div {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 15px;
  }
  .cms_page_not_found_bottom_div_imagen {
    width: 100%;
    max-width: 35rem;
    height: auto;
    path {
        fill: currentColor;
    }
    &:hover {
      color: $color-kim-primario1;
    }
  }
  .cms_page_not_found_button_div_H3 {
    text-align: center;
  }
  .cms_page_not_found_button_div1 {
    margin-top: 3rem;
  }
}
