.blog-page,
.blog-widget-recent-list {
    .post-list {
        @include lib-list-reset-styles();
        border-top: 1px solid $color-kim-gris2;
    }
    .post-item {
        @include lib-css(padding, $indent__base 0);
        @include lib-css(margin-bottom, 0);
        border-bottom: 1px solid $color-kim-gris2;

        .post-title-holder {
            display: flex;
            justify-content: space-between;
            align-content: center;
            margin-bottom: $indent__xxs;
            @include max-screen($screen__m) {
                flex-direction: column;
            }
        }
        &__title {
            margin: 0;
        }
    }
    .post-content {
        &__description {
            display: flex;
            flex-wrap: wrap;
        }

        &__ftimg-hld {
            flex-basis: 35%;
            text-align: center;
            max-width: 300px;
        }

        &__ftimg-hld + .post-content__text-hld {
            flex-basis: 65%;
            flex-grow: 1;
            box-sizing: border-box;
            padding-left: $indent__base;
        }

        &__text {
            @include lib-font-size($blog-content__font-size);
            line-height: $blog-content__line-height;
        }

        &__read-more {
            @include lib-link-quaternary();
            display: inline-block;
            padding: $indent__xxs $indent__xxxs;
        }

        @include max-screen($screen__xs) {
            &__description {
                justify-content: center;
            }
            &__ftimg-hld,
            &__text-hld {
                flex-basis: 100%;
            }
            &__ftimg-hld + .post-content__text-hld,
            &__text-hld {
                padding-top: $indent__base;
                padding-left: 0;
            }
        }
    }
    .toolbar {
        text-align: center;
    }
}
