body.search-result-opened {
    overflow: hidden;
    width: 100%;
    height: 100%;
    position: relative;
}
.block.block-content {

    #search_result {
        max-width: $layout__max-width;
        background-color: $color-white;
        left: 0;
        right: 0;
        box-sizing: border-box;

        .result {
            padding: 15px 0 $indent__base;

            .text,
            .num_results {
                @include lib-font-size($font-size__xl);
                @include lib-css(font-weight, $font-weight__semibold);
                display: none;
            }

            .close-popup {
                cursor: pointer;
                background-color: $color-kim-gris3;
                display: flex;
                align-items: center;
                position: absolute;
                top: 15px;
                right: $indent__base;
                svg {
                    width: 2.4rem;
                    height: 2.4rem;
                    padding: $indent__xxxs;
                    box-sizing: border-box;
                }
                .label-text {
                    padding: $indent__xxxs $indent__xxs;
                    display: inline-block;
                }
            }

            .view-all {
                margin-left: $indent__xs;
                display: inline-block;
                cursor: pointer;
                font-weight: $font-weight__heavier;
                font-style: $font-style__emphasis;
            }
        }

        .filters {
            padding-top: $indent__xs;

            .filter {
                border-bottom: 1px solid $color-kim-gris2;
                border-top: 1px solid $color-kim-gris2;

                & + .filter {
                    border-top: none;
                }
            }

            .filter-title {
                padding: $indent__xs $indent__s $indent__xs $indent__xxs;
                text-transform: uppercase;

                .filter-name {
                    display: inline-block;
                }

                .arrow {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    right: 0;
                    color: $color-kim-gris2;
                }

                .arrow-up {
                    display: none;
                }

                .arrow-down {
                    display: inline-block;
                }

                &.active {
                    .arrow-up {
                        display: inline-block;
                    }
                    .arrow-down {
                        display: none;
                    }
                }
            }

            .options {
                padding: $indent__xxs;
                margin-bottom: $indent__xxs;

                .value::before {
                    content: '';
                    width: 16px;
                    min-width: 16px;
                    height: 16px;
                    margin-right: $indent__xxs;
                    display: inline-block;
                    background-image: url(../images/checkbox-off-kimidori.svg);
                    background-repeat: no-repeat;
                    background-position: center;
                    vertical-align: top;
                }
                div.content {
                    cursor:pointer;
                    margin-top: 0;

                    &.selected {
                        .value::before {
                            background-image: url(../images/checkbox-on-kimidori.svg);
                        }
                    }

                    &:hover {
                        .value {
                            color: $color-kim-primario1;
                        }
                    }

                    .value {
                        padding: $indent__xxxs 0;
                    }

                    & + div.content {
                        margin-top: $indent__xxxs;
                    }
                }
            }
        }
        .selected-filters {
            text-align: left;

            .selected-item {
                margin-bottom: $indent__xs;
                margin-right: $indent__xxs;
                padding: $indent__xxxs;
                border: 1px solid $color-kim-gris3;
                transition: all 1s;

                @include lib-font-size($font-size__s);

                &:hover {
                    border-color: $color-kim-primario1;
                    color: $color-kim-primario1;

                }

                svg {
                    margin-left: $indent__s;
                    width: 1.2rem;
                    height: 1.2rem;
                    box-sizing: border-box;
                }
            }
        }
        .products {

            .product-item-info {

                .price-box {
                    .old-price {
                        margin-left: $indent__xxs;
                        font-weight: $font-weight__regular;
                        font-size: $font-size__base;
                    }
                }
            }
        }
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {

    .block-search {
        .label {
            display: inline-block;
            cursor: pointer;

            span {
                font-size: 0;
                width: 0;
                height: 0;
            }
        }
    }


    .block.block-content {
        #search_result > div {
            padding-left: $layout__width-xs-indent;
            padding-right: $layout__width-xs-indent;

            .result {
                .filter-options-text {
                    padding: $indent__base;
                    display: block;
                    text-align: center;
                    cursor: pointer;
                    border: 1px solid $color-kim-gris2;
                    text-transform: uppercase;
                }
                .text {
                    display: none;
                    margin-bottom: $indent__xxs;
                }
                .text,
                .num_results {
                    @include lib-font-size($font-size__l);
                }
            }
            .filters {
                .options div.content .value {
                    padding: $indent__xxs 0;
                }
            }
        }
    }
}

@include max-screen($screen__xs) {
    .block-search {
        display: block;
        position: initial;
        .label {
            display: inline-block;
            cursor: pointer;

            span {
                @include lib-visually-hidden();
            }
        }
        .control {
            position: absolute;

        }
    }

    .block.block-content {
        #search_result > div .result {

            .filter-icon {
                font-size: 0;

                &::after {
                    @include lib-css(font-size, 14px);
                    @include lib-css(font-weight, normal);
                    margin-left: 0;
                }
            }

            .view-all {
                margin-left: 3px;
                display: block;
                max-width: 50%;
                margin-bottom: $indent__xxs;
            }
            .close-popup {
                max-width: 100px;
            }

        }
    }
}


//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .block.block-content {
        #search_result {
            border: 1px solid $color-kim-gris1;
            & > div {
                padding-left: $layout-indent__width;
                padding-right: $layout-indent__width;

                .result .filter-options-text {
                    display: none;
                }
            }
        }
    }
}
