//
//  Header
//  ---------------------------------------------

.page-header {
    @include lib-css(background-color, $header__background-color);
    margin-bottom: 0;
    z-index: 900;
}

.page-header.fixed {
    .panel.wrapper {
        display: none;
    }
    .header.content {
        position: fixed;
        background-color: $color-white;
        z-index: 2;
        top: 0;
        transition: top 0.5s 0.5s;
        left: 0;
        right: 0;
        margin: 0 auto;
        max-width: $layout__max-width;
        box-shadow: $box-shadow-kim-sombra1;
        border-bottom: none;

        .block-blog-logo {
            display: none;
        }
        .block-account {

            .text{
                display: none;
                vertical-align: middle;
            }

            svg {
                display: inline-block;
                vertical-align: middle;
            }
        }
        .block-custom-minicart {

            .showcart .text {
                display: none;
            }
        }
    }
    .custom-header {

        & > .custom-menu-sec {
            display: block;
            order: 2;
            width: auto;
        }
        & > .custom-menu-prin {
            display: block;
            flex: 0 1 100%;
            order: 6;
            margin-bottom: 0;
        }
    }
}

.header {
    &.content {
        @extend .abs-add-clearfix;
        padding-top: 15px;
        position: static;
        top: -100px;
        transition: top 0.5s;

        .custom-menu-sec,
        .custom-menu-prin {
            width: 100%;
        }
        .custom-header {
            position: relative;

            .block-blog-logo {
                svg, img {
                    max-width: 98px;
                    height: 40px;
                }
            }
        }
    }
}
.page-header.fixed .header.content.without-transition,
.header.content.without-transition {
    transition: unset;
}

.panel.wrapper > div {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
}

.logo {
    position: relative;
    z-index: 5;
    .page-print & {
        float: none;
    }
}


.wrapper-menu {
    margin-top: $indent__xs;
    margin-bottom: $indent__m;
    display: none;

    .authorization-link a {
        padding: $indent__xxs $indent__s $indent__xxs $indent__m;
        display: block;
        //font-family: $font-family__Raleway-medium;
        font-weight: $font-weight__heavier;
    }
    .header-link {
        margin-bottom: $indent__xs;
        a {
            padding: $indent__xxs $indent__s $indent__xxs $indent__m;
            display: block;
            //font-family: $font-family__Raleway-medium;
            font-weight: $font-weight__heavier;
        }
    }
    .blog-logo {
        text-align: center;
        padding-top: $indent__s;

        svg, img {
            max-width: 98px;
        }
    }
    .social-icons {
        margin-top: $indent__s;
        text-align: center;
    }
}

.page-header {
    .switcher {
        margin-right: 10px;

        .options {
            @include lib-dropdown(
                $_dropdown-actions-padding            : 0,
                $_dropdown-list-item-padding          : 0,
                $_dropdown-toggle-icon-content        : $icon-down,
                $_dropdown-toggle-active-icon-content : $icon-up,
                $_icon-font-text-hide                 : true,
                $_icon-font-size                      : 22px,
                $_icon-font-line-height               : 22px,
                $_dropdown-list-min-width             : 160px
            );

            ul.dropdown {
                a {
                    display: block;
                    padding: 8px;

                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }

        li {
            font-size: $font-size__s;
            margin: 0;
        }

        .label {
            @extend .abs-visually-hidden;
        }

        strong {
            font-weight: $font-weight__regular;
        }
    }
}

//
//  Top header message - Debe ser posible cambiar el color del fondo y de la letra del bloque
//  ---------------------------------------------
:root {
    --header-top-message__color                          : #{$color-white};
    --header-top-message__background-color               : #{$color-kim-primario1};
}
$header-top-message__animation-duration-big-desktop : 35s !default;
$header-top-message__animation-duration-desktop     : 30s !default;
$header-top-message__animation-duration-tablet      : 25s !default;
$header-top-message__animation-duration-mobile      : 20s !default;

#header-top-msg {
    background-color: var(--header-top-message__background-color);
    z-index: 100;
    overflow: hidden;

    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    transition: transform 0.5s 0.5s;

    .msg-container {
        width: 100%;
        padding: 16px 0 8px 100%;
    }
    .container {
        display: inline-block;
        padding-right: 100%;
        white-space: nowrap;

        animation-iteration-count: infinite;
        animation-timing-function: linear;
        animation-name: slidein;
        animation-duration: $header-top-message__animation-duration-mobile;

        @include min-screen($screen__s) {
            animation-duration: $header-top-message__animation-duration-tablet;
        }
        @include min-screen($screen__l) {
            animation-duration: $header-top-message__animation-duration-desktop;
        }
        @include min-screen($screen__xxl) {
            animation-duration: $header-top-message__animation-duration-big-desktop;
        }
    }

    .text {
        color: var(--header-top-message__color);
        display: inline-block;
        padding: 0;
        margin: 0;
    }

    .close-btn {
        @include lib-button-as-link(
            $_link-color        : var(--header-top-message__color),
            $_link-color-hover  : var(--header-top-message__color)
        );
        position: absolute;
        right: 0;
        top: 0;
        svg {
            width: 1.2rem;
            height: 1.2rem;
            margin: $indent__xxxs $indent__xxs $indent__base;
        }
    }

    &.hide {
        transform: translateY(-100%);
    }
}

@-webkit-keyframes slidein {
    0% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        visibility: visible;
    }

    100% {
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
    }
}

@keyframes slidein {
    0% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        visibility: visible;
    }

    100% {
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__l-3) {
    .page-header.fixed {
        .logged-in{
            .navigation .enlaces-secundarios > li > a{
                padding: 6px 14px 3px;
            }
        }
    }
}

@include max-screen($screen__l-2) {
    .category-top-banner {
        .ctb-text {
            font-size: 1.4rem;
        }
    }
    .page-header.fixed {
        .custom-header{
            &.logged-in{
                > .custom-menu-sec {
                    display: none;
                }
            }
        }
    }
}

@include max-screen($screen__l) {
    .header.content {
        padding-top: 15px;
    }
}


@include max-screen($screen__m) {

    .panel.wrapper {
        margin-top: $indent__xxxs;
        hr {
            margin-top: $indent__xxxs;
        }
    }

    .custom-header {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;

        @supports (display: grid) {
            justify-content: flex-end;
        }
    }

    strong.logo,
    a.logo {
        order: 1;
        flex: 0 1 93px;
        margin-right: auto;
        img {
            max-height: inherit;
            vertical-align: middle;
        }
    }

    .custom-search {
        order: 4;
        margin-left: $indent__s;
    }

    .block-blog-logo {
        order: 2;
        flex: 0 1 98px;
        justify-self: end;
        align-self: center;
    }

    .block-account {
        order: 3;
        align-self: center;
        margin-left: $indent__s;
        justify-self: end;
        white-space: nowrap;

        .my-wishlist,
        .my-account.logged {
            margin-left: $indent__s;
        }
        .my-points span.text,
        .my-wishlist span,
        .my-account span {
            display: none;
        }
    }
    .block-custom-minicart{
        order: 5;
        align-self: center;
        justify-self: end;
        margin-left: $indent__s;
    }

    .custom-menu-sec {
        order: 6;
    }

    .custom-menu-prin {
        order: 7;
    }

    .category-top-banner {
        display: none;
    }
    .subcategories-sidebar{
        border: 1px solid $color-kim-gris2;
        padding: 0 1rem;
        margin-top: 1rem;
        .block-title.filter-title {
            display: block;
        }
        ul{
            display: none;
            background: transparent;
            padding: 15px 0;
            margin-bottom: 0;
        }
        li{
            margin: 0;
            border-left: 3px solid transparent;
            display: block;
            padding: .6rem 1.8rem .6rem 1.5rem;
        }
    }
}

@include max-screen($screen__l) {

    .page-header.fixed {
        .header.content {
            strong.logo,
            a.logo {
                flex: 0 1 93px;
                margin: 0 auto;

                img.logo {
                    display: block;
                }

                .responsive-logo {
                    display: none;
                }
            }
            .custom-menu-sec,
            .custom-menu-prin {
                display: none;
            }
            .nav-toggle {
                display: block;
            }
        }
    }

    strong.logo,
    a.logo {
        margin: 0 auto;
    }

    .block-blog-logo {
        display: none;
    }

    .block-account {
        margin-left: 0;

        .text{
            display: none;
        }

        svg {
            display: inline-block;
        }

        .my-account.logged {
            margin-left: 0;
        }

        .my-wishlist,
        .my-points{
            display: none;
        }
    }

    .panel.wrapper {
        display: none;
    }

    .wrapper-menu {
        display: block;
    }
    .header.content {
        padding-top: 4px;
    }
}

@include max-screen($screen__xs) {

    .page-header.fixed {
        .custom-header > .custom-menu-sec,
        .custom-header > .custom-menu-sec {
            display: none;
        }
    }

}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {

    .navigation ul {
        padding: 0 8px;
    }

    .header {
        &.panel {
            > .header.links {
                float: right;
                font-size: 0;
                @include lib-list-inline();
                margin-left: auto;

                > li {
                    font-size: $font-size__base;
                    margin: 0 0 0 15px;

                    &.welcome,
                    a {
                        line-height: 1.4;
                    }

                    &.welcome {
                        a {
                            @include lib-css(padding-left, $indent__xs);
                        }
                    }
                }
            }
        }

        &.content {
            @extend .abs-add-clearfix-desktop;
        }
    }

    .page-header {
        border: 0;

        .panel.wrapper {
            margin-top: $indent__xxxs;
            hr {
                margin-top: $indent__xxxs;
            }
        }

        .header.panel {
            @extend .abs-add-clearfix-desktop;
            padding-bottom: $indent__s;
            padding-top: $indent__s;
        }

        .switcher {
            display: inline-block;
        }
    }

    .page-header.fixed {
        .header.content {
            .block-blog-logo {
                display: none;
            }

            .custom-search {
                order: 4;
                margin-left: $indent__s;
                width: auto;
            }
            .block-account {
                order: 3;
                align-self: center;
                margin-left: $indent__s;
                white-space: nowrap;
                .my-wishlist,
                .my-account.logged {
                    margin-left: $indent__s;
                }
            }
            .block-custom-minicart{
                order: 5;
                align-self: center;
                margin-left: $indent__s;
            }
        }
        .custom-header {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: center;
            justify-content: flex-start;
        }
    }

    .custom-header {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-start;

        @supports (display: grid) {
            justify-content: flex-end;
        }
    }

    strong.logo,
    a.logo {
        order: 1;
        flex: 0 1 93px;
        margin-right: auto;
        img {
            max-height: inherit;
        }
    }

    .custom-search {
        order: 4;
        margin-left: $indent__s;
    }

    .block-blog-logo {
        order: 2;
        flex: 0 1 98px;
        align-self: center;
        justify-self: end;
    }

    .block-account {
        order: 3;
        align-self: center;
        margin-left: $indent__s;
        justify-self: end;
        white-space: nowrap;

        .my-points span,
        .my-wishlist span,
        .my-account span {
            display: none;
        }
        .my-wishlist,
        .my-account.logged {
            margin-left: $indent__s;
        }
    }
    .block-custom-minicart{
        order: 5;
        align-self: center;
        justify-self: end;
        margin-left: $indent__s;
    }

    .custom-menu-sec {
        order: 6;
    }

    .custom-menu-prin {
        order: 7;
    }
}

@include min-screen($screen__m-2) {

    .page-header.fixed {
        .header.content {
            strong.logo,
            a.logo {
                flex: 0 1 93px;
            }
        }
    }

}

@include max-screen($screen__xxs) {
    .block-custom-minicart{
        margin-right: 5px;
    }
}

@include min-screen($screen__l) {

    .custom-header {
        display: -ms-grid;
        display: grid;
        grid-template-columns: min-content auto auto min-content min-content min-content;
        -ms-grid-columns: min-content auto auto min-content min-content min-content;
        grid-template-rows: repeat(4, auto);
        -ms-grid-rows: auto auto auto auto;
        justify-items: flex-start;
        justify-content: flex-start;
        @supports (display: grid) {
            justify-content: normal;
        }
        align-items: center;
        grid-template-areas:
                'nada nada nada blog login carrito'
                'logo search search search search search'
                'sec sec sec sec sec sec'
                'prin prin prin prin prin prin';
        .block-custom-minicart a:hover,
        .block-account a:hover {
            font-family: inherit;
        }
        a .text {
            //font-family: $font-family__Raleway-regular;
            font-weight: $font-weight__regular;
        }
    }

    strong.logo,
    a.logo {
        width: 196px;
        display: block;
        grid-area: logo;
        -ms-grid-row: 2;
        -ms-grid-column: 1;
        img {
            max-height: inherit;
        }
    }

    .custom-search {
        grid-area: search;
        -ms-grid-row: 2;
        -ms-grid-column: 2;
        -ms-grid-column-span: 6;
        width: 100%;
        max-width: 448px;
    }

    .block-blog-logo {
        grid-area: blog;
        -ms-grid-row: 1;
        -ms-grid-column: 4;
        width: 98px;
    }

    .block-account {
        grid-area: login;
        -ms-grid-row: 1;
        -ms-grid-column: 5;
        margin-left: $indent__l;
        white-space: nowrap;

        .my-wishlist,
        .my-account.logged {
            margin-left: $indent__l;
        }

        .my-account.not-logged {
            svg{
                display: none;
            }
        }

        .my-points,
        .my-wishlist,
        .my-account {
            span {
                display: inline-block;
            }
        }
    }
    .block-custom-minicart{
        grid-area: carrito;
        -ms-grid-row: 1;
        -ms-grid-column: 6;
        margin-left: $indent__l;
    }

    .custom-menu-sec {
        grid-area: sec;
        -ms-grid-row: 3;
        -ms-grid-column: 1;
        -ms-grid-column-span: 6;
    }
    .custom-menu-prin {
        grid-area: prin;
        -ms-grid-row: 4;
        -ms-grid-column: 1;
        -ms-grid-column-span: 6;
    }

}
