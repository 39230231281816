//Page Styles

@import "page/somos-kimidori";

.header-content {
    &__title {
        @include lib-heading(h1);
    }
    &__subtitle {
        @include lib-text(grande);
    }
}

.block-separator {
    margin: $indent__xxxxl 0;
    &.with-border {
        border-bottom: 1px solid $color-kim-gris2;
    }
    &.first-separator{
        margin: $indent__m 0;
    }

    @include max-screen($screen__m) {
        margin: $indent__m 0;
    }
    @include max-screen($screen__xs) {
        margin: $indent__s 0;
    }
}

/* Common styled classes */

.text-align-center {
    text-align: center;
}
.text-align-right {
    text-align: right;
}
