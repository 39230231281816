$product-grid-items-per-row-layout-default          : 2 !default;

$product-grid-items-per-row-layout-1-screen-s       : 3 !default;
$product-grid-items-per-row-layout-1-screen-m       : 4 !default;
$product-grid-items-per-row-layout-1-screen-l       : 5 !default;

$product-grid-items-per-row-layout-2-left-screen-s  : 3 !default;
$product-grid-items-per-row-layout-2-left-screen-m  : 4 !default;
$product-grid-items-per-row-layout-2-left-screen-l  : '' !default;

$product-grid-items-per-row-layout-2-right-screen-s : 3 !default;
$product-grid-items-per-row-layout-2-right-screen-m : 4 !default;
$product-grid-items-per-row-layout-2-right-screen-l : '' !default;

$product-grid-items-per-row-layout-3-screen-s       : 3 !default;
$product-grid-items-per-row-layout-3-screen-m       : '' !default;
$product-grid-items-per-row-layout-3-screen-l       : '' !default;

$product-grid-items-padding                         : 0 $indent__base $indent__base !default;
$product-grid-items-margin                          : 0 0 $indent__s !default;

$product-name-text-decoration                       : none !default;
$product-name-text-decoration-hover                 : $link__hover__text-decoration !default;

$toolbar-mode-icon-font-size                        : 26px !default;
$product-h1-margin-bottom-desktop                   : $indent__base !default;

@import 'module/listings';
@import 'module/toolbar';
@import 'module/gallery';

//
//  Category view
//  ---------------------------------------------

.old-price,
.old.price {
    text-decoration: line-through;
}

.prices-tier {
    .price-container {
        .price-including-tax {
            + .price-excluding-tax {
                &:before {
                    content: '(' attr(data-label) ': ';
                }

                &:last-child:after {
                    content: ')';
                }
            }
        }

        .weee[data-label] {
            display: inline;

            .price {
                @include lib-font-size(11);
            }

            &:before {
                content: ' +' attr(data-label) ': ';
            }
        }
    }
}

.actual-price {
    font-weight: $font-weight__semibold;
}

.product.name a {
    @extend .abs-product-link;
}

.category-image {
    .image {
        display: block;
        height: auto;
        max-width: 100%;
    }
}

.category-image,
.category-description {
    margin-bottom: $indent__base;
}

//
//  Product images general container
//  ---------------------------------------------

.product-image-container {
    display: inline-block;
    max-width: 100%;
    width: 100%;
    position: relative;
}

.product-image-wrapper {
    display: block;
    height: 0;
    overflow: hidden;
    position: relative;
    padding-bottom: 125%;
}

.product-image-photo {
    bottom: 0;
    display: block;
    height: auto;
    left: 0;
    margin: auto;
    max-width: 100%;
    position: absolute;
    right: 0;
    top: 0;
}

.catalog-category-view .product-image-photo {
    max-height: 100%;
    width: auto;
}

//
//  Product view
//  ---------------------------------------------

.product.media {
    position: relative;
    .product.photo .photo.image {
        @extend .abs-adaptive-images-centered;
    }

    .placeholder .photo.container {
        max-width: 100%;
    }

    .notice {
        @include lib-css(color, $text__color__muted);
        @include lib-font-size($font-size__s);
        margin: $indent__s 0;
    }

    .product.thumbs {
        margin: $indent__base 0 $indent__l;
    }

    .items.thumbs {
        @include lib-list-inline();

        .active {
            display: block;
            line-height: 1;
        }
    }
}

.product.info.detailed {
    border-bottom: 1px solid $color-kim-gris2;
    clear: both;
    margin-bottom: $indent__m;
    padding: $indent__l 0 $indent__s 0;
    .data.item.title {
        float: none;
        a#tab-label-description-title {
            border: none;
            text-transform: uppercase;
            @include lib-text(medio);
        }
    }
    .data.item.content#description {
        border: none;
        @include lib-text(normal);
    }
    .product.attribute.description {
        font-size: 1.4rem;

        .value {
            p {
                line-height: 20px;
            }
            .column1,
            .column2 {
                width: 46%;
                h3 {
                    //font-family: $font-family__Raleway-medium;
                    font-weight: $font-weight__heavier;
                    line-height: 20px;
                }
                p {
                    line-height: 20px;
                }
                strong,
                b {
                    //font-family: $font-family__Raleway-semibold;
                    font-weight: $font-weight__semibold;
                }
                .cursiva {
                    //font-family: $font-family__Raleway-medium-italic;
                    font-weight: $font-weight__heavier;
                    font-style: $font-style__emphasis;
                }
                ul {
                    list-style-type: none;
                    padding: 0;
                    li {
                        margin-bottom: 12px;
                        line-height: 20px;
                    }
                    li:before {
                        content: '- ';
                    }
                }
                .product-descri-video {
                    margin-bottom: $indent__m;
                    position: relative;
                    .video {
                        background: url("../images/icon-youtube-kimidori-white.svg") no-repeat center;
                        background-size: 40px;
                        height: 40px;
                        left: 50%;
                        position: absolute;
                        top: 50%;
                        transform: translate(-50%, -50%);
                        width: 40px;
                        z-index: 10;
                        &:hover {
                            background: url("../images/icon-youtube-kimidori.svg") no-repeat center;
                            background-size: 40px;
                        }
                    }
                    .mobile-button {
                        display: none;
                    }
                }
            }
            .column1 {
                float: left;
            }
            .column2 {
                float: right;
            }
        }
    }

    .additional-attributes {
        width: auto;
        @include lib-table-resize(
            $_th-padding-left   : 0,
            $_th-padding-right  : $indent__l,
            $_th-padding-bottom : $indent__s,
            $_td-padding-bottom : $indent__s
        );
    }
}

.product-labels-photo {
    display: block;
    position: relative;
}


.special-product-labels {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;

    &.agotado {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        .special-product-label span {
            background-color: $color-white;
            text-align: center;
        }

        & + .product-item-photo{
            opacity: 0.6;
        }
    }
}

.discount-label {
    //font-family: $font-family__Raleway-semibold;
    font-weight: $font-weight__semibold;
    @include lib-font-size(13);

    .container {
        position: relative;
        display: block;
    }

    .triangle {
        display: block;
        border-style: solid;
        border-width: 24px 48px 24px 0;
        border-color: transparent $color-kim-primario3 transparent $color-kim-primario3; // Default border
        // border-color: transparent $color-black; // BF border
        filter: drop-shadow(1px 1px 3px rgba(0,0,0,.2));
    }

    .percentage {
        position: absolute;
        top: 50%;
        right: 2px;
        transform: translateY(-50%);
        // color: $color-white; // BF Color
    }
}

.special-product-label {
    display: block;
    margin-bottom: 1px;
    position: relative;


    &:not(.agotado):after {
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 18px 9px 0 0;
        position: absolute;
        top: 0;
        filter: drop-shadow(1px 2px 1px rgba(0, 0, 0, 0.2));
        bottom: 0;
    }
    span {
        display: inline-block;
        box-shadow: $box-shadow-kim-sombra1;
        text-transform: uppercase;
        //font-family: $font-family__Raleway-semibold;
        font-weight: $font-weight__semibold;
        color: $color-kim-gris1;
        font-size: 13px;
        letter-spacing: normal;
        padding: 1px 7px;
    }
    &.nuevo {
        &:after {
            border-color: $color-kim-secundario1 transparent;
        }
        span {
            background-color: $color-kim-secundario1;
        }
    }
    &.top-ventas {
        &:after {
            border-color: $color-kim-secundario4 transparent;
        }
        span {
            background-color: $color-kim-secundario4;
        }
    }
    &.proximamente {
        &:after {
            border-color: $color-kim-secundario2 transparent;
        }
        span {
            background-color: $color-kim-secundario2;
        }
    }
}

.product-info-main {

    .page-title-wrapper {
        .page-title {
            line-height: $line-height__base;
            margin-bottom: 8px;
        }
    }

    .product-info-price{
        margin-bottom: $indent__base;
    }

    .stock {
        &.available,
        &.unavailable {
            display: none;
            font-weight: $font-weight__semibold;
            margin-right: $indent__base;
            text-transform: uppercase;
            vertical-align: top;
        }
    }

    .product {
        &.attribute {
            &.sku {
                display: inline-block;
                vertical-align: top;
                @include lib-css(color, $text__color__muted);
                margin-bottom: $indent__xxs;

                > .value {
                    display: inline-block;
                    vertical-align: top;
                    word-break: break-all;
                }

                .type {
                    margin-right: $indent__xxxs;
                }
            }

            &.overview {
                margin: $indent__base 0;
                a {
                    //font-family: $font-family__Raleway-medium-italic;
                    //font-style: italic;
                    font-weight: $font-weight__heavier;
                    font-style: $font-style__emphasis;
                }
            }
        }

        &.alert {
            margin: $indent__s 0;
        }
    }

    .price-box {
        margin-top: $indent__s;
        margin-bottom: 0;
        position: relative;
        display: grid;
        display: -ms-grid;
        grid-template-areas:    "final original discount"
                                "final vat discount"
                                "text text discount";
        grid-template-columns: min-content auto min-content;
        justify-content: flex-start;
        align-items: self-end;

        .price-label {
            display: none;
        }
        .vat {
            grid-area: vat;
            align-self: self-start;
            @include lib-text(pequeno);
        }
        .special-price,
        .price-container {
            grid-area: final;
            margin-right: $indent__xxs;
        }
        .old-price {
            grid-area: original;
        }

        .special-price .price,
        > .price-container .price {
            @include lib-heading(h1);
        }

        .discount-label {
            grid-area: discount;
            align-self: center;
        }
        .text {
            grid-area: text;
            //font-family: $font-family__Raleway-medium-italic;
            //font-style: italic;
            font-weight: $font-weight__heavier;
            font-style: $font-style__emphasis;
            @include lib-text(pequeno);
            color: $color-kim-primario1;
            margin: 0;
        }
    }

    .product-reviews-summary .reviews-actions {
        @include lib-font-size($font-size__base);
    }
}

.last-units{
    .text{
        grid-area: text;
        font-weight: $font-weight__heavier;
        font-style: $font-style__emphasis;
        @include lib-text(pequeno);
        color: $color-kim-primario1;
        margin: 0;
    }
}

.product-options-wrapper {
    .fieldset-product-options-inner {
        .legend {
            @include lib-css(font-weight, $font-weight__semibold);
            @include lib-css(margin, 0 0 $indent__xs);
            @include lib-font-size(14px);
            border: none;
            display: inline-block;
            float: none;
            padding: 0;
        }

        //  Date & Time custom option (Affect Time that goes only after Date)
        input.datetime-picker {
            ~ select.datetime-picker {
                margin-top: $indent__s;
            }
        }

        &.required,
        &._required {
            .legend {
                &:after {
                    content: '*';
                    @include lib-typography(
                        $_font-size   : $form-field-label-asterisk__font-size,
                        $_color       : $form-field-label-asterisk__color,
                        $_font-family : $form-field-label-asterisk__font-family,
                        $_font-weight : $form-field-label-asterisk__font-weight,
                        $_line-height : $form-field-label-asterisk__line-height,
                        $_font-style  : $form-field-label-asterisk__font-style
                    );
                    @include lib-css(margin, $form-field-label-asterisk__margin);
                }
            }
        }
    }

    .field {
        .note {
            display: block;
        }

        .price-notice {
            @extend .abs-adjustment-incl-excl-tax;
        }
    }
}

.product-info-main,
.product-options-bottom {
    .price-box {
        .price-including-tax + .price-excluding-tax,
        .weee + .price-excluding-tax,
        .weee {
            @include lib-font-size(12);
            line-height: 14px;
            margin-bottom: $indent__xs;

            .price {
                @include lib-font-size(12);
                font-weight: $font-weight__semibold;
            }
        }

        .price-wrapper .price {
            @include lib-font-size(18);
            font-weight: $font-weight__semibold;
        }

        .price {
            white-space: nowrap;
        }
        .price-container.price-final_price.tax.weee .price-wrapper .price {
            @include lib-text(giant);
        }
        .special-price {
            .price-container.price-final_price.tax.weee .price-wrapper .price {
                @include lib-text(giant);
            }
        }
        .old-price {
            .price-container.price-final_price.tax.weee .price-wrapper .price {
                //font-family: $font-family__Raleway-regular;
                font-weight: $font-weight__regular;
                font-size: 13px!important;
                @include lib-text(normal);
            }
        }
    }

    .special-price {
        display: block;
        margin: 0 $indent__xxs 0 0;

        .price-label + .price-wrapper {
            display: inline-block;
        }
    }

    .old-price,
    .special-price {
        .price-label {
            &:after {
                content: ': ';
            }
        }
    }

    .box-tocart {
        margin: $indent__base 0;

        .field.qty {
            padding-right: 0.75 * $indent__base;
            .input-qty.minus,
            .input-qty.plus {
                background-color: $color-kim-gris3;
                border: 1px solid $color-kim-gris2;
                padding: $indent__xxs;
                position: relative;
                width: $tocart-width;
                height: $tocart-height;
                cursor: pointer;
                vertical-align: middle;
                -webkit-transition: background 1s, border 1s;
                -moz-transition: background 1s, border 1s;
                -ms-transition: background 1s, border 1s;
                -o-transition: background 1s, border 1s;
                display: inline-block;
                box-sizing: border-box;
                transition: background 1s, border 1s;

                svg {
                    color: $color-kim-gris2;
                }
                &:hover {
                    background-color: $color-kim-gris1;
                    border-color: $color-kim-gris1;
                }
            }
            .control {
                font-size: 0;
            }
        }

        .input-text.qty {
            $tocart-input-size: $button__line-height__l + 3rem;
            font-size: $font-size__xl;
            width: $tocart-width;
            height: $tocart-height;
            text-align: center;
            border-color: $color-kim-gris2;

            vertical-align: middle;
            border-left: none;
            border-right: none;
        }

        .actions {
            text-align: center;
        }

        .action.tocart {
            @extend .abs-button-l;
            height: $tocart-height;
        }
    }

    .product-addto-links {
        margin: $indent__base 0;
    }

    .action.tocompare {
        @extend .abs-action-addto-product;
        vertical-align: top;
    }
}

.prices-tier {
    @extend .abs-reset-list;
    @include lib-css(background, $sidebar__background-color);
    margin: $indent__s 0;
    padding: $indent__s (0.75 * $indent__base);

    .price-container {
        display: inline-block;
    }

    .price-including-tax,
    .price-excluding-tax,
    .weee {
        display: inline-block;

        .price {
            @include lib-font-size(14);
            font-weight: $font-weight__semibold;
        }
    }
}

.ui-dialog-titlebar-close {
    @include lib-button-as-link();
}

.block.related {
    .block-title.title {
        text-align: center;
        #block-related-heading {
            //font-family: $font-family__Raleway-regular;
            font-weight: $font-weight__regular;
            @include lib-text(extra);
            margin-bottom: $indent__m;
            text-transform: uppercase;
        }
    }
    .action.select {
        margin: 0 $indent__xs;
    }
}
.block.upsell {
    .block-title.title {
        #block-upsell-heading{
            @include lib-text(normal);
            text-transform: uppercase;
        }
    }
    #upsell-products-ol {
        display: block;
        margin-top: 0;
        li {
            border: 1px solid $color-kim-gris2;
            display: inline-block;
            margin-right: $indent__base;
            width: 47%;
            .product-item-info:hover {
                border: none;
                box-shadow: none;
            }
            .product-item-info {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                margin: 0;
                max-width: 100%;
                padding: $indent__xxxs $indent__base;
                text-align: left;
                flex-direction: row;
                align-items: center;
                .product.photo.product-item-photo {
                    width: 40%;
                }
                .product.details.product-item-details {
                    display: block;
                    width: 56%;
                    .price-box.price-final_price {
                        margin: 0;
                        .price-container.price-final_price {
                            margin: 0;
                            .price-wrapper .price {
                                @include lib-text(grande);
                            }
                        }
                        .special-price {
                            margin: 0;
                        }
                        .old-price {
                            grid-area: original;
                            .price-container.price-final_price .price-wrapper .price {
                                color: $color-kim-gris2;
                                @include lib-text(normal);
                            }
                        }
                        .discount-label {
                            display: none;
                        }
                    }
                    .product.name.product-item-name {
                        margin: $indent__xxs 0;
                        justify-content: flex-start;
                        a {
                            @include lib-text(pequeno);
                        }
                    }
                    .product.actions.product-item-actions {
                        margin: 0;
                    }
                }
            }
        }
    }
}
#product-view-filters {
    border-bottom: 1px solid $color-kim-gris2;
    border-top: 1px solid $color-kim-gris2;
    clear: both;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    div span.title {
        display: block;
        @include lib-text(medio);
        margin-bottom: $indent__base;
    }
    .brand-filter,
    .collection-filter,
    .other-filter {
        width: 33.3%;
    }
    .brand-filter {
        .content-wrap {
            .content svg {
                color: $color-kim-primario2;
            }
        }
    }
    .collection-filter {
        .content-wrap {
            .content svg {
                color: $color-kim-primario4;
            }
        }
    }
    .other-filter {
        .content-wrap {
            .content svg {
                color: $color-kim-primario1;
            }
        }
    }

    .content-wrap {
        a {
            display: inline-block;
            margin-right: $indent__base;
            margin-bottom: $indent__base;
            padding: $indent__xxs;
            @include lib-text(pequeno);
            background-color: $color-kim-gris3;

            .content {
                text-align: center;

                svg {
                    width: 0.8rem;
                    height: 0.8rem;
                }

                .text {
                    margin-left: $indent__xxs;
                    text-transform: uppercase;
                    vertical-align: middle;
                }
            }

            &:hover {
                color: $color-kim-primario1;
            }
        }

    }
}

//
//  Sidebar product view
//  ---------------------------------------------

.sidebar {
    .product-items {
        .product-item {
            margin-bottom: $indent__base;
            position: relative;
        }

        .product-item-info {
            position: relative;
            width: auto;

            .product-item-photo {
                left: 0;
                position: absolute;
                top: 0;
            }
        }

        .product-item-name {
            margin-top: 0;
        }

        .product-item-details {
            margin: 0 0 0 85px;
        }

        .product-item-actions {
            display: block;
            margin-top: $indent__s;
        }

        .price-box {
            display: block;
            margin: 7px 0;
        }

        .text {
            margin-right: 8px;
        }

        .counter {
            @include lib-css(color, $primary__color__lighter);
            @include lib-font-size(12);
            white-space: nowrap;
        }

        .minilist {
            .price {
                display: inline;
                padding: 0;
            }

            .weee:before {
                display: inline-block;
            }
        }
    }

    .action {
        &.delete {
            @extend .abs-remove-button-for-blocks;
            position: absolute;
            right: 0;
            top: 0;
        }
    }

    .subtitle {
        @extend .abs-no-display;
    }

    //
    //  Product images only
    //  ---------------------------------------------

    .product-items-images {
        @extend .abs-add-clearfix;
        margin-left: -$indent__xs;

        .product-item {
            @extend .abs-add-box-sizing;
            float: left;
            padding-left: $indent__xs;
        }
    }

    //
    //  Product names only
    //  ---------------------------------------------

    .product-items-names {
        .product-item {
            margin-bottom: $indent__s;
        }

        .product-item-name {
            margin: 0;
        }
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__s) {
    .block.upsell {
        #upsell-products-ol {
            text-align: center;
            li {
                margin-bottom: $indent__xxs ;
                margin-right: 0;
                width: 80%;
            }
        }
    }
    #product-view-filters {
        .brand-filter,
        .collection-filter,
        .other-filter {
            width: 100%;
        }
    }
}
@include max-screen($screen__xs) {
    .catalog-product-view {
        .product-info-main .box-tocart {
            .fieldset .actions {
                width: 68%;
            }
        }
    }
    .block.upsell {
        #upsell-products-ol {
            li {
                width: 100%;
            }
        }
    }

}
@include max-screen($screen__xxs) {
    .catalog-product-view {
        .product-info-main .box-tocart {
            .fieldset .actions {
                width: 100%;
            }
        }
    }
}

//
//  tablet
//  _____________________________________________

@include max-screen($screen__m) {
    .catalog-product-view {
        .column.main {
            position: relative;
            @include lib-vendor-prefix-display(flex);
            @include lib-vendor-prefix-flex-direction(column);
            .special-product-labels:not(.agotado) {
                left: 30px;
            }
        }

        .product.media {
            margin-bottom: $indent__m;
            @include lib-vendor-prefix-order(-1);
        }

        .product-info-main .box-tocart {
            .field.qty,
            .actions {
                display: inline-block;
            }
            .actions {
                position: relative;
                top: 3px;
                width: 38%;
            }
        }
    }
    .product-info-main .box-tocart {
        .actions {
            .action.tocart {
                @extend .abs-button-responsive-smaller;
            }
        }
    }
    .block.related {
        .action.select {
            display: block;
            margin: $indent__xs 0;
        }
    }
    .block.upsell {
        margin-top: $indent__m;
        .block-title.title {
            #block-upsell-heading {
                font-size: 13px;
            }
        }
    }
    #product-view-filters {
        padding: $indent__m 0;
        div span.title {
            margin-top: $indent__base;
        }
    }
    .product.info.detailed {
        margin-bottom: $indent__m;
        padding: $indent__l 0 $indent__s 0;
        .data.item.title {
            font-size: 15px;
            margin: 0;
            padding: 0;
            a#tab-label-description-title {
                margin: 0;
                padding: 0;
            }
        }
        .data.item.content#description {
            font-size: 13px;
            padding: 0;
        }
        .product.attribute.description {
            .value {
                .column1,
                .column2 {
                    width: 100%;
                    .product-descri-video {
                        .desktop-link,
                        .video,
                        img {
                            display: none;
                        }
                        .mobile-button {
                            display: block;
                            max-width: 160px;
                        }
                    }
                }
                .column1 {
                    margin-bottom: 12px;
                }
            }
        }
    }

    .compare,
    .product-addto-links .action.tocompare,
    .product-item-actions .actions-secondary > .action.tocompare,
    [class*='block-compare'] {
        display: none;
    }

}


@include max-screen($screen__xs) {
    .catalog-product-view {
        .column.main {
            .special-product-labels:not(.agotado) {
                left: 0;
            }
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {

    body.catalog-category-view .page-title-wrapper h1,
    body.catalog-category-view .page.messages,
    body.catalogsearch-result-index .page-title-wrapper,
    body.catalogsearch-result-index .page.messages,
    .category-view {
        width: calc(100% - 225px);
        float: right;
    }

    .product-info-main,
    .product-options-bottom {
        .box-tocart {
            display: table;

            .field.qty {
                display: table-cell;
            }

            .actions {
                display: table-cell;
                padding-top: 0;
                text-align: center;
                vertical-align: bottom;
            }
        }
    }

    .product-info-main {
        .page-title-wrapper {
            .page-title {
                margin-top: -13px;
            }
        }
    }

    .sidebar {
        .product-items {
            .product-item-info {
                .product-item-photo {
                    float: left;
                    left: auto;
                    margin: 0 $indent__s $indent__s 0;
                    position: relative;
                    top: auto;
                }
            }

            .product-item-details {
                margin: 0;
            }

            .product-item-actions {
                clear: left;
            }
        }
    }

    .product-add-form {
        @extend .abs-revert-field-type-desktop;
    }
    .block.upsell {
        #upsell-products-ol {
            li {
                margin-right: $indent__xxs;
                width: 49%;
                &:last-child {
                    margin-right: 0;
                }
                .product-item-info {
                    padding: $indent__xxxs $indent__xxxs;
                    .product.photo.product-item-photo {
                        width: 38%;
                    }
                    .product.details.product-item-details {
                        width: 61%;
                    }
                }
            }
        }
    }
    #product-view-filters {
        padding: $indent__m 0;
    }
}

//
//  Desktop large
//  _____________________________________________

@include min-screen($screen__xl) {
    .sidebar {
        .product-items {
            .product-item-info {
                .product-item-photo {
                    float: none;
                    left: 0;
                    margin: 0;
                    position: absolute;
                    top: 0;
                }
            }

            .product-item-details {
                margin-left: 85px;
            }
        }
    }
}

@include min-screen($screen__xxl) {

    body.catalog-category-view .page-title-wrapper h1,
    body.catalog-category-view .page.messages,
    body.catalogsearch-result-index .page-title-wrapper,
    body.catalogsearch-result-index .page.messages,
    .category-view {
        width: calc(100% - 275px);
    }

}

//
//  Desktop
//  _____________________________________________
@include min-screen($screen__l-2) {

    .page-layout-1column {
        .product-info-main {
            width: 52%;
        }
        .product.media {
            width: 48%;
        }
    }
    #product-view-filters {
        padding: $indent__xxxl $indent__xxxxxl;
    }
}

//
//  Category page layout
//  ---------------------------------------------

@include min-screen($screen__m) {
    .product-info-main {
        float: right;
    }

    .product.media {
        float: left;
        margin-bottom: $indent__m;
    }

    .page-layout-1column {
        .product-info-main {
            width: 54%;
            box-sizing: border-box;
            padding-left: $indent__s;
        }
        .product.media {
            width: 46%;
        }
    }

    .page-layout-2columns-left,
    .page-layout-2columns-right,
    .page-layout-3columns {
        .product-info-main {
            width: 48%;
        }

        .product.media {
            width: 50%;
        }
    }
}

//
//  Compare Products Page
//  ---------------------------------------------

body.catalog-product-compare-index {
    .action.print {
        float: right;
        margin: 15px 0;
    }
}

.table-wrapper.comparison {
    clear: both;
    max-width: 100%;
    overflow-x: auto;
}

.table-comparison {
    table-layout: fixed;

    .cell.label.remove,
    .cell.label.product {
        span {
            @extend .abs-visually-hidden;
        }
    }

    .cell.label,
    td:last-child {
        border-right: $table__border-width $table__border-style $table__border-color;
    }

    .cell {
        padding: 15px;
        width: 140px;

        .attribute.value {
            overflow: hidden;
            width: 100%;
        }

        &.product.info,
        &.product.label {
            border-bottom: $table__border-width $table__border-style $table__border-color;
        }

        &.label {
            .attribute.label {
                display: block;
                width: 100%;
                word-wrap: break-word;
            }
        }

        &.attribute {
            @include lib-font-size(13);
            img {
                height: auto;
                max-width: 100%;
            }
        }
    }

    .product-item-photo {
        display: block;
        margin: 0 auto 15px;
    }

    .product-image-photo {
        margin-left: 0;
    }

    .product-item-actions,
    .price-box,
    .product.rating,
    .product-item-name {
        display: block;
        margin: 15px 0;
    }

    .product-addto-links {
        margin-top: 15px;

        .action.split,
        .action.toggle {
            @include lib-button-s();
        }

        .action.toggle {
            padding: 0;
        }
    }

    .cell.remove {
        padding-bottom: 0;
        padding-top: 0;
        text-align: right;

        .action.delete {
            @extend .abs-remove-button-for-blocks;
        }
    }

    .product-item-actions {
        > .actions-primary {
            + .actions-secondary {
                margin-top: $indent__s;
            }
        }
    }

    .action {
        &.tocart {
            white-space: nowrap;
        }
    }
}

.comparison.headings {
    @include lib-css(background, $page__background-color);
    left: 0;
    position: absolute;
    top: 0;
    width: auto;
    z-index: 2;
}

.block-compare {
    .block-title {
        @extend .abs-block-title;
    }

    .product-item .product-item-name {
        margin-left: 22px;
    }

    .action {
        &.delete {
            @extend .abs-remove-button-for-blocks;
            left: -6px;
            position: absolute;
            top: 0;
        }

        &.compare {
            @extend .abs-revert-secondary-color;
        }
    }

    .counter {
        @extend .abs-block-items-counter;
    }

    .actions-toolbar {
        margin: 17px 0 0;
    }
}

//
//  Product pre-view
//  ---------------------------------------------
.product{
    &.attribute{
        &.brand{
            img{
                width: 100%;
                max-width: 100px;
            }
        }
    }
}

//
//  Product info view
//  ---------------------------------------------
.product-info-view {
    text-align: center;
    padding: $indent__base;

    .product-link {
        @include lib-heading(h2);
        display: block;
    }

    .price-box {
        @include lib-price-box();
        display: inline-grid;
        text-align: left;
    }

    .action.towishlist {
        display: inline-block;
        margin-top: $indent__s;
    }
}
