//
//  Typography variables
//  _____________________________________________

//
//  Fonts
//  ---------------------------------------------

//  Path
$icons__font-path               : "../fonts/Blank-Theme-Icons/Blank-Theme-Icons" !default;

//  Names
$icons__font-name               : 'icons-blank-theme' !default; // ToDo UI                                         : we need to rename (it shouldn't use blank theme name) or move icon fonts to blank theme

//  Font families

//  Font families
$font-family__Raleway-regular          : 'Raleway';
$font-family__Raleway-medium           : 'Raleway';
$font-family__Raleway-medium-italic    : 'Raleway';
$font-family__Raleway-semibold         : 'Raleway';


$font-family-name__base         : $font-family__Raleway-regular;
$font-family__sans-serif        : 'Helvetica Neue', Helvetica, Arial, sans-serif !default;
$font-family__serif             : Georgia, 'Times New Roman', Times, serif !default;
$font-family__monospace         : Menlo, Monaco, Consolas, 'Courier New', monospace !default;

$font-family__base              : $font-family-name__base, $font-family__sans-serif !default;

//  Sizes
$root__font-size                : 62.5% !default; // Defines ratio between root font size and base font size, 1rem = 10px
$font-size-ratio__base          : 1.3 !default; // Defines ratio of the root font-size to the base font-size

$font-size-unit                 : rem !default; // The unit to which most typography values will be converted by default
$font-size-unit-ratio           : $root__font-size * 16 / 100 / 1% !default; // Ratio of the root font-size to the font-size unit
$font-size-unit-convert         : true !default; // Controls whether font-size values are converted to the specified font-size unit

$font-size__base                : $font-size-unit-ratio * $font-size-ratio__base * 1px !default; // Base font size value in px
$font-size__xxl                 : 2rem; // ~20px
$font-size__xl                  : 1.7rem; // ~17px
$font-size__l                   : 1.5rem; // ~15px
$font-size__s                   : 1.1rem; // ~11px
$font-size__xs                  : ceil(0.9 * $font-size__base) !default; // 11px

//  Weights
$font-weight__light             : 300 !default;
$font-weight__regular           : 400 !default;
$font-weight__heavier           : 500 !default;
$font-weight__semibold          : 600 !default;
$font-weight__bold              : 700 !default;

//  Styles
$font-style__base               : normal !default;
$font-style__emphasis           : italic !default;

//  Line heights
$line-height__base              : 1.25;
$line-height__computed          : floor($font-size__base * $line-height__base) !default;
$line-height__xl                : 1.7 !default;
$line-height__l                 : 1.5 !default;
$line-height__s                 : 1.33 !default;

//  Colors
$text__color                    : $color-kim-gris1;
$text__color__intense           : $primary__color__darker !default;
$text__color__muted             : $color-kim-gris1;

//
//  Indents
//  ---------------------------------------------

$indent__base                   : 16px;
$indent__xxxxxl                 : 86px;
$indent__xxxxl                  : 72px;
$indent__xxxl                   : 64px;
$indent__xxl                    : 56px;
$indent__xl                     : 48px;
$indent__l                      : 40px;
$indent__m                      : 32px;
$indent__s                      : 24px;
$indent__xs                     : 12px;
$indent__xxs                    : 8px;
$indent__xxxs                   : 4px;

//
//  Borders
//  ---------------------------------------------

$border-color__base             : darken($page__background-color, 18%) !default;
$border-width__base             : 1px !default;

//
//  Links
//  ---------------------------------------------

$link__color                    : $color-kim-gris1;
$link__font-family              : $font-family-name__base;
$link__font-weight              : $font-weight__regular;
$link__font-style               : $font-style__base;
$link__text-decoration          : none;
$link__transition               : color 1s;

$link__visited__color           : $color-kim-gris1;
$link__visited__font-family     : $font-family-name__base;
$link__visited__font-weight     : $font-weight__regular;
$link__visited__font-style      : $font-style__base;
$link__visited__text-decoration : none;
$link__visited__transition      : color 1s;

$link__hover__color             : $color-kim-primario1;
$link__hover__font-family       : $font-family-name__base;
$link__hover__font-weight       : $font-weight__regular;
$link__hover__font-style        : $font-style__base;
$link__hover__text-decoration   : none;
$link__hover__transition        : color 1s;

$link__active__color            : $color-kim-gris1;
$link__active__font-family      : $font-family-name__base;
$link__active__font-weight      : $font-weight__regular;
$link__active__font-style       : $font-style__base;
$link__active__text-decoration  : none;
$link__active__transition       : color 1s;

/* Secondary */

$link__secondary__color                    : $color-kim-gris1;
$link__secondary__font-family              : $font-family-name__base;
$link__secondary__font-weight              : $font-weight__regular;
$link__secondary__font-style               : $font-style__base;
$link__secondary__text-decoration          : none;
$link__secondary__transition               : color 1s;

$link__visited__secondary__color           : $color-kim-gris1;
$link__visited__secondary__font-family     : $font-family-name__base;
$link__visited__secondary__font-weight     : $font-weight__regular;
$link__visited__secondary__font-style      : $font-style__base;
$link__visited__secondary__text-decoration : underline;
$link__visited__secondary__transition      : color 1s;

$link__hover__secondary__color             : $color-kim-gris1;
$link__hover__secondary__font-family       : $font-family-name__base;
$link__hover__secondary__font-weight       : $font-weight__regular;
$link__hover__secondary__font-style        : $font-style__base;
$link__hover__secondary__text-decoration   : underline;
$link__hover__secondary__transition        : color 1s;

$link__active__secondary__color            : $color-kim-gris1;
$link__active__secondary__font-family      : $font-family-name__base;
$link__active__secondary__font-weight      : $font-weight__regular;
$link__active__secondary__font-style       : $font-style__base;
$link__active__secondary__text-decoration  : underline;
$link__active__secondary__transition       : color 1s;

/* Tertiary */

$link__tertiary__color                    : $color-kim-gris1;
$link__tertiary__font-family              : $font-family-name__base;
$link__tertiary__font-weight              : $font-weight__regular;
$link__tertiary__font-style               : $font-style__base;
$link__tertiary__text-decoration          : none;
$link__tertiary__transition               : color 1s;

$link__visited__tertiary__color           : $color-kim-gris1;
$link__visited__tertiary__font-family     : $font-family-name__base;
$link__visited__tertiary__font-weight     : $font-weight__regular;
$link__visited__tertiary__font-style      : $font-style__base;
$link__visited__tertiary__text-decoration : none;
$link__visited__tertiary__transition      : color 1s;

$link__hover__tertiary__color             : $color-kim-gris1;
$link__hover__tertiary__font-family       : $font-family-name__base;
$link__hover__tertiary__font-weight       : $font-weight__heavier;
$link__hover__tertiary__font-style        : $font-style__base;
$link__hover__tertiary__text-decoration   : none;
$link__hover__tertiary__transition        : color 1s;

$link__active__tertiary__color            : $color-kim-gris1;
$link__active__tertiary__font-family      : $font-family-name__base;
$link__active__tertiary__font-weight      : $font-weight__regular;
$link__active__tertiary__font-style       : $font-style__base;
$link__active__tertiary__text-decoration  : none;
$link__active__tertiary__transition       : color 1s;

/* Quaternary */

$link__quaternary__color                    : $color-kim-gris1;
$link__quaternary__font-family              : $font-family-name__base;
$link__quaternary__font-weight              : $font-weight__heavier;
$link__quaternary__font-style               : $font-style__emphasis;
$link__quaternary__text-decoration          : underline;
$link__quaternary__transition               : color 1s;

$link__visited__quaternary__color           : $color-kim-gris1;
$link__visited__quaternary__font-family     : $font-family-name__base;
$link__visited__quaternary__font-weight     : $font-weight__heavier;
$link__visited__quaternary__font-style      : $font-style__emphasis;
$link__visited__quaternary__text-decoration : underline;
$link__visited__quaternary__transition      : color 1s;

$link__hover__quaternary__color             : $color-kim-primario1;
$link__hover__quaternary__font-family       : $font-family-name__base;
$link__hover__quaternary__font-weight       : $font-weight__heavier;
$link__hover__quaternary__font-style        : $font-style__emphasis;
$link__hover__quaternary__text-decoration   : underline;
$link__hover__quaternary__transition        : color 1s;

$link__active__quaternary__color            : $color-kim-primario1;
$link__active__quaternary__font-family      : $font-family-name__base;
$link__active__quaternary__font-weight      : $font-weight__heavier;
$link__active__quaternary__font-style       : $font-style__emphasis;
$link__active__quaternary__text-decoration  : underline;
$link__active__quaternary__transition       : color 1s;


/* Quinary */

$link__quinary__color                    : $color-kim-gris1;
$link__quinary__font-family              : $font-family-name__base;
$link__quinary__font-weight              : $font-weight__heavier;
$link__quinary__font-style               : $font-style__emphasis;
$link__quinary__text-decoration          : none;
$link__quinary__transition               : color 1s;

$link__visited__quinary__color           : $color-kim-gris1;
$link__visited__quinary__font-family     : $font-family-name__base;
$link__visited__quinary__font-weight     : $font-weight__heavier;
$link__visited__quinary__font-style      : $font-style__emphasis;
$link__visited__quinary__text-decoration : none;
$link__visited__quinary__transition      : color 1s;

$link__hover__quinary__color             : $color-kim-primario1;
$link__hover__quinary__font-family       : $font-family-name__base;
$link__hover__quinary__font-weight       : $font-weight__heavier;
$link__hover__quinary__font-style        : $font-style__emphasis;
$link__hover__quinary__text-decoration   : none;
$link__hover__quinary__transition        : color 1s;

$link__active__quinary__color            : $color-kim-primario1;
$link__active__quinary__font-family      : $font-family-name__base;
$link__active__quinary__font-weight      : $font-weight__heavier;
$link__active__quinary__font-style       : $font-style__emphasis;
$link__active__quinary__text-decoration  : none;
$link__active__quinary__transition       : color 1s;
//
//  Focus
//  ---------------------------------------------

$focus__color                   : $color-sky-blue1 !default;
$focus__box-shadow              : 0 0 3px 1px $focus__color !default;

//
//  Lists
//  ---------------------------------------------

$list__color__base              : false !default;
$list__font-size__base          : false !default;
$list__margin-top               : 0 !default;
$list__margin-bottom            : $indent__m !default;

$list-item__margin-top          : 0 !default;
$list-item__margin-bottom       : $indent__s !default;

$dl__margin-top                 : 0 !default;
$dl__margin-bottom              : $indent__base !default;

$dt__margin-top                 : 0 !default;
$dt__margin-bottom              : $indent__xs !default;
$dt__font-weight                : $font-weight__semibold !default;

$dd__margin-top                 : 0 !default;
$dd__margin-bottom              : $indent__s !default;

//
//  Paragraphs
//  ---------------------------------------------

$p__margin-top                  : 0 !default;
$p__margin-bottom               : $indent__xs !default;

//
//  Headings
//  ---------------------------------------------

$heading__font-family__base     : false !default;
$heading__font-weight__base     : $font-weight__regular !default;
$heading__line-height__base     : 1.1 !default;
$heading__color__base           : $color-kim-gris1;
$heading__font-style__base      : false !default;
$heading__margin-top__base      : $indent__base !default;
$heading__margin-bottom__base   : $indent__xs;

$h1__font-size                  : 2.4rem; // ~24px
$h1__font-size-screen__s        : $font-size__xxl; // ~20px tablet
$h1__font-size-screen__xs       : 1.8rem; // ~18px móvil
$h1__font-color                 : $heading__color__base;
$h1__font-family                : $heading__font-family__base;
$h1__font-weight                : $font-weight__semibold;
$h1__font-style                 : $heading__font-style__base !default;
$h1__line-height                : normal;
$h1__margin-top                 : 0 !default;
$h1__margin-bottom              : $heading__margin-bottom__base !default;

$h2__font-size                  : 2.4rem; // ~24px
$h2__font-size-screen__s        : $font-size__xl; // ~17px tablet
$h2__font-size-screen__xs       : $font-size__l; // ~15px móvil
$h2__font-color                 : $heading__color__base !default;
$h2__font-family                : $heading__font-family__base;
$h2__font-weight                : $font-weight__semibold;
$h2__font-style                 : $heading__font-style__base !default;
$h2__line-height                : $heading__line-height__base !default;
$h2__margin-top                 : 0;
$h2__margin-bottom              : $heading__margin-bottom__base !default;

$h3__font-size                  : ceil(($font-size__base * 1.28)) !default; // 18px
$h3__font-size-screen__s        : $font-size__xl; // ~17px tablet
$h3__font-size-screen__xs       : $font-size__l; // ~15px móvil
$h3__font-color                 : $heading__color__base !default;
$h3__font-family                : $heading__font-family__base !default;
$h3__font-weight                : $heading__font-weight__base !default;
$h3__font-style                 : $heading__font-style__base !default;
$h3__line-height                : $heading__line-height__base !default;
$h3__margin-top                 : $indent__base * 0.75 !default;
$h3__margin-bottom              : $indent__s !default;

$h4__font-size                  : $font-size__base !default; // 14px
$h4__font-color                 : $heading__color__base !default;
$h4__font-family                : $heading__font-family__base !default;
$h4__font-weight                : $font-weight__semibold !default;
$h4__font-style                 : $heading__font-style__base !default;
$h4__line-height                : $heading__line-height__base !default;
$h4__margin-top                 : $heading__margin-top__base !default;
$h4__margin-bottom              : $heading__margin-bottom__base !default;

$h5__font-size                  : ceil(($font-size__base * 0.85)) !default; // 12px
$h5__font-color                 : $heading__color__base !default;
$h5__font-family                : $heading__font-family__base !default;
$h5__font-weight                : $font-weight__semibold !default;
$h5__font-style                 : $heading__font-style__base !default;
$h5__line-height                : $heading__line-height__base !default;
$h5__margin-top                 : $heading__margin-top__base !default;
$h5__margin-bottom              : $heading__margin-bottom__base !default;

$h6__font-size                  : ceil(($font-size__base * 0.9)) !default; // 10px
$h6__font-color                 : $heading__color__base !default;
$h6__font-family                : $heading__font-family__base !default;
$h6__font-weight                : $font-weight__semibold !default;
$h6__font-style                 : $heading__font-style__base !default;
$h6__line-height                : $heading__line-height__base !default;
$h6__margin-top                 : $heading__margin-top__base !default;
$h6__margin-bottom              : $heading__margin-bottom__base !default;

$heading__small-color           : $primary__color !default;
$heading__small-line-height     : 1 !default;
$heading__small-size            : ($font-size__xs / $font-size__base) * 100% !default;

//
//  Texts
//  ---------------------------------------------
$text__font-family__base     : false !default;
$text__font-weight__base     : $font-weight__regular !default;
$text__color__base           : false !default;
$text__font-style__base      : false !default;
$text__margin-top__base      : $indent__xxs;
$text__margin-bottom__base   : $indent__xxs;

$text-giant__font-size                  : 3.6rem; // ~36px
$text-giant__font-size-screen__s        : 3rem; // ~17px tablet
$text-giant__font-size-screen__xs       : 3rem; // ~15px móvil
$text-giant__font-color                 : $color-kim-gris1;
$text-giant__font-family                : $text__font-family__base;
$text-giant__font-weight                : $font-weight__semibold !default;
$text-giant__font-style                 : $text__font-style__base !default;
$text-giant__line-height                : 1.41;
$text-giant__line-height-screen__s      : 1.33; // tablet
$text-giant__line-height-screen__xs     : 1.33; // móvil
$text-giant__margin-top                 : 0 !default;
$text-giant__margin-bottom              : 0 !default;

$text-extragrande__font-size                  : 2.4rem; // ~24px
$text-extragrande__font-size-screen__s        : $font-size__xl; // ~17px tablet
$text-extragrande__font-size-screen__xs       : $font-size__l; // ~15px móvil
$text-extragrande__font-color                 : $color-kim-gris1;
$text-extragrande__font-family                : $text__font-family__base !default;
$text-extragrande__font-weight                : $text__font-weight__base !default;
$text-extragrande__font-style                 : $text__font-style__base !default;
$text-extragrande__line-height                : 1.41;
$text-extragrande__line-height-screen__s      : 1.33; // tablet
$text-extragrande__line-height-screen__xs     : 1.33; // móvil
$text-extragrande__margin-top                 : 0 !default;
$text-extragrande__margin-bottom              : 0 !default;


$text-grande__font-size                  : $font-size__xl; // ~17px
$text-grande__font-size-screen__s        : $font-size__l; // ~15px tablet
$text-grande__font-size-screen__xs       : $font-size__l; // ~15px móvil
$text-grande__font-color                 : $color-kim-gris1;
$text-grande__font-family                : $text__font-family__base !default;
$text-grande__font-weight                : $text__font-weight__base !default;
$text-grande__font-style                 : $text__font-style__base !default;
$text-grande__line-height                : 1.41;
$text-grande__line-height-screen__s      : 1.33; // tablet
$text-grande__line-height-screen__xs     : 1.33; // móvil
$text-grande__margin-top                 : 0 !default;
$text-grande__margin-bottom              : $text__margin-bottom__base !default;

$text-medio__font-size                  : $font-size__l; // ~15px
$text-medio__font-size-screen__s        : $font-size__base; // ~13px tablet
$text-medio__font-size-screen__xs       : $font-size__base; // ~13px móvil
$text-medio__font-color                 : $color-kim-gris1;
$text-medio__font-family                : $text__font-family__base !default;
$text-medio__font-weight                : $text__font-weight__base !default;
$text-medio__font-style                 : $text__font-style__base !default;
$text-medio__line-height                : 1.33;
$text-medio__line-height-screen__s      : 1.15; // tablet
$text-medio__line-height-screen__xs     : 1.15; // móvil
$text-medio__margin-top                 : 0 !default;
$text-medio__margin-bottom              : $text__margin-bottom__base !default;

$text-normal__font-size                  : $font-size__base; // ~13px
$text-normal__font-size-screen__s        : $font-size__s; // ~12px tablet
$text-normal__font-size-screen__xs       : $font-size__s; // ~12px móvil
$text-normal__font-color                 : $color-kim-gris1;
$text-normal__font-family                : $text__font-family__base !default;
$text-normal__font-weight                : $text__font-weight__base !default;
$text-normal__font-style                 : $text__font-style__base !default;
$text-normal__line-height                : 1.15;
$text-normal__line-height-screen__s      : 1.25; // tablet
$text-normal__line-height-screen__xs     : 1.25; // móvil
$text-normal__margin-top                 : 0 !default;
$text-normal__margin-bottom              : $text__margin-bottom__base !default;

$text-pequeno__font-size                  : $font-size__s; // ~11px
$text-pequeno__font-size-screen__s        : $font-size__s; // ~11px tablet
$text-pequeno__font-size-screen__xs       : $font-size__s; // ~11px móvil
$text-pequeno__font-color                 : $color-kim-gris1;
$text-pequeno__font-family                : $text__font-family__base !default;
$text-pequeno__font-weight                : $text__font-weight__base !default;
$text-pequeno__font-style                 : $text__font-style__base !default;
$text-pequeno__line-height                : 1.18;
$text-pequeno__line-height-screen__s      : 1.3; // tablet
$text-pequeno__line-height-screen__xs     : 1.3; // móvil
$text-pequeno__margin-top                 : 0 !default;
$text-pequeno__margin-bottom              : $text__margin-bottom__base !default;

//  Code blocks
$code__background-color         : $panel__background-color !default;
$code__color                    : $primary__color__darker !default;
$code__font-size                : $font-size__s !default;
$code__padding                  : 2px 4px !default;

$pre__background-color          : $panel__background-color !default;
$pre__border-color              : $border-color__base !default;
$pre__border-width              : $border-width__base !default;
$pre__color                     : $primary__color__darker !default;

$kbd__background-color          : $panel__background-color !default;
$kbd__color                     : $primary__color__darker !default;

//  Blockquote
$blockquote__border-color       : $border-color__base !default;
$blockquote__border-width       : 0 !default;
$blockquote__content-before     : '\2014 \00A0' !default;
$blockquote__font-size          : $font-size__base !default;
$blockquote__font-style         : $font-style__emphasis !default;
$blockquote__margin             : 0 0 $indent__base $indent__xl !default;
$blockquote__padding            : 0 !default;

$blockquote-small__color        : $primary__color !default;
$blockquote-small__font-size    : $font-size__xs !default;

$cite__font-style               : $font-style__base !default;

//  Misc
$hr__border-color               : $border-color__base !default;
$hr__border-style               : solid !default;
$hr__border-width               : $border-width__base !default;

$mark__color                    : $primary__color__dark !default;
$mark__background-color         : $panel__background-color !default;

$abbr__border-color             : $border-color__base !default;

//  Disable filters output in css
$disable-filters                : false !default;
