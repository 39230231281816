@include lib-font-face(
    $family-name: $font-family-name__base,
    $font-path: '../fonts/opensans/light/opensans-300',
    $font-weight: 300,
    $font-style: normal
);

@include lib-font-face(
    $family-name: $font-family-name__base,
    $font-path: '../fonts/opensans/regular/opensans-400',
    $font-weight: 400,
    $font-style: normal
);

@include lib-font-face(
    $family-name: $font-family-name__base,
    $font-path: '../fonts/opensans/semibold/opensans-600',
    $font-weight: 600,
    $font-style: normal
);

@include lib-font-face(
    $family-name: $font-family-name__base,
    $font-path: '../fonts/opensans/bold/opensans-700',
    $font-weight: 700,
    $font-style: normal
);

//
//  Kimidori font family
//  _____________________________________________

@include lib-font-face(
    $family-name: $font-family__Raleway-regular,
    $font-path: '../fonts/Raleway/Raleway-Regular',
    $font-weight: $font-weight__regular,
    $font-style: $font-style__base
);

@include lib-font-face(
        $family-name: $font-family__Raleway-medium,
        $font-path: '../fonts/Raleway/Raleway-Medium',
        $font-weight: $font-weight__heavier,
        $font-style: $font-style__base
);

@include lib-font-face(
        $family-name: $font-family__Raleway-medium-italic,
        $font-path: '../fonts/Raleway/Raleway-MediumItalic',
        $font-weight: $font-weight__heavier,
        $font-style: $font-style__emphasis
);

@include lib-font-face(
    $family-name: $font-family__Raleway-semibold,
    $font-path: '../fonts/Raleway/Raleway-SemiBold',
    $font-weight: $font-weight__semibold,
    $font-style: $font-style__base
);



//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    h1 {
        @include lib-css(font-size, $h1__font-size);
        @include lib-css(margin-bottom, $h1__margin-bottom__desktop);
    }
}

.items {
    @include lib-list-reset-styles();
}
