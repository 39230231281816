.cms-tematicas {
    .page-main .columns {
        display: block;
    }
    .tags-count {
        //font-family: $font-family__Raleway-medium-italic;
        //font-style: italic;
        font-weight: $font-weight__heavier;
        font-style: $font-style__emphasis;
        @include lib-text(normal);
    }

    .tags-filter {
        border-top: 1px solid $color-kim-gris2;
        padding: $indent__xxl 0;
        .tag-list{
            li{
                margin: 12px 12px;
            }
            a{
                padding: 8px;
            }
            li a span.svg svg{
                width: 0.8rem;
                height: 0.8rem;
            }
            li a span.svg svg polygon.color-1.st0 {
                fill: $color-kim-primario1;
            }
            li a span.svg svg polygon.color-2.st0 {
                fill: $color-kim-primario2;
            }
            li a span.svg svg polygon.color-3.st0 {
                fill: $color-kim-primario3;
            }
            li a span.svg svg polygon.color-4.st0 {
                fill: $color-kim-primario4;
            }
            li a span.svg svg polygon.color-5.st0 {
                fill: $color-kim-primario5;
            }
            li a span.svg svg polygon.color-6.st0 {
                fill: $color-kim-primario6;
            }

            .text {
                margin-left: $indent__xxs;
                text-transform: uppercase;
                vertical-align: middle;
                font-weight: 600;
                &:hover {
                    color: $color-kim-primario1;
                }
            }
        }

        .tag-mobile-list {
            display: none;
            .select-input-container {
                width: 100%;
                .selected-input-option {
                    @include lib-text(medio);
                    background-color: #fff;
                    background-position-x: 97%;
                    border: 1px solid $color-kim-gris2;
                    padding: $indent__xs;
                    margin-bottom: 0;
                }
                .select-input-options
                {
                    margin-top: $indent__xxxs;
                    @include lib-text(medio);
                    li:first-child {
                        display: none;
                    }
                    li:nth-child(2) {
                        margin-top: $indent__xxs;
                    }
                    li {
                        padding: $indent__xs;
                        margin-bottom: 0;
                    }
                }
            }

        }
    }
    .all-tags-block{
        .tag-details {
            div.title {
                background-color: $color-kim-gris3;
                font-weight: $font-weight__heavier;
                padding-left: $indent__ss;
                text-transform: uppercase;
                @include lib-text(grande);
            }
            div.content ul {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr 1fr;
                grid-column-gap: $indent__xs;
                list-style: none;
                padding: 0;
                margin: $indent__xs 0;
                li {
                    border: 1px solid $color-kim-gris2;
                    padding: $indent__m;
                    text-align: center;
                    text-transform: uppercase;
                    &.color-1:hover {
                        border: 2px solid $color-kim-primario1;
                        padding: 25px;
                    }
                    &.color-2:hover {
                        border: 2px solid $color-kim-primario2;
                        padding: 25px;
                    }
                    &.color-3:hover {
                        border: 2px solid $color-kim-primario3;
                        padding: 25px;
                    }&.color-4:hover {
                         border: 2px solid $color-kim-primario4;
                         padding: 25px;
                     }
                    &.color-5:hover {
                        border: 2px solid $color-kim-primario5;
                        padding: 25px;
                    }
                    &.color-6:hover {
                        border: 2px solid $color-kim-primario6;
                        padding: 25px;
                    }

                    a {
                        img {
                            border-bottom: 1px solid $color-kim-gris2;
                            display: block;
                            margin: 0 auto $indent__base auto;
                            padding-bottom: $indent__base;
                        }
                        span {
                            //font-family: $font-family__Raleway-semibold;
                            font-weight: $font-weight__semibold;
                            @include lib-text(grande);
                            text-transform: uppercase;
                        }
                    }
                }
            }
        }
    }
}
//
//  Large xxl desktop
//  _____________________________________________
@include min-screen($screen__xxxl) {
    .cms-tematicas {
        .all-tags-block {
            .tag-details {
                div.content ul{
                    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
                }
            }
        }
    }
}

//
//  Large desktop
//  _____________________________________________
@include min-screen($screen__xxl-2) {
    .cms-tematicas {
        .all-tags-block {
            .tag-details {
                div.content ul{
                    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
                }
            }
        }
    }
}

//
//  Tablet
//  _____________________________________________
@include max-screen($screen__m) {
    .cms-tematicas {
        .all-tags-block {
            .tag-details {
                div.content ul{
                    padding: $indent__xxs;
                    grid-template-columns: 1fr 1fr 1fr;
                }
            }
        }
    }
}

//
//  mobile
//  _____________________________________________

@include max-screen($screen__s-2) {
    .cms-tematicas {
        .all-tags-block {
            .tag-details {
                div.content ul li:hover{
                    border: 1px solid $color-kim-gris2;
                    padding: $indent__m;
                }
            }
        }
    }
}

@include max-screen($screen__xs) {
    .cms-tematicas {
        .tags-filter {
            padding: $indent__base 0 $indent__xl 0;
            .tag-mobile-list {
                display: block;
            }
            .tag-list {
                display: none;
            }
        }
        .all-tags-block {
            .tag-details {
                div.content ul{
                    grid-template-columns: 1fr 1fr;
                }
            }
        }
    }
}
@include max-screen($screen__xxs-2) {
    .cms-tematicas {
        .all-tags-block {
            .tag-details {
                div.content ul{
                    grid-template-columns: 1fr;
                }
            }
        }
    }
}