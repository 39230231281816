.checkout-agreements-block {
    .checkout-agreements {
        margin-bottom: $indent__base;
        text-decoration: underline;
        text-align: center;
        .action-show {
            @extend .abs-action-button-as-link;
            vertical-align: baseline;
        }
    }

    //  Checkout Agreements in popup
    .checkout-agreements-items {
        @extend .abs-reset-list;
        padding-bottom: $indent__l;

        .checkout-agreements-item {
            margin-bottom: $indent__base;
        }

        .checkout-agreements-item-title {
            @extend .abs-checkout-title;
            border-bottom: 0;
        }
    }

    //  Checkout Agreements
    .checkout-agreement-item-content {
        overflow: auto;
    }

}

