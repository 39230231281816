a.action.primary,
button {
    @include lib-css(border-radius, $button__border-radius);
}
a.action.primary {
    @include lib-link-as-button();
}
.action.primary {
    @include lib-button-kim-primary-l();
}
.action.secondary {
    @include lib-button-kim-secondary-l();
}
.action.alternative {
  @include lib-button-kim-alternative-l();
}
.action.primary.small {
    @include lib-button-kim-primary-s();
}
.action.secondary.small {
    @include lib-button-kim-secondary-s();
    @include max-screen($screen__xxs) {
        font-size: $button-xs-kim__font-size;
    }
}
.action.alternative.small {
    @include lib-button-kim-alternative-s();
}
.action.action-update:hover {
    color: #fff;
    background: $color-kim-primario1;
}
.zEWidget-launcher {
    bottom: 60px!important;
    right: -4px!important;
}
button.src-component-launcher-WidgetLauncher-wrapper {
    border-radius: 0!important;
}


