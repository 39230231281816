.blog-page {
    .post-comments {
        &__text {
            @include lib-heading(h2);
        }
        &__content {
            border-top: 1px solid $color-kim-gris2;
            padding: $indent__base 0;
        }
        &__form {
            .fieldset {
                margin: 0;
            }
            .actions-toolbar {
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;
                align-items: baseline;
                margin-top: $indent__xxs;
            }
            .actions {
                display: flex;
                justify-content: center;
                margin-top: $indent__base;
                .cancel {
                    margin-right: auto;
                }
                .reply {
                    margin-left: $indent__xxs;
                }
            }
        }
        &__form.no-active .form__section {
            display: none;
        }
        &__form .form__section {
            margin-top: $indent__xxs;
        }
    }
    .post-comment {
        margin-top: $indent__xxs;
        margin-left: $indent__m;

        .post-comments__form-container {
            margin-top: $indent__xxs;
        }
        .reply-action {
            @include lib-link-quaternary();
            display: inline-block;
            padding: $indent__xxs 0;
        }
        &__replies {
            margin-top: $indent__xxs;
            border-left: 1px dotted $color-kim-gris2;
        }
        &__more .link{
            display: inline-block;
            padding: $indent__xxs 0;
            margin-left: $indent__m;
            margin-bottom: -$indent__xxs;
        }
    }
}
