.block.filter {
    margin-bottom: 0;

    .filter-title {
        display: none;
    }

    .items {
        @extend .abs-reset-list;
    }

    .filter-current {
        .items {
            margin-bottom: $indent__xs;
        }
    }

    .filter-actions {
        display: none;
    }
}

.block-subtitle {
    display: inline-block;
    margin-bottom: $indent__s;
}

.filter-current {

    .block-subtitle.filter-current-subtitle {
        display: none;
    }

    .items {
        display: block;

        li.item {
            margin-bottom: $indent__xs;
            margin-right: $indent__xxs;
            display: inline-block;

            .action {
                padding: $indent__xxxs;
                border: 1px solid $color-kim-gris3;
                transition: all 1s;
                display: block;

                @include lib-font-size($font-size__s);

                &:hover {
                    border-color: $color-kim-primario1;
                    color: $color-kim-primario1;
                }

                .close-text {
                    display: none;
                }

                svg {
                    margin-left: $indent__s;
                    width: 1.2rem;
                    height: 1.2rem;
                    box-sizing: border-box;
                }
            }

            &.special {
                .action {
                    border: 1px solid $color-kim-gris3;
                    background-color: $color-kim-gris3;
                    transition: background-color 1s, border-color 1s, color 0.2s;

                    @include lib-font-size($font-size__s);

                    &:hover {
                        border-color: $color-kim-primario1;
                        background-color: $color-kim-primario1;
                        color: $color-white;
                    }
                }
            }
        }
    }

    .filter-label {
        display: inline-block;
    }

    + .filter-actions {
        margin-bottom: $indent__s;
        display: inline-block;


        .action.clear {
            @include lib-font-size($font-size__s);
        }
    }
}

.filter-options-content {
    .filter-count-label {
        @extend .abs-visually-hidden;
    }
}

.block.filter.category,
.filter-options .filter-options-item-category {
    .filter-options-title {
        word-break: break-all;
        @include lib-font-size($font-size__l);
        font-weight: $font-weight__semibold;
        padding: $indent__xxs 0;
    }

    .filter-options-content {
        margin: 0 0 $indent__l;
    }

    .items {
        padding: 0 $indent__xxs;
        .item {
            margin-bottom: $indent__xxxs;

            > a {
                padding: $indent__xxs 0;
                display: block;
            }

            &.hide {
                display: none;
            }
            &.show {
                display: list-item;
            }
            &.show-more {
                font-style: $font-style__emphasis;
                font-weight: $font-weight__heavier;
                .hide {
                    display: none;
                }
                .show {
                    display: inline-block;
                    cursor: pointer;
                    padding: $indent__xs 0;
                }
            }
        }
    }
}

.filter-options {
    margin: 0;

    .filter-options-text {
        margin: 0 0 $indent__base;
        word-break: break-all;
        text-transform: uppercase;
    }

    .filter-options-item {
        border-bottom: 1px solid $color-kim-gris2;
        border-top: 1px solid $color-kim-gris2;

        & + .filter-options-item {
            border-top: none;
        }

        .filter-options-title {
            position: relative;
            cursor: pointer;
            text-transform: uppercase;
            padding: $indent__xs $indent__s $indent__xs $indent__xxs;

            .arrow-up {
                display: none;
            }

            .arrow-down {
                display: inline-block;
            }

            > span {
                display: inline-block;
                word-break: break-word;
            }

            .arrow {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 0;
                color: $color-kim-gris2;
            }
        }

        .items .item {
            &:not(:last-child) {
                margin-bottom: $indent__xxxs;
            }

            a {
                position: relative;
                padding: $indent__xxxs 0;
                display: block;
            }
        }

        .filter-options-content {
            padding: $indent__xxs;
            margin-bottom: $indent__xxs;

            .smile-es-range-slider {
                position: relative;
                display: flex;
                flex-wrap: wrap;

                .from,
                .to {
                    flex: 0 1 50%;
                    order: 1;
                }

                .to {
                    text-align: right;
                }


                .ui-slider-horizontal {
                    height: 8px;
                    margin: $indent__base 14px $indent__base 0;
                    flex: 0 1 100%;
                    order: 0;
                    background-color: $color-kim-gris3;

                    .ui-slider-handle {
                        top: -8px;
                        width: $indent__s;
                        height: $indent__s;
                        border-radius: 50%;
                        -webkit-box-shadow: $box-shadow-kim-sombra1;
                        -moz-box-shadow: $box-shadow-kim-sombra1;
                        box-shadow: $box-shadow-kim-sombra1;
                        background-color: $color-white;
                        cursor: pointer;
                    }

                    .ui-slider-range {
                        height: 8px;
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        background-color: $color-kim-gris2;
                    }
                }
                .actions-toolbar {
                    flex: 0 1 100%;
                    order: 2;
                    margin-top: $indent__base;

                    .actions-primary {
                        margin-top: $indent__s;

                        .action.secondary {
                            display: block;
                            cursor: pointer;
                            max-width: 160px;
                            margin: 0 auto;
                        }
                    }
                }
            }

            .show-more,
            .show-less {
                display: inline-block;
                cursor: pointer;
                padding: $indent__xs 0;
                font-style: $font-style__emphasis;
                font-weight: $font-weight__heavier;
            }

            .field.search {
                margin-bottom: $indent__xs;
            }
        }

        &.active {
            .arrow-up {
                display: inline-block;
            }
            .arrow-down {
                display: none;
            }
        }
    }

    .count {
        @include lib-css(color, $text__color__muted);

        &:before {
            content: '(';
        }

        &:after {
            content: ')';
        }
    }

    .action.primary {
        min-width: unset;
        float: none;
    }
    .actions-toolbar:after {
        clear: none;
    }
}

.filtered {
    .items {
        margin: 15px 0;
    }

    .item {
        margin-bottom: 6px;
        padding-left: 22px;
        position: relative;

        .label {
            font-weight: $font-weight__semibold;
        }

        .action.remove {
            @extend .abs-remove-button-for-blocks;
            left: -6px;
            position: absolute;
            top: 0;
        }
    }

    & + .actions {
        margin-bottom: 35px;
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {

    .column.main .block.filter {
        display: none;
    }

    body.filters-active {
        overflow: hidden;
        header.page-header {
            display: none;
        }
    }
    

    .block.filter:not(.category) {
        .filter-options-text {
            padding: $indent__base;
            display: block;
            text-align: center;
            cursor: pointer;
            border: 1px solid $color-kim-gris2;
        }

        .container-filter-options {
            display: none;
        }

        .filter-content {
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;

            & > * {
                align-self: center;
            }

            .filter-options {
                order: 2;
                flex: 0 1 100%;

                .filter-options-item.filter-active {
                    .filter-options-title:after {
                        content: '';
                        width: 8px;
                        height: 8px;
                        -webkit-border-radius: 50%;
                        -moz-border-radius: 50%;
                        border-radius: 50%;
                        display: inline-block;
                        margin-left: $indent__s;
                    }
                }
            }

            .filter-actions {
                margin: 0 0 $indent__s auto;
            }

            .filter-options-item {
                .items .item {
                    a {
                        padding: $indent__xxs 0;
                    }
                }
            }
        }

        &.active {

            .filter-content {
                position: relative;
                display: block;

                .filter-current {
                    order: -1;
                }

                .filter-actions {
                    position: fixed;
                    z-index: 10;
                    right: 0;
                    left: 0;
                    top: 0;
                    text-align: center;
                    margin: 0;
                    order: 0;
                    box-shadow: $box-shadow-kim-sombra1;
                    background-color: $color-white;
                    height: 40px;

                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .close-filter-options {
                        display: inline-block;
                        position: absolute;
                        right: 0;
                        padding: $indent__xxs;
                        svg {
                            width: 2.4rem;
                            height: 2.4rem;
                            padding: $indent__xxxs;
                            box-sizing: border-box;
                        }
                    }

                    > strong {
                        @include lib-font-size($font-size__l);
                        text-transform: uppercase;
                    }

                    .action.clear {
                        margin-top: $indent__m;
                        padding: $indent__xs;
                        display: block;
                    }
                }

                .filter-options {
                    overflow-y: auto;
                    position: fixed;
                    z-index: 10;
                    top: 40px;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    padding: $indent__base;
                    margin: 0;
                    background-color: $color-white;
                    display: flex;
                    flex-direction: column;
                }

                .filter-options-text {
                    display: none;
                }

                .container-filter-options {
                    display: block;
                }
            }
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .sidebar .block.filter .filter-current,
    .sidebar .block.filter .block-actions.filter-actions {
        display: none;
    }
    .filter-options .filter-options-text {
        @include lib-font-size($font-size__l);
        font-weight: $font-weight__semibold;
        margin-bottom: $indent__xxs;
    }


    .column.main .block.filter {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;

        & > * {
            align-self: center;
        }

        .filter-options {
            .filter-options-item:not(.active) .filter-options-content {
                display: none;
            }
        }

    }
}

