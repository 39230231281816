//
//  Indents
//  ---------------------------------------------

$indent__base     : 16px;
$indent__xxxl     : 64px;
$indent__xxl      : 56px;
$indent__xl       : 48px;
$indent__l        : 40px;
$indent__m        : 32px;
$indent__s        : 24px;
$indent__ss       : 16px;
$indent__xs       : 12px;
$indent__xxs      : 8px;
$indent__xxxs     : 4px;
$indent__auto     : auto !default;

//
//  Icons
//  ---------------------------------------------
$icons__font-name : 'icons-blank-theme' !default;
