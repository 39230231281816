$blog-layout__max-width             : $screen__xxl !default;
$blog-layout__max-width--3columns   : $screen__xxl !default;
$blog-content__font-size            : 14px !default;
$blog-content__line-height          : $line-height__l !default;
$blog-content__limit-s              : 600px !default;
$blog-content__limit-m              : 889px !default;
$blog-content__limit-l              : 1100px !default;


@import 'module/blog-author';
@import 'module/blog-categories';
@import 'module/blog-list';
@import 'module/blog-post';
@import 'module/blog-post-comments';
@import 'module/blog-post-info';
@import 'module/blog-related';
@import 'module/blog-sidebar';

.blog-page {
    .posts-loader {
        position: relative;
        padding: $indent__xl;
    }
    .wp-caption {
        display: flex;
        flex-direction: column;
    }
    .limit-s {
        max-width: $blog-content__limit-s;
        margin-left: auto;
        margin-right: auto;
    }
    .limit-m {
        max-width: $blog-content__limit-m;
        margin-left: auto;
        margin-right: auto;
    }

    @include min-screen($screen__m) {
        .page-main {
            max-width: $blog-layout__max-width;
        }
        &.page-layout-3columns .page-main {
            max-width: $blog-layout__max-width--3columns;
        }
    }
}
