//
//  Responsive variables
//  _____________________________________________

$use-flex    : true !default; // Use flexbox [true|false]
$responsive  : true !default; // Theme is responsive [true|false]

$screen__xxxs : 320px !default;
$screen__xxs  : 480px !default;
$screen__xs   : 540px !default;
$screen__s    : 680px !default;
$screen__m    : 868px !default;
$screen__l    : 1025px !default;
$screen__xl   : 1300px !default;
$screen__xxl  : 1440px !default;
$screen__xxxl : 1920px !default;

//
// Others
//  _____________________________________________

$screen__xxxs-2  : 375px;
$screen__xxs-2   : 400px;
$screen__s-2     : 715px;
$screen__m-2     : 900px;
$screen__m-3     : 940px;
$screen__l-2     : 1100px;
$screen__l-3     : 1200px;
$screen__l-4     : 1245px;
$screen__xxl-2   : 1600px;

