.downloadable.samples {
    margin-bottom: $indent__base;

    dd {
        margin: 0 0 $indent__s;
    }
}

.table-downloadable-products {
    .product-name {
        margin-right: 15px;
    }
}

.page-product-downloadable {
    .product-options-bottom {
        .field.qty {
            display: none !important;
        }
    }
    .legend.links-title {
        display: none;
    }
}