//
//  Variables
//  _____________________________________________

$active-nav-indent: 54px !default;

.panel.header {
    .links,
    .switcher {
        display: none;
    }
}

.sections.nav-sections {
    //display: none;
}
.custom-header .navigation {
    .enlaces-secundarios {
        padding: 0;

        & > li {
            display: inline-block;
            margin: 0;
            transition: background-color 1s;
            -ms-transform: skew(-30deg);
            -webkit-transform: skew(-30deg);
            transform: skew(-30deg);
            position: relative;

            a {
                //font-family: $font-family__Raleway-regular;
                font-weight: $font-weight__regular;
                display: inline-block;
                -ms-transform: skew(30deg);
                -webkit-transform: skew(30deg);
                transform: skew(30deg);

                &:hover {
                    color: $color-kim-gris1;
                }
            }

            &.secundario1 {
                background-color: $color-kim-secundario1;
                &:hover {
                    background-color: $color-kim-primario5;

                    &::before {
                        border-color: transparent $color-kim-primario5;
                    }
                }

                &::before {
                    border-color: transparent $color-kim-secundario1;
                }
            }

            &.secundario2 {
                background-color: $color-kim-secundario2;
                &:hover {
                    background-color: $color-kim-primario2;

                    &::before {
                        border-color: transparent $color-kim-primario2;
                    }
                }

                &::before {
                    border-color: transparent $color-kim-secundario2;
                }
            }

            &.secundario3 {
                background-color: $color-kim-secundario3;
                &:hover {
                    background-color: $color-kim-primario3;

                    &::before {
                        border-color: transparent $color-kim-primario3;
                    }
                }

                &::before {
                    border-color: transparent $color-kim-secundario3;
                }
            }

            &.secundario4 {
                background-color: $color-kim-secundario4;
                &:hover {
                    background-color: $color-kim-primario4;

                    &::before {
                        border-color: transparent $color-kim-primario4;
                    }
                }

                &::before {
                    border-color: transparent $color-kim-secundario4;
                }
            }

            &::after,
            &::before {
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 0 0 25px 15px;
                position: absolute;
                top: 0;
                bottom: 0;
                -ms-transform: skew(30deg);
                -webkit-transform: skew(30deg);
                transform: skew(30deg);
                transition: border-color 1s;
            }

            &.first::before {
                content: '';
                left: -7px;
            }
            &.last::after {
                content: '';
                right: -7px;
            }
            &.last {
                margin-right: 7px;
            }
        }
    }
}

.nav-principal {
    .enlaces-principales {
        padding: 0;
        margin: 0;
        position: relative;

        .submenu1 {
            left: 0;
            right: 0;
            margin: 0 auto;
            position: fixed;
            max-width: $footer__max-width;
            z-index: 2;

            .scroll {
                display: flex;
                background: $color-white;
                -webkit-box-shadow: $box-shadow-kim-sombra2;
                -moz-box-shadow: $box-shadow-kim-sombra2;
                box-shadow: $box-shadow-kim-sombra2;
                overflow-x: hidden;
                overflow-y: auto;
                height: 550px;
                margin-bottom: $indent__xxxs;
                scrollbar-width: none; /* Firefox */

                &::-webkit-scrollbar {
                    width: 0;
                }
            }

            .menu-level1 {
                width: 100%;
            }

            ul.level1 {
                height: 500px;
                padding: $indent__base 0 $indent__s $indent__m;
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-start;
                align-content: flex-start;
                flex-direction: column;
            }

            li.level1:not(.esp):not(.back):not(.enabled) {
                max-width: 210px;
                width: 25%;
                margin-right: $indent__base;

                & > a {
                    @include lib-text(medio);
                    display: block;
                    padding: 0;
                    @include lib-link(
                            $_link-font-weight             : $font-weight__semibold,
                            $_link-font-weight-visited     : $font-weight__semibold,
                            $_link-font-weight-hover       : $font-weight__semibold,
                            $_link-font-weight-active      : $font-weight__semibold
                    );
                }
            }

            li.level1 {
                margin-bottom: 5px;
                > a {
                    margin-bottom: 10px;
                }
            }
            li.level2 {
                margin-bottom: 0;

                & > a {
                    padding: 6px 0;
                    display: block;

                    &:hover {
                        /* pueden poner color a un nombre de categoria */
                        color: $color-kim-primario1 !important;
                    }
                }
            }
            li.level1.disabled {
                display: none;
            }
            li.level1.back {
                flex: 1 1 100%;
                border-right: 1px solid $color-kim-gris2;
                padding-right: $indent__base;
                width: 80px;
                margin-right: $indent__m;
                text-align: right;

                svg {
                    max-width: 24px;
                    cursor: pointer;
                }
                span {
                    @include lib-font-size(11);
                    display: block;
                    margin-top: $indent__xxs;
                }
            }
            div.level1.esp.submenuEsp {
                display: none;
                border-left: 1px solid $color-kim-gris2;
                padding-left: $indent__m;
                margin: 16px 32px 24px 0;

                max-width: 350px;
                min-width: 300px;
                flex-direction: column;
                justify-content: space-between;
                height: fit-content;
                height: -moz-fit-content;

                .marca {
                    margin-top: $indent__xxs;

                    a {
                        display: inline-block;
                        max-width: 80px;
                        flex: 0 1 25%;
                        text-align: center;
                        padding: $indent__xxs 0 0;
                    }
                }

                .tematica {
                    margin-top: $indent__m;

                    a {
                        flex: 0 1 25%;
                        max-width: 80px;
                        text-align: center;
                        padding: $indent__xxs 0 $indent__xs;
                        @include lib-link-quinary();
                    }
                }

                .producto {
                    margin-top: $indent__s;
                }

                .marca > div,
                .tematica > div {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    justify-content: space-between;
                }

                span {
                    //font-family: $font-family__Raleway-semibold;
                    font-weight: $font-weight__semibold;
                    @include lib-text(medio);
                    display: block;
                }

                hr {
                    margin-bottom: $indent__xxs;
                }
            }
            &.col-1 div.level1.esp {
                display: flex;
            }

            li.level1:not(.enabled) .submenu2 {
                .view-more ~ .level2 {
                    display: none;
                }
            }

            li.level1.enabled {
                width: auto;

                & > a {
                    @include lib-text(medio);
                    //font-family: $font-family__Raleway-semibold;
                    font-weight: $font-weight__semibold;
                    display: block;
                    margin-bottom: 0;
                }

                .submenu2 {

                    .view-more {
                        display: none;
                    }

                    ul.level2 {
                        display: flex;
                        flex-direction: column;
                        flex-wrap: wrap;
                        height: 500px;
                        align-content: flex-start;

                        .level2 {
                            margin-right: $indent__m;
                            max-width: 210px;
                            width: 25%;
                            min-width: 150px;
                        }
                    }
                }
            }
            li.level1.enabled ~ div.level1.esp {
                display: none;
            }

            ul.level2 {
                padding: 0 0 $indent__base $indent__xxs;
            }

            .view-more span {
                @include lib-link-quinary();
                padding: 6px 0;
                display: block;
                cursor: pointer;
            }
        }
        & > li {
            margin: 0;

            & > a {
                @include lib-text(normal);
                text-transform: uppercase;
                display: inline-block;
                margin: 0;
            }

            &:hover {
                > a {
                    //font-family: $font-family__Raleway-medium;
                    font-weight: $font-weight__heavier;
                }

            }
        }
    }
}

.nav-top-links{
    display: flex;
    flex-flow: row wrap;
    padding: 5px 0;
    > div{
        padding: 10px 0;
        width: 50%;
        text-align: center;
        box-sizing: border-box;
        border-right: 1px solid $color-kim-gris3;
        &:last-child{
            border: none;
        }
        > a{
            position: relative;
        }
    }
}
.nav-toggle {
    @include lib-icon-font(
        $icon-menu,
        $_icon-font-size        : 28px,
        $_icon-font-color       : $header-icons-color,
        $_icon-font-color-hover : $header-icons-color-hover
    );
    @include lib-icon-text-hide();
    cursor: pointer;
    display: block;
    font-size: 0;
    z-index: 14;

    order: 0;
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__l) {
    .custom-menu-sec,
    .custom-menu-prin {
        display: none;
    }

    .page-header:not(.fixed) .custom-menu-sec {
        margin-top: $indent__base;
    }

    .navigation {
        padding-left: $indent__m;
        .enlaces-secundarios {
            text-align: right;
            margin: 0;

            & > li > a {
                padding: 6px $indent__base 3px;
            }

            & > li.first > a {
                padding: 6px $indent__base 3px $indent__base - 7px;
            }
            & > li.last > a {
                padding: 6px $indent__base - 7px 3px $indent__base;
            }
        }

        .parent {
            .level-top {
                position: relative;
                @include lib-icon-font(
                    $_icon-font-content  : $icon-down,
                    $_icon-font-size     : 42px,
                    $_icon-font-position : after,
                    $_icon-font-display  : block
                );

                &:after {
                    position: absolute;
                    right: 7px;
                    top: -8px;
                }

                &.ui-state-active {
                    @include lib-icon-font-symbol(
                        $_icon-font-content  : $icon-up,
                        $_icon-font-position : after
                    );
                }
            }
        }
    }

    .nav-sections-item-title,
    .nav-sections {
        display: none;
    }

    .page-header.fixed {
        .sections.nav-sections {
            -webkit-overflow-scrolling: touch;
            height: auto;
            background-color: $color-white;
            z-index: 5;
            position: fixed;
            margin-top: 0;
            display: block;
        }
    }

    .nav-principal {
        display: block;
        border: none;
        order: 1;
        flex: 0 1 100%;
        margin-top: 0;

        .enlaces-principales {
            display: block;
            border-bottom: 1px solid $color-kim-gris3;
            margin: 0;

            li.level1:not(.esp):not(.back):not(.enabled) {
                min-width: 120px;
            }

            & > li {
                display: block;

                > a {
                    padding: $indent__xxs $indent__s $indent__xxs $indent__m;
                    @include lib-font-size(13);
                }

                &:hover .submenu1 {
                    display: none;
                    max-height: 100%;
                    overflow: hidden;
                    transition: max-height 0.8s, overflow 0s;
                }
            }
            & > li:first-child {
                margin-top: $indent__xs;
            }
            & > li:last-child {
                margin-bottom: $indent__xs;
            }

            & > li.enabled {
                position: relative;
                margin-top: 0;

                & > a {
                    //font-family: $font-family__Raleway-semibold;
                    font-weight: $font-weight__semibold;
                    padding: $indent__s $indent__s $indent__s $indent__xxxl;
                }

                ul.level1,
                ul.level2 {
                    padding: $indent__xxs $indent__base $indent__xs $indent__xl;
                }
            }

            .submenu1.col-1 div.level1.esp {
                display: none;
            }

            & > li.enabled > a.disabled {
                display: none;

                ~ .submenu1 {
                    padding: 0;
                    border: none;

                    ul.level1 {
                        padding: 0;

                        li.level1 > a {
                            padding: $indent__s $indent__s $indent__s $indent__xxxl;
                        }

                        .submenu2 {
                            border-top: 1px solid $color-kim-gris3;

                            & > ul.level2 {
                                display: block;
                                height: auto;

                                li.level2 {
                                    width: 100%;
                                    max-width: unset;

                                    > a {
                                        padding: $indent__xxs $indent__s $indent__xxs 0;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .submenu1 {
                position: static;
                border-top: 1px solid $color-kim-gris3;
                max-height: 100%;
                display: none;
                overflow: hidden;
                transition: max-height 0.3s, overflow 0.5s 0.5s;

                .scroll {
                    -webkit-box-shadow: none;
                    -moz-box-shadow: none;
                    box-shadow: none;
                    overflow: hidden auto;
                    height: auto;

                    &::-webkit-scrollbar {
                        width: 0;
                    }
                    &::-webkit-scrollbar-track-piece {
                        background-color: transparent;
                        -webkit-border-radius: 6px;
                    }

                    ul.level1 {
                        display: block;
                        height: auto;

                        li.level1:not(.esp):not(.back):not(.enabled) {
                            max-width: unset;
                            width: 100%;

                            & > a {
                                padding: $indent__xxs $indent__s $indent__xxs 0;
                                @include lib-font-size(13);
                            }
                        }

                        li.back {
                            position: absolute;
                            top: 0;
                            left: 0;
                            right: 0;
                            margin: $indent__xxs 0;
                            text-align: right;
                            padding: 0 $indent__xxs 0 0;
                            width: $indent__xl;
                            border-right-color: $color-kim-gris3;

                            span {
                                display: block;
                                @include lib-font-size(9);
                                margin: 0;
                                .txt-desktop {
                                    display: none;
                                }
                            }
                        }
                    }

                }

                li.level1 > .submenu2 {
                    display: none;
                }
            }

            li.disabled {
                display: none;
            }
        }
    }

    .nav-before-open {
        overflow-x: hidden;
        width: 100%;

        body {
            height: 100%;
            overflow: hidden;
            position: relative;
            width: 100%;

            .nav-sections {
                $_shadow: 2px 5px 7px 0 rgba(0, 0, 0, 0.2);
                @include lib-css(box-shadow, $_shadow, 1);
                z-index: 99;

                max-height: 100%;
                overflow-x: hidden;
                transition: max-height 0.8s, overflow 0.8s 0.8s;

                .navigation {

                    .enlaces-secundarios {

                        & > li {

                            &::before {
                                content: '';
                                border-style: solid;
                                border-width: 4px 0 4px 8px;
                                height: 0;
                                width: 0;
                                left: 0;
                                top: 50%;
                                transform: translateY(-50%);
                                position: absolute;
                            }

                            &.secundario1{
                                &::before {
                                    border-color: transparent $color-kim-secundario1 transparent $color-kim-secundario1;
                                }
                                &:hover{
                                    &::before {
                                        border-color: transparent $color-kim-primario5 transparent $color-kim-primario5;
                                    }
                                }
                            }

                            &.secundario2{
                                &::before {
                                    border-color: transparent $color-kim-secundario2 transparent $color-kim-secundario2;
                                }
                                &:hover{
                                    &::before {
                                        border-color: transparent $color-kim-primario2 transparent $color-kim-primario2;
                                    }
                                }
                            }

                            &.secundario3{
                                &::before {
                                    border-color: transparent $color-kim-secundario3 transparent $color-kim-secundario3;
                                }
                                &:hover{
                                    &::before {
                                        border-color: transparent $color-kim-primario3 transparent $color-kim-primario3;
                                    }
                                }
                            }

                            &.secundario4{
                                &::before {
                                    border-color: transparent $color-kim-secundario4 transparent $color-kim-secundario4;
                                }
                                &:hover{
                                    &::before {
                                        border-color: transparent $color-kim-primario4 transparent $color-kim-primario4;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .nav-toggle {
                &:after {
                    background: rgba(255, 255, 255, $overlay__opacity);
                    content: '';
                    display: block;
                    height: 100%;
                    position: fixed;
                    right: 0;
                    top: 50px;
                    width: 100%;
                    z-index: 1;
                }
            }
        }
    }

    .nav-open {
        .nav-sections {
            overflow-y: auto;
        }
    }

    .nav-sections-items {
        @include lib-clearfix();
        position: relative;
        z-index: 1;
    }

    .nav-sections {
        -webkit-overflow-scrolling: touch;
        @include lib-css(transition, top 2s, 1);
        height: auto;
        background-color: $color-white;
        z-index: 5;
        left: 0;
        right: 0;
        position: fixed;
        margin-top: 0;
        display: block;

        max-height: 0;
        overflow: hidden;
        transition: max-height 0.3s, overflow 0s;

        .navigation {
            order: 2;
            flex: 0 1 100%;

            .enlaces-secundarios {
                border-bottom: 1px solid $color-kim-gris3;
                text-align: left;
                padding: 0;
                & > li {
                    display: block;
                    position: relative;
                    padding: 3px 0;

                    & > a {
                        background-color: transparent;
                        padding: $indent__xxs $indent__base;
                        font-weight: 500;
                    }

                    &.first {
                        margin-top: $indent__base;
                    }
                    &.last {
                        margin-bottom: $indent__base;
                    }
                }

                li,
                a {
                    display: block;
                }
            }
        }

        .switcher {
            margin: 0;
            padding: 0.8rem 3.5rem 0.8rem 2rem;

            .label {
                display: block;
                margin-bottom: $indent__xs;
            }

            &:last-child {
                border-bottom: 1px solid $color-gray82;
            }
        }

        .switcher-trigger {
            strong {
                position: relative;
                @include lib-icon-font(
                    $_icon-font-content  : $icon-down,
                    $_icon-font-size     : 42px,
                    $_icon-font-position : after,
                    $_icon-font-display  : block
                );

                &:after {
                    position: absolute;
                    right: -40px;
                    top: -16px;
                }
            }

            &.active strong {
                @include lib-icon-font-symbol(
                    $_icon-font-content  : $icon-up,
                    $_icon-font-position : after
                );
            }
        }

        .switcher-dropdown {
            @include lib-list-reset-styles();
            padding: $indent__s 0;
        }

        .header.links {
            @include lib-list-reset-styles();
            border-bottom: 1px solid $color-gray82;

            li {
                font-size: 1.6rem;
                margin: 0;

                &.greet.welcome {
                    border-top: 1px solid $color-gray82;
                    font-weight: $font-weight__semibold;
                    padding: 0.8rem $indent__base;
                }

                > a {
                    border-top: 1px solid $color-gray82;
                }
            }

            a,
            a:hover {
                @include lib-css(color, $navigation-level0-item__color);
                @include lib-css(text-decoration, $navigation-level0-item__text-decoration);
                display: block;
                padding: 0.8rem $indent__base;
            }

            .header.links {
                border: 0;
            }
        }
    }

    .nav-sections-item-title {
        background: darken($navigation__background, 5%);
        border: solid darken($navigation__background, 10%);
        border-width: 0 0 1px 1px;
        box-sizing: border-box;
        float: left;
        height: 71px;
        padding-top: 24px;
        text-align: center;
        width: 33.33%;
        display: block;

        &.active {
            background: transparent;
            border-bottom: 0;
        }

        .nav-sections-item-switch {
            &:hover {
                text-decoration: none;
            }
        }
    }

    .nav-sections-item-content {
        @include lib-clearfix();
        box-sizing: border-box;
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        &.active {
            display: block;
        }

    }

    @include lib-main-navigation();
}

@include max-screen($screen__m){
    .page-header.fixed{
        .sections.nav-sections{
            padding-left: $indent__xxs;
            padding-right: $indent__xxs;
        }
    }
}
//
//  Desktop
//  _____________________________________________

@include min-screen($screen__l) {

    .page-header.fixed .sections.nav-sections {
        display: none;
    }

    .custom-menu-sec {
        @include lib-vendor-prefix-flex-shrink(0);
        @include lib-vendor-prefix-flex-basis(auto);
    }
    .page-header:not(.fixed) .custom-menu-sec {
        margin-top: 0;
    }

    .nav-sections-item-title,
    .nav-sections {
        display: none;
    }

    .nav-sections-item-content {
        display: block !important;
    }

    .nav-sections-item-content > * {
        display: none;
    }

    .navigation {
        display: block;
        .enlaces-secundarios {
            text-align: right;

            & > li > a {
                padding: 6px $indent__s 3px;
            }

            & > li.first > a {
                padding: 6px $indent__s 3px $indent__s - 7px;
            }
            & > li.last > a {
                padding: 6px $indent__s - 7px 3px $indent__s;
            }
        }
    }
    .nav-principal {
        border-top: 1px solid $color-kim-gris2;
        margin-top: 3px;
        display: block;

        .enlaces-principales {
            justify-content: space-between;
            display: flex;
            flex-direction: row;
            margin: 0;
            .submenu1 {
                max-height: 0;
                overflow: hidden;
                transition: max-height 0.3s, overflow 0.5s 0.5s;
                li.level1.enabled ~ div.level1.esp {
                    display: flex;
                }
            }

            & > li {
                display: inline-block;

                &:hover .submenu1 {
                    max-height: 100%;
                    overflow: hidden;
                    transition: max-height 0.8s, overflow 0s;
                }

                & > a {
                    padding: $indent__xxs $indent__xxxs;
                    margin: $indent__xxxs 0 $indent__xxs;
                    @include lib-text(medio);
                }
            }
            & > li:first-child > a {
                padding: $indent__xxs $indent__xxxs $indent__xxs 0;
            }
            & > li:last-child > a {
                padding: $indent__xxs 0 $indent__xxs $indent__xxxs;
            }
            li.level1:not(.esp):not(.back):not(.enabled) {
                min-width: 150px;
            }
        }
    }

    @include lib-main-navigation-desktop();

    .panel.header {
        .links,
        .switcher {
            display: inline-block;
        }
    }
    .page-header.fixed {
        .navigation {
            .enlaces-secundarios {

                & > li > a {
                    padding: 6px $indent__base 3px;
                }

                & > li.first > a {
                    padding: 6px $indent__base 3px $indent__base - 7px;
                }
                & > li.last > a {
                    padding: 6px $indent__base - 7px 3px $indent__base;
                }
            }
        }
    }


    .nav-toggle {
        display: none;
    }
}

@include min-screen($screen__m-2) {
    .nav-principal {
        .enlaces-principales {
            .submenu1.col-2 {
                div.level1.esp {
                    display: flex;
                }
                li.level1:not(.esp):not(.back) {
                    width: auto;
                }

            }
        }
    }
}

@include min-screen($screen__l-2) {

    .nav-principal {
        .enlaces-principales {
            & > li > a,
            & > li:first-child > a,
            & > li:last-child > a {
                padding: $indent__xs $indent__base;
            }
            .submenu1.col-3 {
                div.level1.esp {
                    display: flex;
                }
                li.level1:not(.esp):not(.back) {
                    width: auto;
                }

            }
        }
    }
}

@include min-screen($screen__l-4) {

    .nav-principal {
        .enlaces-principales {

            & > li:first-child {
                margin-left: $indent__base;
            }
            & > li:last-child {
                margin-right: $indent__base;
            }

            .submenu1.col-4 {
                div.level1.esp {
                    display: flex;
                }
                li.level1:not(.esp):not(.back) {
                    width: auto;
                }
            }
        }

    }
}

@include min-screen($screen__xxl) {

    .nav-principal {
        .enlaces-principales {

            .submenu1 {
                li.level1:not(.esp):not(.back):not(.enabled) {
                    margin-right: $indent__m;
                }
            }
        }

    }
}

@include min-screen($screen__xxl-2) {

    .nav-principal {
        .enlaces-principales {

            .submenu1 {
                li.level1:not(.esp):not(.back):not(.enabled) {
                    margin-right: $indent__xxxl;
                    width: 100%;
                }
            }
        }

    }
}

@include min-screen($screen__xxxl) {

    .nav-principal {
        .enlaces-principales {

            .submenu1 {
                li.level1:not(.esp):not(.back):not(.enabled) {
                    max-width: 260px;
                }
            }
        }

    }
}
